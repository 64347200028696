import Search from '@mui/icons-material/Search';
import { Box, Button, SxProps, Theme, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { FC } from 'react';
import { useSearchStore } from 'stores/search';

export type SearchButtonProps = {
  placeholder: string;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
};
export const SearchButton: FC<SearchButtonProps> = ({ placeholder, size = 'medium', sx }) => {
  const theme = useTheme();
  const { openSearch } = useSearchStore();

  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: 'rgba(255,255,255,.2)',
          },
        },
      })}
    >
      <Button
        sx={{ color: 'white', ...sx }}
        variant="contained"
        size={size}
        fullWidth
        endIcon={<Search />}
        onClick={openSearch}
      >
        <Box flex={1} textAlign="left">
          {placeholder}
        </Box>
      </Button>
    </ThemeProvider>
  );
};
