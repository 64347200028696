import { Close, ContentCopy, Outbound, VisibilityOff } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { FC, useMemo, useState } from 'react';
import { useTokenDetailStore } from 'stores/tokenDetail';
import { useTokenImageUrls } from 'stores/tokens';
import { getFormatedAddress } from 'utils/address';
import { copyTextToClipboard } from 'utils/clipboard';
import AllImage from 'assets/all.png';
import { TokenNode } from 'types/token';
import { ReactComponent as UpIcon } from 'assets/up.svg';
import { ReactComponent as DownIcon } from 'assets/down.svg';

const useSelectedNode = (): [TokenNode | undefined, number] => {
  const { selectedNode, tokenDetail } = useTokenDetailStore();
  return useMemo(() => {
    const nodes = tokenDetail?.nodes || [];
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      if (node.address === selectedNode?.address) {
        return [node, index];
      }
    }
    return [undefined, -1];
  }, [selectedNode?.address, tokenDetail?.nodes]);
};

export const SelectedWalletInfo: FC = () => {
  const {
    mode,
    selectedNode,
    setSelectedNode,
    d3Helper,
    tokenDetail,
    disabledAddresses,
    hiddenAddresses,
    addDisabledAddresses,
  } = useTokenDetailStore();
  const currentChainConfig = useCurrentChainConfig();
  const [selectedNodeDetail, selectedNodeIndex] = useSelectedNode();

  const selectedGroupAddresses = useMemo(
    () => {
      return (
        (selectedNode &&
          selectedNode.group !== -1 &&
          d3Helper?.getGroupAddresses(selectedNode.group)) ||
        []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [d3Helper, selectedNode]
  );

  const [percentage, clusterPercentage] = useMemo(() => {
    if (!selectedNodeDetail) return [0, 0];

    const nodes = (tokenDetail?.nodes || []).map((node) => {
      const isDisabled = disabledAddresses.includes(node.address);
      const isHidden = hiddenAddresses.includes(node.address);
      const isSelectedGroup = selectedGroupAddresses.includes(node.address);
      return { node, isHidden, isDisabled, isSelectedGroup };
    });
    const ignoredPercents = nodes
      .map((el) => (el.isHidden || el.isDisabled ? el.node.percentage : 0))
      .reduce((a, b) => a + b, 0);
    let dividerValue = 100 - ignoredPercents;
    dividerValue = dividerValue < 0 ? 0 : dividerValue;

    const percentage = (selectedNodeDetail.percentage * 100) / dividerValue;
    const clusterPercentage = nodes
      .filter((node) => node.isSelectedGroup)
      .reduce((prev, node) => prev + node.node.percentage, 0);

    return [percentage, clusterPercentage];
  }, [
    disabledAddresses,
    hiddenAddresses,
    selectedGroupAddresses,
    selectedNodeDetail,
    tokenDetail?.nodes,
  ]);

  if (!selectedNodeDetail) return <></>;
  return (
    <Box
      position="absolute"
      left={12}
      top={64}
      bgcolor="rgba(29,25,33,.8)"
      borderRadius={1}
      p={1}
      width={270}
    >
      <Box display="flex" alignItems="center">
        <Typography fontSize={19} fontWeight="bold" flex={1}>
          Selected Wallet
        </Typography>
        <IconButton
          sx={{
            color: 'rgb(77, 59, 79)',
            '&:hover': {
              color: 'white',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (selectedNode?.address) addDisabledAddresses(selectedNode.address);
          }}
        >
          <VisibilityOff sx={{ color: 'inherit' }} />
        </IconButton>
        <IconButton
          sx={{
            color: 'rgb(77, 59, 79)',
            '&:hover': {
              color: 'white',
            },
          }}
          onClick={() => setSelectedNode(undefined)}
        >
          <Close sx={{ color: 'inherit' }} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" columnGap={1}>
        <Box
          component="a"
          href={currentChainConfig.tokenScanUrl.replace('$token', selectedNodeDetail.address)}
          target="_blank"
          color="rgb(222, 33, 154)"
          maxWidth="calc( 100% - 24px )"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {selectedNodeDetail.name || getFormatedAddress(selectedNodeDetail.address)}
        </Box>
        <Tooltip title="Copy">
          <Box
            sx={{ cursor: 'pointer', pt: 0.5 }}
            onClick={() => selectedNode?.address && copyTextToClipboard(selectedNode.address)}
          >
            <ContentCopy sx={{ fontSize: 16, color: 'white' }} />
          </Box>
        </Tooltip>
      </Box>
      <Box>
        Wallet Rank: <strong>#{selectedNodeIndex + 1}</strong>
      </Box>
      <Box>
        Percentage: <strong>{Math.round(percentage * 10) / 10}%</strong>
      </Box>
      {!!clusterPercentage && (
        <Box>
          Cluster Percentage: <strong>{Math.round(clusterPercentage * 10) / 10}%</strong>
        </Box>
      )}
      {!!clusterPercentage && mode >= 0 && <ShowTransfersModal />}
    </Box>
  );
};

const ShowTransfersModal: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { mode, tokenDetail, selectedNode } = useTokenDetailStore();
  const currentChainConfig = useCurrentChainConfig();
  const [selectedNodeDetail, selectedNodeIndex] = useSelectedNode();

  const tokenLinks = tokenDetail?.tokenLinks || [];
  const imageUrls = useTokenImageUrls(
    tokenLinks.map((tokenLink) => ({
      tokenAddress: tokenLink.address,
      symbol: tokenLink.symbol,
    }))
  );
  const currentImageUrl = mode < 0 ? AllImage : imageUrls[mode];
  const currentTokenLink = mode < 0 ? { symbol: 'All', address: '' } : tokenLinks[mode];

  const nodes = useMemo(() => {
    const backwardAddresses: Record<string, number> = {};
    const forwardAddresses: Record<string, number> = {};
    const nodes = tokenDetail?.nodes || [];
    const links = [...(tokenDetail?.tokenLinks?.[mode].links || [])];

    links.forEach((link) => {
      if (link.source === selectedNodeIndex) {
        if (link.forward) {
          forwardAddresses[nodes[link.target].address] = link.forward;
        }
        if (link.backward) {
          backwardAddresses[nodes[link.target].address] = link.backward;
        }
      }
      if (link.target === selectedNodeIndex) {
        if (link.forward) {
          backwardAddresses[nodes[link.source].address] = link.forward;
        }
        if (link.backward) {
          forwardAddresses[nodes[link.source].address] = link.backward;
        }
      }
    });

    return (tokenDetail?.nodes || [])
      .map((node, index) => ({
        address: node.address,
        name: node.name,
        backward: backwardAddresses[node.address],
        forward: forwardAddresses[node.address],
        index,
      }))
      .filter((node) => node.backward || node.forward);
  }, [mode, selectedNodeIndex, tokenDetail?.links, tokenDetail?.nodes, tokenDetail?.tokenLinks]);

  if (!selectedNode) return <></>;

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        size="small"
        sx={{ mt: 1 }}
        onClick={() => setIsOpen(true)}
      >
        SHOW TRANSFERS
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        disableAutoFocus
        disableEnforceFocus
      >
        <Box
          sx={{
            width: 600,
            maxWidth: 'calc(100% - 32px)',
            maxHeight: 'calc(100% - 32px)',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            bgcolor: '#1d1921',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              overflow: 'auto',
              px: 3,
              py: 2.5,
              flex: 1,
            }}
          >
            <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
              <Avatar
                src={currentImageUrl}
                alt={currentTokenLink.symbol}
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: '9px !important',
                  fontWeight: 'bold',
                  bgcolor: 'rgb(77, 59, 79)',
                }}
              >
                {currentTokenLink.symbol.slice(0, 2)}
              </Avatar>
              <Typography variant="h6" fontWeight="bold">
                {currentTokenLink.symbol} Transfers Details
              </Typography>
            </Box>
            {currentTokenLink.address === tokenDetail?.tokenAddress && (
              <Box
                component="a"
                target="_blank"
                href={currentChainConfig.tokenScanUrl.replace('$token', selectedNode.address)}
                display="flex"
                alignItems="center"
                columnGap={1}
                color="#1581dd"
                mt={1}
                mb={2}
              >
                Show on {currentChainConfig.fullName}
                <Outbound sx={{ fontSize: 18 }} />
              </Box>
            )}
            {currentTokenLink.address !== tokenDetail?.tokenAddress && (
              <Box color="#fff" mt={1} mb={2} fontStyle="italic">
                Click on the amounts to show the Solscan explorer.
              </Box>
            )}
            <Box sx={{ fontWeight: 'bold', color: '#de219a', mb: 2 }}>
              #{selectedNodeIndex + 1}
              {' - '}
              {selectedNodeDetail?.name || getFormatedAddress(selectedNodeDetail?.address || '')}
            </Box>
            {nodes.map((node) => (
              <Box key={node.address} display="flex" alignItems="center" columnGap={1} mb={0.5}>
                <Box>
                  <strong>#{node.index + 1} - </strong>{' '}
                  {node.name || getFormatedAddress(node.address)}
                </Box>
                {node.forward && (
                  <TrendButton
                    type="up"
                    value={node.forward}
                    link={currentChainConfig.tokenScanToUrl
                      .replace('$seletedwallet', selectedNode.address)
                      .replace('$toaddress', node.address)
                      .replace('$token_address', currentTokenLink.address)}
                  />
                )}
                {node.backward && (
                  <TrendButton
                    type="down"
                    value={node.backward}
                    link={currentChainConfig.tokenScanFromUrl
                      .replace('$seletedwallet', selectedNode.address)
                      .replace('$fromaddress', node.address)
                      .replace('$token_address', currentTokenLink.address)}
                  />
                )}
              </Box>
            ))}
            <Box display="flex" columnGap={2} rowGap={0.5} flexWrap="wrap" mt={2}>
              <Box display="flex" columnGap={1}>
                <TrendButton type="down" />
                {currentTokenLink.symbol} received by wallet #{selectedNodeIndex + 1}
              </Box>
              <Box display="flex" columnGap={1}>
                <TrendButton type="up" />
                {currentTokenLink.symbol} sent by wallet #{selectedNodeIndex + 1}
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 1, textAlign: 'right' }}>
            <Button sx={{ color: '#bf97d1' }} onClick={() => setIsOpen(false)}>
              CLOSE
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

type TrendButtonProps = {
  type: 'up' | 'down';
  value?: number;
  hasBg?: boolean;
  link?: string;
};
const TrendButton: FC<TrendButtonProps> = ({ type, value, hasBg = true, link }) => {
  const text = useMemo(() => {
    if (!value) return '';
    if (value < 0.1) return '< 0.1';
    if (value < 1000) return value;
    if (value < 1000000) return `${Math.round(value / 100) / 10}k`;
    return `${Math.round(value / 100000) / 10}M`;
  }, [value]);
  return (
    <Box
      sx={{
        display: 'inline-flex',
        columnGap: 0.5,
        alignItems: 'center',
        color: type === 'up' ? 'red' : 'green',
        background: hasBg ? (type === 'up' ? 'rgba(255,0,0,.2)' : 'rgba(0,128,0,.2)') : '',
        borderRadius: 1,
        px: 0.5,
        py: 0.25,
        fontSize: 14,
      }}
      component={link ? 'a' : 'span'}
      href={link}
      target="_blank"
    >
      {!!text && <Box sx={{ lineHeight: '19px', pt: '2px', pl: '2px' }}>{text}</Box>}
      <SvgIcon sx={{ fontSize: 12, minHeight: 21, color: 'inherit' }}>
        {type === 'up' && <UpIcon />}
        {type === 'down' && <DownIcon />}
      </SvgIcon>
    </Box>
  );
};
