import { Avatar, Box, Button, SvgIcon, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { FC } from 'react';
import { RugDetail } from 'types/rug';
import { getFormatedAddress } from 'utils/address';

import { ReactComponent as WebsiteIcon } from 'assets/website.svg';
import { ReactComponent as EthscanIcon } from 'assets/ethscan.svg';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { formattedShortNumber } from 'utils/number';

export const Header: FC<{ rugDetail: RugDetail }> = ({ rugDetail }) => {
  const theme = useTheme();
  const currentChainConfig = useCurrentChainConfig();

  return (
    <Box display="flex" columnGap={1} mb={4}>
      <Box display="flex" flex={1} columnGap={1}>
        <Avatar
          src={rugDetail.token.imageUrl}
          alt={rugDetail.token.symbol}
          sx={{
            width: 66,
            height: 66,
            fontSize: '9px !important',
            fontWeight: 'bold',
            bgcolor: 'rgb(77, 59, 79)',
            borderRadius: 2,
          }}
        >
          {rugDetail.token.symbol.slice(0, 2)}
        </Avatar>
        <Box>
          <Box
            flexShrink={1}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize={30}
          >
            <strong>
              {rugDetail.token.symbol} -{' '}
              {rugDetail.token.fullName || getFormatedAddress(rugDetail.token.tokenAddress)}
            </strong>
          </Box>
          <ThemeProvider
            theme={createTheme({
              ...theme,
              palette: {
                primary: {
                  main: 'rgba(29,25,33,.8)',
                },
              },
              components: {
                ...theme,
                MuiButton: {
                  defaultProps: {
                    variant: 'contained',
                  },
                  styleOverrides: {
                    root: {
                      padding: 8,
                      fontSize: '1rem',
                      lineHeight: 1.5,
                      minWidth: 0,
                      borderRadius: 8,
                      textTransform: 'none',
                    },
                    startIcon: {
                      marginLeft: 0,
                    },
                    endIcon: {
                      marginRight: 0,
                    },
                  },
                },
              },
            })}
          >
            <Box display="flex" columnGap={1}>
              <Button
                component="a"
                href={currentChainConfig.tokenScanUrl.replace(
                  '$token',
                  rugDetail.token.tokenAddress
                )}
                target="_blank"
              >
                <SvgIcon fontSize="small">
                  <EthscanIcon />
                </SvgIcon>
              </Button>
              {!!rugDetail.token.website && (
                <Button component="a" href={rugDetail.token.website} target="_blank">
                  <SvgIcon fontSize="small">
                    <WebsiteIcon />
                  </SvgIcon>
                </Button>
              )}
            </Box>
          </ThemeProvider>
        </Box>
      </Box>
      <Box fontSize={{ md: 32, sm: 24, xs: 20 }} letterSpacing={2}>
        <Box component="strong" display={{ md: 'inline', xs: 'none' }}>
          ${rugDetail.tokenMeta.pricing}
        </Box>
        <Box component="strong" display={{ md: 'none', xs: 'inline' }}>
          ${formattedShortNumber(rugDetail.tokenMeta.pricing)}
        </Box>
      </Box>
    </Box>
  );
};
