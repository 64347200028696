import { Close, Refresh, SkipNext, SkipPrevious } from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { OpenTableMode, openTableWidth, useTokenDetailStore } from 'stores/tokenDetail';
import { formatDate, formatTimeAgo } from 'utils/datetime';

export const MapHistories: FC = () => {
  const {
    historyId,
    setHistoryId,
    loadTokenDetail,
    tokenDetail,
    tokenHistories,
    openTableMode,
    setOpenTableMode,
  } = useTokenDetailStore();

  const tokenHistoryIndex = useMemo(() => {
    if (!historyId) return 0;
    const index = tokenHistories.findIndex((tokenHistory) => tokenHistory.id === historyId);
    return index < 0 ? 0 : index;
  }, [historyId, tokenHistories]);

  if (openTableMode !== OpenTableMode.history || !tokenHistories.length || !tokenDetail) {
    return <></>;
  }
  return (
    <Box
      sx={{
        bgcolor: '#090015',
        position: 'absolute',
        zIndex: 20,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        width: openTableWidth,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" py={1} pl={2} pr={1}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Map Histories
        </Typography>
        <IconButton size="small" sx={{ color: '#fff' }} onClick={() => setOpenTableMode(undefined)}>
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <Box display="flex">
        <Tooltip title="Previous">
          <ActionBox
            disabled={tokenHistoryIndex >= tokenHistories.length - 1}
            onClick={() => {
              setHistoryId(tokenHistories[tokenHistoryIndex + 1].id);
              loadTokenDetail();
            }}
            width={60}
          >
            <SkipPrevious />
          </ActionBox>
        </Tooltip>
        <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
        {tokenHistoryIndex > 0 && (
          <Tooltip title="Most Recent">
            <ActionBox
              onClick={() => {
                setHistoryId(undefined);
                loadTokenDetail();
              }}
            >
              Most Recent
            </ActionBox>
          </Tooltip>
        )}
        {tokenHistoryIndex <= 0 && (
          <Tooltip title="Refresh">
            <ActionBox
              onClick={() => {
                loadTokenDetail();
              }}
            >
              Refresh
              <Refresh sx={{ fontSize: 18 }} />
            </ActionBox>
          </Tooltip>
        )}
        <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
        <Tooltip title="Next">
          <ActionBox
            disabled={tokenHistoryIndex <= 0}
            onClick={() => {
              setHistoryId(tokenHistories[tokenHistoryIndex - 1].id);
              loadTokenDetail();
            }}
            width={60}
          >
            <SkipNext />
          </ActionBox>
        </Tooltip>
      </Box>
      <Divider />
      <Box flex={1} overflow="auto">
        {tokenHistories.map((tokenHistory, index) => (
          <Box
            key={tokenHistory.id}
            sx={{
              display: 'flex',
              columnGap: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              transition: 'all ease .4s',
              px: 2,
              py: 1,
              fontSize: 14,
              cursor: 'pointer',
              opacity: 1,
              background: index === tokenHistoryIndex ? '#de219a !important' : 'transparent',
              '&:hover': {
                backgroundColor: '#4d3b4f',
              },
            }}
            onClick={() => {
              setHistoryId(tokenHistory.id);
              loadTokenDetail();
            }}
          >
            <Box fontSize={16} fontWeight="bold">
              {formatDate(tokenHistory.dtUpdate)}
            </Box>
            <Box>{formatTimeAgo(tokenHistory.dtUpdate)}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const ActionBox = styled(Box)<{ disabled?: boolean; width?: number }>(({ disabled, width }) => ({
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: width ? undefined : 1,
  height: 30,
  width: width,
  transition: 'all ease 0.3s',
  cursor: disabled ? 'auto' : 'pointer',
  opacity: disabled ? 0.3 : 1,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : 'rgba(77,59,79,.5)',
  },
}));
