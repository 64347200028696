import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FC, useEffect } from 'react';
import { useSearchStore } from 'stores/search';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { useTrendingTokens } from 'stores/tokens';
import { debounce } from 'utils/debounce';
import RotateLoader from 'react-spinners/RotateLoader';
import { ChainTag, Tag } from 'components/Tag';
import { Link, useLocation } from 'react-router-dom';
import { TokenLiteWithChain } from 'types/token';
import { useRecentlyViewedStore } from 'stores/recentlyViewed';

export const SearchModal: FC = () => {
  const {
    isOpen,
    closeSearch,
    items,
    totals,
    isSearching,
    keyword,
    setIsSearching,
    searchTokens,
    setKeyword,
  } = useSearchStore();
  const currentChainConfig = useCurrentChainConfig();
  const trendingTokens = useTrendingTokens();
  const location = useLocation();
  useEffect(() => {
    closeSearch();
  }, [closeSearch, location]);

  const { items: recentlyViewedItems, cleanAllRecentlyViewed } = useRecentlyViewedStore();

  return (
    <Modal
      open={isOpen}
      onClose={closeSearch}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      disableAutoFocus
      disableEnforceFocus
    >
      <Box
        sx={{
          width: 700,
          maxWidth: 'calc(100% - 32px)',
          height: 'calc(100% - 32px)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#1d1921',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ p: 2.5 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="header-search">Search by name, address, symbol</InputLabel>
            <OutlinedInput
              id="header-search"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: 'rgba(255,255,255,0.5)', fontSize: 30 }} />
                </InputAdornment>
              }
              label="Search by name, address, symbol"
              sx={{
                borderRadius: 32,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
              }}
              value={keyword}
              onChange={(event) => {
                setIsSearching(true);
                const keyword = event.target.value;
                setKeyword(keyword);
                debounce('search_input_modal', () => searchTokens());
              }}
            />
          </FormControl>
        </Box>
        <Divider />
        {!keyword && (
          <Box sx={{ flex: 1, p: 2.5, overflow: 'auto' }}>
            <Typography variant="h6" fontWeight="bold" textAlign="center" mb={2}>
              Trending on {currentChainConfig.fullName}
            </Typography>
            <Grid container spacing={0.5}>
              {trendingTokens.slice(0, 3).map((token, index) => (
                <Grid
                  key={token.tokenAddress}
                  item
                  md={4}
                  xs={6}
                  sx={{ display: { md: 'block', xs: index > 1 ? 'none' : 'block' } }}
                >
                  <Box
                    display="block"
                    bgcolor="rgba(255,255,255,.1)"
                    borderRadius={2}
                    px={1}
                    py={1.5}
                    component={Link}
                    to={`/${currentChainConfig.symbol}/token/${token.tokenAddress}`}
                  >
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        mx: 'auto',
                        mb: 1,
                        '& img': {
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    >
                      <img src={token.imageUrl} alt={token.fullName} />
                    </Box>
                    <Typography variant="body1" textAlign="center">
                      {token.fullName}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box height={40}></Box>
            {!recentlyViewedItems.length && (
              <>
                <Typography variant="h5" fontWeight="bold" textAlign="center" mb={0.5}>
                  Welcome to Bubblemaps!
                </Typography>
                <Typography textAlign="center">
                  Choose a token or NFT collection and discover the power of our bubbles
                </Typography>
                <Box textAlign="center" mt={1}>
                  <ThemeProvider
                    theme={createTheme({
                      palette: {
                        primary: { main: '#bf97d1' },
                      },
                    })}
                  >
                    <Button variant="outlined" size="small" sx={{ borderRadius: 100, px: 2 }}>
                      LEARN MORE
                    </Button>
                  </ThemeProvider>
                </Box>
              </>
            )}
            {!!recentlyViewedItems.length && (
              <>
                <Typography variant="h6" fontWeight="bold" textAlign="center" mb={2}>
                  Recently Viewed
                </Typography>
                {recentlyViewedItems.map((item, index) => (
                  <SearchItem
                    key={item.tokenAddress}
                    item={item}
                    divider={index < recentlyViewedItems.length - 1}
                  />
                ))}
                <Button fullWidth onClick={cleanAllRecentlyViewed}>
                  Clean local history
                </Button>
              </>
            )}
          </Box>
        )}
        {!!keyword && !isSearching && !!items.length && (
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Box overflow="auto">
              <Box sx={{ pl: 2.5, pr: 1, py: 2, display: 'flex' }}>
                {totals.map((total) => (
                  <Box key={total.chain} pr={1.5} display="flex" alignItems="center">
                    <ChainTag chain={total.chain} />
                    <Typography ml={0.5} variant="body2">
                      {total.count}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider />
            <Box p={2.5}>
              {items.map((item, index) => (
                <SearchItem
                  key={item.tokenAddress}
                  item={item}
                  divider={index < items.length - 1}
                />
              ))}
            </Box>
          </Box>
        )}
        {!!keyword && !isSearching && !items.length && (
          <Box sx={{ flex: 1, overflow: 'auto', textAlign: 'center', pt: 4 }}>
            <Box sx={{ color: '#de219a' }}>No maps found for this query</Box>
            <Box>Please try with the contract address</Box>
          </Box>
        )}
        {!!keyword && !!isSearching && (
          <Box flex={1} display="flex" alignItems="center" justifyContent="center">
            <RotateLoader color="#fff" size={15} />
          </Box>
        )}
        <Divider />
        <Box sx={{ p: 1, textAlign: 'right' }}>
          <Button sx={{ color: '#bf97d1' }} onClick={closeSearch}>
            CLOSE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const SearchItem: FC<{ divider?: boolean; item: TokenLiteWithChain }> = ({ divider, item }) => {
  const currentChainConfig = useCurrentChainConfig();

  return (
    <>
      <Box
        component={Link}
        to={`/${currentChainConfig.symbol}/token/${item.tokenAddress}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 0.5,
          transition: 'all ease .3s',
          '&:hover': {
            bgcolor: 'rgba(255,255,255,0.1)',
          },
        }}
      >
        <Box flex={1}>
          <Typography>
            {item.fullName} -{' '}
            <Typography display="inline" fontStyle="italic" fontWeight={100}>
              {item.symbol}
            </Typography>
          </Typography>
          <Typography variant="body2" color="rgba(255,255,255,.5)">
            {item.tokenAddress}
          </Typography>
        </Box>
        {item.isX721 && <Tag name="NFT" color="hsla(0,0%,100%,.7)" outline sx={{ mr: 1 }} />}
        <ChainTag chain={item.chain} />
      </Box>
      {divider && <Divider />}
    </>
  );
};
