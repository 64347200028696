import { chainConfigs, defaultChain } from 'configs/chains';
import { useParams } from 'react-router-dom';
import { ChainSymbol } from 'types/chain';

export let currentChain = defaultChain;

export const useCurrentChain = () => {
  const params = useParams<{ chain: ChainSymbol }>();
  currentChain = params.chain || defaultChain;
  return params.chain || defaultChain;
};

export const useCurrentChainConfig = () => {
  const currentChain = useCurrentChain();
  const currentChainConfig = chainConfigs.find(
    (chainConfig) => chainConfig.symbol === currentChain
  );
  if (currentChainConfig) return currentChainConfig;

  return chainConfigs.find((chainConfig) => chainConfig.symbol === defaultChain)!;
};

export const useCurrentChainValid = () => {
  const params = useParams<{ chain: string }>();
  if (!params.chain) return false;
  return !!chainConfigs.find((chainConfig) => chainConfig.symbol === params.chain);
};
