import { FC } from 'react';

export enum ChainSymbol {
  eth = 'eth',
  bsc = 'bsc',
  ftm = 'ftm',
  avax = 'avax',
  cro = 'cro',
  arbi = 'arbi',
  poly = 'poly',
  base = 'base',
  sol = 'sol',
}

export type ChainConfig = {
  symbol: ChainSymbol;
  fullName: string;
  color: string;
  tokenScanUrl: string;
  tokenScanFromUrl: string;
  tokenScanToUrl: string;
  tokenRugUrl: string;
  IconComponent: FC<React.SVGProps<SVGSVGElement> & { title?: string }>;
};
