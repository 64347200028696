import { GraphLink, GraphNode } from './D3Helper.type';

export const getGroups = (links: GraphLink[]) => {
  const groups: number[][] = [];
  links.forEach((link) => {
    //@ts-ignore
    const e0 = link.source as number;
    //@ts-ignore
    const e1 = link.target as number;

    const i0 = getGroupIndex(groups, e0);
    const i1 = getGroupIndex(groups, e1);

    if (i0 !== null && i1 !== null) {
      if (i0 !== i1) {
        // Merge the groups in the first one
        groups[i0] = [...groups[i0], ...groups[i1]];
        // Remove the leftover one
        groups.splice(i1, 1);
      }
    } else if (i0 !== null) {
      groups[i0].push(e1);
    } else if (i1 !== null) {
      groups[i1].push(e0);
    } else {
      if (e0 !== e1) {
        groups.push([e0, e1]);
      }
    }
  });
  var rep: Record<number, number> = {};
  groups.forEach((group, i) => {
    group.forEach((index) => {
      rep[index] = i;
    });
  });

  return rep;
};

const getGroupIndex = (groups: number[][], index: number) => {
  for (var i = 0; i < groups.length; i++) {
    if (groups[i].includes(index)) {
      return i;
    }
  }
  return null;
};

type GroupsNode = {
  index: number;
  link_count: number;
  amount: number;
  address: string;
};

export const getGroupsMainNodes = (
  groups: Record<number, number>,
  nodes: Omit<GraphNode, 'group'>[],
  links: GraphLink[]
) => {
  const groupsNodes: Record<number, GroupsNode[]> = [];
  Object.keys(groups).forEach((_node_index) => {
    const nodeIndex = parseInt(_node_index);
    const group = groups[nodeIndex];
    const nodeDetail: GroupsNode = {
      index: nodeIndex,
      link_count: links.filter(
        // @ts-ignore
        (link) => (link.source as number) === nodeIndex || (link.target as number) === nodeIndex
      ).length,
      amount: nodes[nodeIndex]['amount'],
      address: nodes[nodeIndex]['address'],
    };
    if (group in groupsNodes) {
      groupsNodes[group] = [...groupsNodes[group], nodeDetail];
    } else {
      groupsNodes[group] = [nodeDetail];
    }
  });
  var groupsMainNodes: Record<number, string> = {};
  Object.keys(groupsNodes).forEach((_group) => {
    const group = parseInt(_group);
    const main_node = groupsNodes[group].sort((node1, node2) => {
      if (node1.link_count > node2.link_count) return -1;
      if (node1.link_count < node2.link_count) return 1;
      if (node1.amount > node2.amount) return -1;
      if (node1.amount < node2.amount) return 1;
      return 0;
    })[0];
    groupsMainNodes[group] = main_node.address;
  });
  return groupsMainNodes;
};
