import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { ListTokens } from 'components/ListTokens';
import { useTokensStore } from 'stores/tokens';

export const ListedTokens: FC = () => {
  const theme = useTheme();
  const currentChainConfig = useCurrentChainConfig();
  const [keyword, setKeyword] = useState('');

  const { allTokens } = useTokensStore();
  const tokens = useMemo(() => {
    const keywordLowerCase = keyword.toLocaleLowerCase();
    return Array.from(allTokens[currentChainConfig.symbol].tokens.values())
      .filter(
        (token) =>
          token.fullName.toLocaleLowerCase().includes(keywordLowerCase) ||
          token.tokenAddress.toLocaleLowerCase().includes(keywordLowerCase)
      )
      .slice(0, 20);
  }, [allTokens, currentChainConfig.symbol, keyword]);

  return (
    <Box width={600} maxWidth="100%" pt={2.5} pb={5} mx="auto">
      <Typography variant="h5" fontWeight="bold" textAlign="center" mb={2.5}>
        Listed Tokens
      </Typography>
      <Typography textAlign="center" mb={2.5}>
        Collectively, we can improve transparency in the space. Get listed to make your bubble map
        publicly available!
      </Typography>
      <Box textAlign="center" mt={3}>
        <ThemeProvider
          theme={createTheme({
            ...theme,
            palette: {
              ...theme.palette,
              primary: {
                main: currentChainConfig.color,
              },
            },
          })}
        >
          <Button
            variant="outlined"
            LinkComponent={({ href, ...props }) => <Link {...props} to={href} />}
            href={`https://docs.google.com/forms/d/e/1FAIpQLSdj9s9DhslOWlgv1xaJRlOAAv-3lYOzR7W9IWpnv0v0b2EFog/viewform`}
          >
            GET LISTED
          </Button>
        </ThemeProvider>
      </Box>
      <Box mt={6} mb={2} px={{ sm: 4, xs: 0 }}>
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="list-search" sx={{ color: '#4d3b4f' }}>
            Search by name, address, symbol
          </InputLabel>
          <OutlinedInput
            id="list-search"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#de219a', fontSize: 26 }} />
              </InputAdornment>
            }
            label="Search by name, address, symbol"
            sx={{
              borderRadius: 32,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#4d3b4f',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#de219a',
              },
            }}
            value={keyword}
            onChange={(event) => {
              const keyword = event.target.value;
              setKeyword(keyword);
            }}
          />
        </FormControl>
      </Box>
      <ListTokens listOnly items={tokens} />
      {!tokens.length && (
        <Box color="#4d3b4f" textAlign="center" fontStyle="italic">
          No results found.
        </Box>
      )}
      {!!keyword && (
        <Button fullWidth onClick={() => setKeyword('')}>
          Reset Search
        </Button>
      )}
    </Box>
  );
};
