import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { TokenLite } from 'types/token';
import { TokenSocial } from 'components/TokenSocial';
import { Link } from 'react-router-dom';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';

const indexColors = [
  'rgb(255, 218, 39)',
  'rgb(198, 175, 68)',
  'rgb(173, 159, 93)',
  'rgb(162, 156, 131)',
  'rgb(156, 156, 156)',
];

export type ListTokensProps =
  | {
      icon: ReactNode;
      title: string;
      items: TokenLite[];
      hasIndex?: boolean;
      listOnly?: false;
    }
  | {
      hasIndex?: boolean;
      items: TokenLite[];
      listOnly: true;
    };
export const ListTokens: FC<ListTokensProps> = ({ items, hasIndex, ...props }) => {
  const currentChainConfig = useCurrentChainConfig();

  const listElements = items.map((item, index) => (
    <Box
      key={item.id}
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        mb: 1,
        borderRadius: 100,
        bgcolor: 'rgba(29, 25, 33, 0.5)',
        transition: 'all ease .3s',
        '&:hover': {
          bgcolor: 'rgba(77,59,79,.7)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          alignItems: 'center',
          p: 1,
          flex: 1,
        }}
        component={Link}
        to={`/${currentChainConfig.symbol}/token/${item.tokenAddress}`}
      >
        {hasIndex && (
          <Typography
            sx={{
              color: indexColors[Math.min(indexColors.length - 1, index)],
              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            {index + 1}
          </Typography>
        )}
        <Box
          width={40}
          height={40}
          borderRadius={40}
          overflow="hidden"
          sx={{
            img: {
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            },
          }}
        >
          <img src={item.imageUrl} alt={item.fullName} />
        </Box>
        <Typography flex={1}>{item.fullName}</Typography>
      </Box>
      <TokenSocial item={item} sx={{ mr: 1 }} />
    </Box>
  ));

  if (props.listOnly) {
    return <>{listElements}</>;
  }

  return (
    <Box sx={{ bgcolor: 'rgba(29,25,33,.5)', borderRadius: 4, p: 1.5 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        columnGap={1}
        color="#bf97d1"
        mb={1.5}
      >
        {props.icon}
        <Typography variant="h6" fontWeight="bold" sx={{ color: 'inherit' }}>
          {props.title}
        </Typography>
      </Box>
      {listElements}
    </Box>
  );
};
