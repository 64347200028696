export const formatTimeAgo = (date: number | string | Date) => {
  if (typeof date === 'string') date = new Date(date);
  if (typeof date === 'number') date = new Date(date * 1000);
  const now = new Date();

  const years = now.getFullYear() - date.getFullYear();
  if (years > 1) return `${years} years ago`;

  const months = years * 12 + now.getMonth() - date.getMonth();
  if (months > 1) return `${months} months ago`;

  const weeks = Math.ceil((now.getTime() - date.getTime()) / (7 * 24 * 60 * 60 * 1000));
  if (weeks > 1) return `${weeks} weeks ago`;

  const days = Math.ceil((now.getTime() - date.getTime()) / (24 * 60 * 60 * 1000));
  if (days > 1) return `${days} days ago`;

  const hours = Math.ceil((now.getTime() - date.getTime()) / (60 * 60 * 1000));
  if (hours > 1) return `${hours} hours ago`;

  const minutes = Math.ceil((now.getTime() - date.getTime()) / (60 * 1000));
  return `${minutes} minutes ago`;
};

export const formatDate = (date: number | string | Date) => {
  if (typeof date === 'string') date = new Date(date);
  if (typeof date === 'number') date = new Date(date * 1000);
  const years = date.getFullYear().toString();
  const months = (date.getMonth() + 1).toString().padStart(2, '0');
  const days = date.getDate().toString().padStart(2, '0');
  return `${years}-${months}-${days}`;
};
