export const tokenImageUrls: Record<string, string> = {
  WETH: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/eth.png',
  WBNB: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/bnb.png',
  WFTM: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/ftm.png',
  WAVAX: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/avax.png',
  WCRO: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/cro.png',
  WMATIC: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/matic.png',
  WSOL: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/sol.png',
  SOL: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/sol.png',
  USDC: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/usdc.png',
  USDT: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/usdt.png',
  BUSD: 'https://storage.googleapis.com/public-bubblemaps/app/modes-logos/busd.png',
};
