export type Callback<Value = any> = (value: Value) => void;

export class EventEmitter<Value = any> {
  private callbacks: Record<number, Callback<Value>> = {};
  private id = 1;

  on(callback: Callback<Value>) {
    const currentId = this.id++;
    this.callbacks[currentId] = callback;
    return currentId;
  }
  remove(id: number) {
    delete this.callbacks[id];
  }
  emit(value: Value) {
    Object.values(this.callbacks).forEach((callback) => {
      callback(value);
    });
  }
}
