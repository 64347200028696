import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MasterLayout } from './components/MasterLayout';
import { HomePage } from './pages/Home';
import { ListedTokens } from './pages/ListedTokens';
import { TokenDetailPage } from './pages/TokenDetail';
import { defaultChain } from 'configs/chains';
import { ThemeProvider, createTheme } from '@mui/material';
import { InitProvider } from 'components/InitProvider';
import { RugPage } from 'pages/Rug';

export const App: FC = () => {
  return (
    <ThemeProvider
      theme={createTheme({
        typography: {
          fontFamily: 'Montserrat, sans-serif',
          body1: {
            'a&': {
              textDecoration: 'none',
              transition: 'all ease .3s',
            },
            'a&:hover': {
              color: '#de219a',
            },
          },
          body2: {
            'a&': {
              textDecoration: 'none',
              transition: 'all ease .3s',
            },
            'a&:hover': {
              color: '#de219a',
            },
          },
        },
        palette: {
          primary: {
            main: '#de219a',
          },
          text: {
            primary: '#fff',
            secondary: '#fff',
            disabled: '#f0f0f0',
          },
        },
        components: {
          MuiTypography: {
            defaultProps: {
              color: 'textPrimary',
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderColor: 'rgba(255,255,255,0.1)',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 100,
                textTransform: 'unset',
              },
            },
          },
        },
      })}
    >
      <InitProvider>
        <BrowserRouter>
          <Routes>
            <Route path=":chain" element={<MasterLayout />}>
              <Route index element={<HomePage />} />
              <Route path="listed-tokens" element={<ListedTokens />} />
              <Route path="token/:tokenAddress" element={<TokenDetailPage />} />
              <Route path="rug/:tokenAddress" element={<RugPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="*" element={<Navigate to={`/${defaultChain}`} replace />} />
          </Routes>
        </BrowserRouter>
      </InitProvider>
    </ThemeProvider>
  );
};
