import { Box, Grid, ThemeProvider, createTheme, styled, useTheme } from '@mui/material';
import { getTokenRug } from 'api/rug';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { RugDetail } from 'types/rug';
import { Header } from './Header';
import { Card } from './Card';
import { Info } from '@mui/icons-material';
import { getFormatedAddress } from 'utils/address';
import { formattedNumber, formattedShortNumber } from 'utils/number';

const marketImageUrls: Record<string, string> = {
  meteora: '/markets/meteora.png',
  raydium: '/markets/raydium.png',
};

export const RugPage: FC = () => {
  const { tokenAddress } = useParams<{ tokenAddress: string }>();
  const currentChainConfig = useCurrentChainConfig();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [rugDetail, setRugDetail] = useState<RugDetail>();

  const loadTokenRug = useCallback(async () => {
    const rugDetail = await getTokenRug({
      chain: currentChainConfig.symbol,
      tokenAddress: tokenAddress || '',
    });
    setRugDetail(rugDetail);
    setLoading(false);
  }, [currentChainConfig.symbol, tokenAddress]);

  useEffect(() => {
    loadTokenRug();
  }, [loadTokenRug]);

  if (loading) {
    return (
      <Box
        height={{ md: 'calc(100dvh - 64px)', xs: 'calc(100dvh - 56px)' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RotateLoader color="#fff" size={15} />
      </Box>
    );
  }

  if (!rugDetail) {
    return (
      <Box
        height={{ md: 'calc(100dvh - 64px)', xs: 'calc(100dvh - 56px)' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Token not found!
      </Box>
    );
  }

  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        typography: {
          ...theme.typography,
          fontFamily: 'VT323, monospace',
          body1: {
            ...theme.typography.body1,
            fontFamily: 'VT323, monospace',
          },
          body2: {
            ...theme.typography.body2,
            fontFamily: 'VT323, monospace',
          },
        },
      })}
    >
      <Box py={4} fontFamily="VT323, monospace" lineHeight={1} fontSize={22}>
        <Header rugDetail={rugDetail} />
        <Grid container spacing={4} rowSpacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <Card title="Risk Analysis" rightTitle={rugDetail.risks && rugDetail.risks.length > 0 ? rugDetail.risks[0].score.toString() : "0"}>
              <Box
                fontSize={48}
                px={1}
                py={0.5}
                border={() => {
                  if (rugDetail.riskOverall === 'good') return '2px solid green';
                  if (rugDetail.riskOverall === 'danger') return '2px solid #dc3545';
                  return '2px solid #fff';
                }}
                textAlign="center"
                fontWeight="bold"
                mb={3}
                textTransform="capitalize"
              >
                {rugDetail.riskOverall}
              </Box>
              {rugDetail.risks.map((risk, index) => (
                <Box
                  position="relative"
                  key={index}
                  mb={1}
                  sx={{
                    '.description': {
                      display: 'none',
                    },
                    '&:hover .description': {
                      display: 'block',
                    },
                  }}
                >
                  <Box
                    borderRadius={2}
                    px={2.5}
                    py={1}
                    bgcolor={(() => {
                      if (risk.level === 'warn') return '#ff8c00';
                      if (risk.level === 'danger') return '#dc3545';
                      return '';
                    })()}
                    color="#000"
                    fontSize={20}
                    display="flex"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Box flex={1}>{risk.name}</Box>
                    <Info />
                  </Box>
                  <Box
                    className="description"
                    position="absolute"
                    top="calc(100% + 8px)"
                    left={0}
                    right={0}
                    px={2.5}
                    py={0.75}
                    bgcolor="#000"
                    borderRadius={2}
                    border="1px solid #fff"
                    zIndex={100}
                    sx={{
                      pointerEvents: 'none',
                    }}
                  >
                    {risk.description}
                  </Box>
                </Box>
              ))}
            </Card>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Card title="Token Overview">
              <Box display="flex" flexDirection="column" rowGap={2} mb={1.5}>
                <Box display="flex">
                  <Box flex={1}>Mint</Box>
                  <Box flex={1}>
                    <TokenLink
                      address={rugDetail.token.tokenAddress}
                      fullName={rugDetail.token.fullName}
                      symbol={rugDetail.token.symbol}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>Supply</Box>
                  <Box flex={1}>{formattedShortNumber(rugDetail.tokenMeta.supply)}</Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>Creator</Box>
                  <Box flex={1}>
                    <TokenLink {...rugDetail.tokenMeta.creator} />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>Market Cap</Box>
                  <Box flex={1}>${formattedNumber(rugDetail.tokenMeta.marketCap)}</Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>Mint Authority</Box>
                  <Box flex={1}>
                    <TokenLink {...rugDetail.tokenMeta.minAuthority} />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>LP Locked</Box>
                  <Box flex={1}>{formattedNumber(rugDetail.tokenMeta.lpLocked)}%</Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card title="Community Sentiment">
              <Box display="flex" columnGap={2}>
                <Box p={1.5} bgcolor="#dc3545" fontSize={30} textAlign="center" flex={1}>
                  💩
                </Box>
                <Box p={1.5} bgcolor="#198754" fontSize={30} textAlign="center" flex={1}>
                  🚀
                </Box>
              </Box>
              <Box display="flex" alignItems="center" columnGap={0.5} mt={1.5}>
                <Box>💩</Box>
                <Box display="flex" flex={1} height={16} border="1px solid #fff">
                  <Box
                    bgcolor="#dc3545"
                    height="100%"
                    flex={rugDetail.communitySentiment.sellPercent}
                  />
                  <Box
                    bgcolor="#198754"
                    height="100%"
                    flex={rugDetail.communitySentiment.buyPercent}
                  />
                </Box>
                <Box>🚀</Box>
              </Box>
            </Card>
          </Grid>
          <Grid item md={8} xs={12}>
            <Card
              title="Markets"
              rightTitle={rugDetail.markets.length.toString()}
              autoHeight={false}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Market</th>
                    <Box component="th" display={{ md: 'table-cell', xs: 'none' }}>
                      Address
                    </Box>
                    <Box component="th" display={{ sm: 'table-cell', xs: 'none' }}>
                      Pair
                    </Box>
                    <Box component="th" display={{ md: 'table-cell', xs: 'none' }}>
                      LP Mint
                    </Box>
                    <th className="right">
                      <Box component="span" display={{ sm: 'inline', xs: 'none' }}>
                        Liquidity
                      </Box>
                      <Box component="span" display={{ sm: 'none', xs: 'inline' }}>
                        Liq
                      </Box>
                    </th>
                    <th className="right">
                      <Box component="span" display={{ sm: 'inline', xs: 'none' }}>
                        LP Locked
                      </Box>
                      <Box component="span" display={{ sm: 'none', xs: 'inline' }}>
                        Locked
                      </Box>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rugDetail.markets.map((market, index) => (
                    <tr key={index}>
                      <td>
                        <Box display="flex" alignItems="center" columnGap={1}>
                          {!marketImageUrls[market.marketType] && <Box>{market.marketType}</Box>}
                          {!!marketImageUrls[market.marketType] && (
                            <Box
                              component="img"
                              src={marketImageUrls[market.marketType]}
                              width={24}
                              height={24}
                            />
                          )}
                          <Box display={{ sm: 'none', xs: 'block' }}>
                            <TokenLink {...market.pairA} />
                            <Box
                              component="span"
                              color="#fff"
                              mt={1}
                              mb={2}
                              fontSize={18}
                              px={0.25}
                              py={0.5}
                              bgcolor="#212529"
                            >
                              /
                            </Box>
                            <TokenLink {...market.pairB} />
                          </Box>
                        </Box>
                      </td>
                      <Box component="td" display={{ md: 'table-cell', xs: 'none' }}>
                        <TokenLink {...market.address} />
                      </Box>
                      <Box component="td" display={{ sm: 'table-cell', xs: 'none' }}>
                        <TokenLink {...market.pairA} />
                        <Box
                          component="span"
                          color="#fff"
                          mt={1}
                          mb={2}
                          fontSize={18}
                          px={0.25}
                          py={0.5}
                          bgcolor="#212529"
                        >
                          /
                        </Box>
                        <TokenLink {...market.pairB} />
                      </Box>
                      <Box component="td" display={{ md: 'table-cell', xs: 'none' }}>
                        <TokenLink {...market.lpMint} />
                      </Box>
                      <td className="right">
                        <Box component="span" display={{ sm: 'inline', xs: 'none' }}>
                          ${formattedNumber(market.liquidity)}
                        </Box>
                        <Box component="span" display={{ sm: 'none', xs: 'inline' }}>
                          ${formattedShortNumber(market.liquidity)}
                        </Box>
                      </td>
                      <td className="right">{formattedNumber(market.lpLocked)}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card
              title="Top Holders"
              rightTitle={
                formattedNumber(
                  rugDetail.holders.reduce(
                    (prev, cur) =>
                      prev +
                      (typeof cur.percentage === 'string'
                        ? parseFloat(cur.percentage)
                        : cur.percentage),
                    0
                  )
                ) + '%'
              }
              autoHeight={false}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th className="right">Amount</th>
                    <th className="right">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {rugDetail.holders.map((holder, index) => (
                    <tr key={index}>
                      <td>
                        <TokenLink {...holder.address} />
                      </td>
                      <td className="right">${formattedShortNumber(holder.amount)}</td>
                      <td className="right">{formattedNumber(holder.percentage)}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const TokenLink: FC<{
  address: string;
  fullName?: string;
  symbol?: string;
}> = ({ address, fullName, symbol }) => {
  address = address || '';
  const currentChainConfig = useCurrentChainConfig();
  const name = useMemo(() => {
    if (symbol) return symbol;
    if (fullName) return fullName;
    return getFormatedAddress(address);
  }, [fullName, symbol, address]);
  const link = currentChainConfig.tokenRugUrl.replace('$token', address);
  if (!address) return <>-</>;
  return (
    <Box
      component="a"
      target="_blank"
      href={link}
      color="#fff"
      mt={1}
      mb={2}
      fontSize={18}
      p={0.5}
      bgcolor="#212529"
      sx={{
        transition: 'all ease .4s',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: '#343a40',
        },
      }}
    >
      {name}
    </Box>
  );
};

const Table = styled('table')(({ theme }) => ({
  margin: 0,
  width: '100%',
  'th, td': {
    padding: '10px 5px',
    textAlign: 'left',
    '&.right': {
      textAlign: 'right',
    },
    '&.center': {
      textAlign: 'center',
    },
  },
}));
