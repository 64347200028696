import { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Box } from '@mui/material';
import { useCurrentChain, useCurrentChainValid } from 'hooks/useCurrentChain';
import { defaultChain } from 'configs/chains';
import { SearchModal } from 'components/SearchModal';
import { Footer } from './Footer';

export const MasterLayout: FC = () => {
  useCurrentChain();
  const currentChainValid = useCurrentChainValid();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!currentChainValid) return <Navigate to={`/${defaultChain}`} />;
  return (
    <>
      <Header />
      <Box px={{ md: 8, xs: 2 }} id="content">
        <Outlet />
      </Box>
      <Footer />
      <SearchModal />
    </>
  );
};
