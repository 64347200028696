import { TotalTokenByChain } from 'types/search';
import { TokenLiteWithChain } from 'types/token';
import { create } from 'zustand';
import { useTokensStore } from './tokens';
import { currentChain } from 'hooks/useCurrentChain';
import { ChainSymbol } from 'types/chain';

export type SearchState = {
  isOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  totals: TotalTokenByChain[];
  items: TokenLiteWithChain[];
  isSearching: boolean;
  keyword: string;
  setIsSearching: (isSearching: boolean) => void;
  searchTokens: () => Promise<void>;
  setKeyword: (keyword: string) => void;
};

export const useSearchStore = create<SearchState>((set, get) => ({
  isOpen: false,
  openSearch: () => {
    set({ isOpen: true, keyword: '', items: [], totals: [], isSearching: false });
  },
  closeSearch: () => {
    set({ isOpen: false });
  },
  keyword: '',
  totals: [],
  items: [],
  isSearching: false,
  setIsSearching: (isSearching) => set({ isSearching }),
  searchTokens: async () => {
    const keyword = get().keyword.toLocaleLowerCase();

    const allTokens = useTokensStore.getState().allTokens;
    const chains = Object.values(ChainSymbol);
    const currentChainIndex = chains.indexOf(currentChain);
    const items: TokenLiteWithChain[] = [];

    for (let index = 0; index < chains.length; index++) {
      const chain = chains[(index + currentChainIndex) % chains.length];
      const tokens = Array.from(allTokens[chain]?.tokens?.values() || []);

      tokens
        .filter(
          (token) =>
            token.tokenAddress.toLocaleLowerCase().includes(keyword) ||
            (token.fullName || '').toLocaleLowerCase().includes(keyword)
        )
        .forEach((token) => {
          items.push({
            id: token.id,
            tokenAddress: token.tokenAddress,
            fullName: token.fullName,
            imageUrl: token.imageUrl,
            website: token.website,
            chain: chain,
            isX721: false,
            symbol: token.symbol,
          });
        });
    }

    const totals: TotalTokenByChain[] = [];

    for (let index = 0; index < chains.length; index++) {
      const chain = chains[index];
      totals.push({ chain, count: items.filter((item) => item.chain === chain).length });
    }

    set({
      items: items.slice(0, 50),
      totals: totals.filter((total) => total.count),
      isSearching: false,
    });
    // try {
    //   const { keyword } = get();
    //   if (!keyword) {
    //     set({ items: [], totals: [], isSearching: false });
    //   } else {
    //     set({ isSearching: true });
    //     const { items, totals } = await searchTokens(keyword);
    //     set({ items, totals, isSearching: false });
    //   }
    // } catch (error) {
    //   set({ isSearching: false });
    //   return Promise.reject();
    // }
  },
  setKeyword: (keyword) => {
    set({ keyword });
  },
}));
