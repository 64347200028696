import { getTokenDetail, getTokenHistories } from 'api/token';
import { D3Helper } from 'pages/TokenDetail/D3Helper';
import { SelectedNode } from 'pages/TokenDetail/D3Helper.type';
import { TokenDetail, TokenHistory } from 'types/token';
import { create } from 'zustand';
import { useRecentlyViewedStore } from './recentlyViewed';
import { updateUrlParams } from 'utils/location';
import { currentChain } from 'hooks/useCurrentChain';

export enum OpenTableMode {
  walletsList = 1,
  history,
}

export const openTableWidth = 300;

export type TokenDetailState = {
  tokenAddress?: string;
  historyId?: string;
  mode: number;

  tokenDetail?: TokenDetail;
  tokenDetailLoading: boolean;
  tokenDetailFirstLoading: boolean;
  tokenHistories: TokenHistory[];
  tokenHistoriesLoading: boolean;
  tokenHistoriesFirstLoading: boolean;
  d3Helper?: D3Helper;
  selectedNode?: SelectedNode;
  disabledAddresses: string[];
  hiddenAddresses: string[];
  openTableMode?: OpenTableMode;

  setTokenAddress: (tokenAddress?: string) => void;
  setHistoryId: (historyId?: string) => void;
  setMode: (mode: number) => void;

  loadTokenDetail: () => Promise<void>;
  loadTokenHistories: () => Promise<void>;
  setSelectedNode: (selectedNode?: SelectedNode) => void;
  setOpenTableMode: (openTableMode?: OpenTableMode) => void;
  enableContracts: (enable: boolean) => void;
  enableExchanges: (enable: boolean) => void;
  setDisabledAddresses: (disabledAddresses: string[]) => void;
  addDisabledAddresses: (disabledAddress: string) => void;
  removeDisabledAddresses: (disabledAddress: string) => void;
  searchChange: (keyword: string) => void;
  initChart: () => void;
  refreshChart: () => void;
  dispose: () => void;
};

export const useTokenDetailStore = create<TokenDetailState>((set, get) => ({
  tokenAddress: undefined,
  historyId: undefined,
  mode: 0,

  tokenDetail: undefined,
  tokenHistories: [],
  tokenDetailLoading: true,
  tokenDetailFirstLoading: true,
  tokenHistoriesLoading: true,
  tokenHistoriesFirstLoading: true,
  d3Helper: undefined,
  selectedNode: undefined,
  disabledAddresses: [],
  hiddenAddresses: [],
  openTableMode: undefined,

  setTokenAddress: (tokenAddress) => {
    set({ tokenAddress });
  },
  setHistoryId: (historyId) => {
    set({ historyId });
    if (get().tokenHistories.length) {
      const isFirst = get().tokenHistories[0].id === historyId;
      if (!isFirst && historyId) updateUrlParams({ historyId });
      else updateUrlParams({ historyId: null });
    }
  },
  setMode: (mode) => {
    set({ mode });
    if (mode) updateUrlParams({ mode });
    else updateUrlParams({ mode: null });
    const { d3Helper } = get();
    d3Helper?.updateProps({ mode });
  },

  loadTokenDetail: async () => {
    const { tokenAddress, historyId } = get();
    if (!tokenAddress) return;
    try {
      set({ tokenDetailLoading: true });
      const tokenDetail = await getTokenDetail({ tokenAddress, historyId, chain: currentChain });

      const {
        selectedNode,
        setSelectedNode,
        enableContracts: setShowContracts,
        enableExchanges: setShowExchanges,
      } = get();
      set({ tokenDetailLoading: false, tokenDetailFirstLoading: false, tokenDetail });
      setShowContracts(false);
      setShowExchanges(true);

      const { disabledAddresses, hiddenAddresses, mode, openTableMode } = get();
      const d3Helper = new D3Helper({
        tokenDetail: tokenDetail,
        selectedNode: selectedNode,
        hiddenAddresses: [...disabledAddresses, ...hiddenAddresses],
        mode,
        offsetRight: openTableMode ? 120 : 0,
      });
      d3Helper.onSelectNode((node) => {
        const selectedNode = node ? { address: node.address, group: node.group } : undefined;
        setSelectedNode(selectedNode);
      });
      set({ d3Helper });

      useRecentlyViewedStore.getState().addRecentlyViewed(tokenDetail);
    } catch (error) {
      set({ tokenDetailLoading: false, tokenDetailFirstLoading: false });
    }
  },
  loadTokenHistories: async () => {
    const { tokenAddress } = get();
    if (!tokenAddress) return;
    try {
      set({ tokenHistoriesLoading: true });
      const tokenHistories = await getTokenHistories({ tokenAddress, chain: currentChain });
      set({ tokenHistories, tokenHistoriesLoading: false, tokenHistoriesFirstLoading: false });
    } catch (error) {
      set({ tokenHistoriesLoading: false, tokenHistoriesFirstLoading: false });
    }
  },
  searchChange: (_keyword) => {
    const keyword = _keyword.toLocaleLowerCase();
    set(({ tokenDetail }) => {
      const hiddenAddresses = (tokenDetail?.nodes || [])
        .filter(
          (node) =>
            !node.address.toLocaleLowerCase().includes(keyword) &&
            !node.name.toLocaleLowerCase().includes(keyword)
        )
        .map((node) => node.address);
      return { hiddenAddresses };
    });
    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  initChart: () => {
    const { d3Helper } = get();
    if (!d3Helper) return;
    d3Helper.initD3();
    d3Helper.refresh();
  },
  refreshChart: () => {
    const { d3Helper } = get();
    if (!d3Helper) return;
    d3Helper.refresh();
  },
  setSelectedNode: (selectedNode) => {
    const { d3Helper } = get();
    if (!d3Helper) return;
    d3Helper.updateProps({ selectedNode });
    set({ selectedNode });
  },
  setOpenTableMode: (openTableMode) => {
    set({ openTableMode });
    get().d3Helper?.updateProps({ offsetRight: openTableMode ? 120 : 0 });
  },
  enableContracts: (enable) => {
    set(({ disabledAddresses, tokenDetail }) => {
      if (!tokenDetail) return {};
      const allContractAddresses = tokenDetail.nodes
        .filter((node) => node.isContract)
        .reduce<Record<string, number>>((prev, node) => ({ ...prev, [node.address]: 1 }), {});
      if (enable) {
        return {
          disabledAddresses: disabledAddresses.filter(
            (hiddenAddress) => !allContractAddresses[hiddenAddress]
          ),
        };
      }
      return {
        disabledAddresses: [...disabledAddresses, ...Object.keys(allContractAddresses)],
      };
    });

    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  enableExchanges: (enable) => {
    set(({ disabledAddresses, tokenDetail }) => {
      if (!tokenDetail) return {};
      const allExchangeAddresses = tokenDetail.nodes
        .filter((node) => node.isExchange)
        .reduce<Record<string, number>>((prev, node) => ({ ...prev, [node.address]: 1 }), {});
      if (enable) {
        return {
          disabledAddresses: disabledAddresses.filter(
            (hiddenAddress) => !allExchangeAddresses[hiddenAddress]
          ),
        };
      }
      return {
        disabledAddresses: [...disabledAddresses, ...Object.keys(allExchangeAddresses)],
      };
    });
    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  setDisabledAddresses: (disabledAddresses) => {
    set({ disabledAddresses });
    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  addDisabledAddresses: (disabledAddress) => {
    set(({ disabledAddresses }) => ({
      disabledAddresses: [...disabledAddresses, disabledAddress],
    }));
    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  removeDisabledAddresses: (disabledAddress) => {
    set(({ disabledAddresses }) => ({
      disabledAddresses: disabledAddresses.filter(
        (_disabledAddress) => _disabledAddress !== disabledAddress
      ),
    }));
    get().d3Helper?.updateProps({
      hiddenAddresses: [...get().hiddenAddresses, ...get().disabledAddresses],
    });
  },
  dispose: () => {
    set({
      tokenDetail: undefined,
      tokenDetailLoading: true,
      d3Helper: undefined,
      selectedNode: undefined,
      hiddenAddresses: [],
      mode: 0,
    });
  },
}));

export const selectWalletsListActions = (state: TokenDetailState) => {
  const { disabledAddresses, tokenDetail } = state;

  let showContracts = false;
  let hiddenContracts = false;
  let showExchanges = false;
  let hiddenExchanges = false;

  for (const node of tokenDetail?.nodes || []) {
    if (node.isContract && disabledAddresses.includes(node.address)) {
      showContracts = true;
    }
    if (node.isContract && !disabledAddresses.includes(node.address)) {
      hiddenContracts = true;
    }
    if (node.isExchange && disabledAddresses.includes(node.address)) {
      showExchanges = true;
    }
    if (node.isExchange && !disabledAddresses.includes(node.address)) {
      hiddenExchanges = true;
    }
  }

  return {
    showAll: !disabledAddresses.length,
    showContracts,
    hiddenContracts,
    showExchanges,
    hiddenExchanges,
  };
};
