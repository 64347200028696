import { Close, Description, Search, Sync, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  styled,
  Tooltip,
} from '@mui/material';
import { FC, Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  OpenTableMode,
  openTableWidth,
  selectWalletsListActions,
  useTokenDetailStore,
} from 'stores/tokenDetail';
import { TokenNode } from 'types/token';
import { getFormatedAddress } from 'utils/address';
import { debounce } from 'utils/debounce';

export const WalletsList: FC = () => {
  const [keyword, setKeyword] = useState('');
  const {
    tokenDetail,
    d3Helper,
    openTableMode,
    setOpenTableMode,
    enableContracts,
    enableExchanges,
    setDisabledAddresses,
    searchChange,
  } = useTokenDetailStore();
  const { showAll, showContracts, hiddenContracts, showExchanges, hiddenExchanges } =
    useTokenDetailStore(selectWalletsListActions);

  const itemTokens = useItemTokens();

  useEffect(() => {
    if (openTableMode !== OpenTableMode.walletsList) setKeyword('');
  }, [openTableMode]);

  if (openTableMode !== OpenTableMode.walletsList || !tokenDetail || !d3Helper) return <></>;
  return (
    <Box
      sx={{
        bgcolor: '#090015',
        position: 'absolute',
        zIndex: 20,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        width: openTableWidth,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" py={1} pl={2} pr={1}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Wallets List
        </Typography>
        <IconButton size="small" sx={{ color: '#fff' }} onClick={() => setOpenTableMode(undefined)}>
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <Box bgcolor="#1d1921" flex={1} overflow="hidden" display="flex" flexDirection="column">
        <Box px={2} py={1}>
          <FormControl fullWidth size="small">
            <InputLabel htmlFor="list-search" sx={{ color: '#4d3b4f' }}>
              Search Wallets
            </InputLabel>
            <OutlinedInput
              id="list-search"
              endAdornment={
                <InputAdornment position="end">
                  <Search sx={{ color: '#de219a', fontSize: 26 }} />
                </InputAdornment>
              }
              label="Search Wallets"
              sx={{
                borderRadius: 3,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4d3b4f',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#de219a',
                },
              }}
              value={keyword}
              onChange={(event) => {
                const keyword = event.target.value;
                setKeyword(keyword);
                debounce('wallets_list_search', () => searchChange(keyword), 300);
              }}
            />
          </FormControl>
        </Box>
        <Divider />
        <Box display="flex">
          <Tooltip title="Show All">
            <ActionBox
              disabled={showAll}
              onClick={() => {
                setDisabledAddresses([]);
              }}
            >
              <Visibility />
            </ActionBox>
          </Tooltip>
          <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
          <Tooltip title="Show Contracts">
            <ActionBox
              disabled={!showContracts}
              onClick={() => !!showContracts && enableContracts(true)}
            >
              <IconWithVisible visible>
                <Description sx={{ fontSize: 'inherit' }} />
              </IconWithVisible>
            </ActionBox>
          </Tooltip>
          <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
          <Tooltip title="Hide Contracts">
            <ActionBox
              disabled={!hiddenContracts}
              onClick={() => !!hiddenContracts && enableContracts(false)}
            >
              <IconWithVisible visible={false}>
                <Description sx={{ fontSize: 'inherit' }} />
              </IconWithVisible>
            </ActionBox>
          </Tooltip>
          <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
          <Tooltip title="Show Exchanges">
            <ActionBox
              disabled={!showExchanges}
              onClick={() => !!showExchanges && enableExchanges(true)}
            >
              <IconWithVisible visible>
                <Sync sx={{ fontSize: 'inherit' }} />
              </IconWithVisible>
            </ActionBox>
          </Tooltip>
          <Divider orientation="horizontal" sx={{ borderRightWidth: 1 }} />
          <Tooltip title="Hide Exchanges">
            <ActionBox
              disabled={!hiddenExchanges}
              onClick={() => !!hiddenExchanges && enableExchanges(false)}
            >
              <IconWithVisible visible={false}>
                <Sync sx={{ fontSize: 'inherit' }} />
              </IconWithVisible>
            </ActionBox>
          </Tooltip>
        </Box>
        <Divider />
        <Box flex={1} overflow="auto">
          {itemTokens.map((itemToken, index) =>
            itemToken.isHidden ? (
              <Fragment key={itemToken.node.address} />
            ) : (
              <ItemTokenNote key={itemToken.node.address} index={index} {...itemToken} />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

const IconWithVisible: FC<PropsWithChildren<{ visible: boolean }>> = ({ children, visible }) => {
  return (
    <Box
      sx={{
        fontSize: 20,
        display: 'flex',
        position: 'relative',
        pb: 0.5,
        pr: 1,
        color: '#de219a',
      }}
    >
      {children}
      <Box
        component={visible ? Visibility : VisibilityOff}
        sx={{ fontSize: 16 }}
        position="absolute"
        right={0}
        bottom={0}
        zIndex={1}
        color="white"
      />
    </Box>
  );
};

const ActionBox = styled(Box)<{ disabled?: boolean }>(({ disabled }) => ({
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: 30,
  transition: 'all ease 0.3s',
  cursor: disabled ? 'auto' : 'pointer',
  opacity: disabled ? 0.3 : 1,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : 'rgba(77,59,79,.5)',
  },
}));

const useItemTokens = () => {
  const { hiddenAddresses, disabledAddresses, tokenDetail, selectedNode, d3Helper, mode } =
    useTokenDetailStore();
  const selectedGroupAddresses = useMemo(
    () => {
      return (
        (selectedNode &&
          selectedNode.group !== -1 &&
          d3Helper?.getGroupAddresses(selectedNode.group)) ||
        []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [d3Helper, selectedNode, mode]
  );

  return useMemo(() => {
    const nodes = (tokenDetail?.nodes || []).map((node) => {
      const isDisabled = disabledAddresses.includes(node.address);
      const isHidden = hiddenAddresses.includes(node.address);
      const isSelected = selectedNode?.address === node.address;
      const isRelatedSelection = selectedGroupAddresses.includes(node.address);

      return {
        node,
        isHidden,
        isDisabled,
        isSelected,
        isRelatedSelection,
      };
    });
    const ignoredPercents = nodes
      .map((el) => (el.isHidden || el.isDisabled ? el.node.percentage : 0))
      .reduce((a, b) => a + b, 0);
    let dividerValue = 100 - ignoredPercents;
    dividerValue = dividerValue < 0 ? 0 : dividerValue;

    return nodes.map((node) => ({
      ...node,
      node: {
        ...node.node,
        percentage: (node.node.percentage * 100) / dividerValue,
      },
    }));
  }, [
    disabledAddresses,
    hiddenAddresses,
    selectedGroupAddresses,
    selectedNode?.address,
    tokenDetail?.nodes,
  ]);
};

const ItemTokenNote: FC<{
  node: TokenNode;
  isDisabled: boolean;
  isSelected: boolean;
  isRelatedSelection: boolean;
  index: number;
}> = ({ node, isDisabled, isSelected, isRelatedSelection, index }) => {
  const { addDisabledAddresses, removeDisabledAddresses, setSelectedNode, d3Helper } =
    useTokenDetailStore();
  const name = useMemo(() => {
    return node.name || getFormatedAddress(node.address);
  }, [node.address, node.name]);

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        transition: 'all ease .4s',
        px: 2,
        py: 0.5,
        fontSize: 14,
        cursor: isDisabled ? 'auto' : 'pointer',
        opacity: isDisabled ? 0.3 : 1,
        background:
          isSelected && !isDisabled
            ? '#de219a !important'
            : isRelatedSelection && !isDisabled
            ? '#4d3b4f !important'
            : 'transparent',
        '&:hover': {
          backgroundColor: isDisabled ? 'transparent' : '#4d3b4f',
        },
      }}
      onClick={() => {
        setSelectedNode({
          address: node.address,
          group: d3Helper?.getGroup(node.address) || -1,
        });
      }}
    >
      <Box>#{index + 1}</Box>
      <Box flex={1} display="flex" alignItems="center" columnGap={0.5} minWidth={0}>
        {node.isContract && <Description sx={{ fontSize: 16 }} />}
        {node.isExchange && <Sync sx={{ fontSize: 16 }} />}
        <Box flex={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {name}
        </Box>
        {!isDisabled && <Box fontWeight="bold">{Math.round(node.percentage * 10) / 10}%</Box>}
      </Box>
      <IconButton
        size="small"
        sx={{ color: '#fff' }}
        onClick={(event) => {
          event.stopPropagation();
          isDisabled ? removeDisabledAddresses(node.address) : addDisabledAddresses(node.address);
        }}
      >
        <Visibility sx={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  );
};
