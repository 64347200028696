import { Box, Button, GlobalStyles, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import RotateLoader from 'react-spinners/RotateLoader';
import { Chart } from './Chart';
import { useTokenDetailStore } from 'stores/tokenDetail';
import { ChartToolbar } from './ChartToolbar';
import { WalletsList } from './WalletsList';
import { MapHistories } from './MapHistories';
import { SelectedWalletInfo } from './SelectedWalletInfo';
import { ArrowForward } from '@mui/icons-material';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';

export const TokenDetailPage: FC = () => {
  const { tokenAddress } = useParams<{ tokenAddress: string }>();
  const [searchParams] = useSearchParams();
  const currentChainConfig = useCurrentChainConfig();
  const theme = useTheme();
  const {
    tokenDetailLoading,
    tokenDetailFirstLoading,
    tokenHistoriesLoading,
    tokenHistoriesFirstLoading,
    tokenDetail,
    dispose: tokenDetailDispose,
    loadTokenHistories,
    loadTokenDetail,
    setTokenAddress,
    setHistoryId,
    setMode,
  } = useTokenDetailStore();

  useEffect(() => {
    try {
      setMode(parseInt(searchParams.get('mode') || '0'));
      setTokenAddress(tokenAddress);
      setHistoryId(searchParams.get('historyId') || undefined);
    } catch (error) {
      setMode(0);
    }
  }, [searchParams, setHistoryId, setMode, setTokenAddress, tokenAddress]);

  useEffect(() => {
    if (!tokenAddress) return;
    loadTokenDetail();
    loadTokenHistories();
  }, [tokenAddress, loadTokenDetail, loadTokenHistories]);

  useEffect(() => tokenDetailDispose, [tokenDetailDispose]);

  return (
    <Box height={{ md: 'calc(100dvh - 64px)', xs: 'calc(100dvh - 56px)' }} position="relative">
      <GlobalStyles styles={{ '#footer': { display: 'none' }, '#content': { padding: 0 } }} />
      {(tokenDetailLoading || tokenHistoriesLoading) && (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <RotateLoader color="#fff" size={15} />
        </Box>
      )}
      {!tokenDetailLoading && !tokenHistoriesLoading && (
        <>
          <Chart />
          <SelectedWalletInfo />
        </>
      )}
      {!tokenDetailFirstLoading && !tokenHistoriesFirstLoading && tokenDetail && (
        <>
          <ChartToolbar />
          <WalletsList />
          <MapHistories />
        </>
      )}
      <ThemeProvider
        theme={createTheme({
          ...theme,
          palette: {
            primary: {
              main: 'rgba(29,25,33,.8)',
            },
          },
          components: {
            ...theme,
            MuiButton: {
              styleOverrides: {
                root: {
                  padding: 8,
                  fontSize: '1rem',
                  lineHeight: 1.5,
                  minWidth: 0,
                  borderRadius: 8,
                  textTransform: 'none',
                },
                startIcon: {
                  marginLeft: 0,
                },
                endIcon: {
                  marginRight: 0,
                },
              },
            },
          },
        })}
      >
        <Box position="absolute" bottom={16} left={16} zIndex={12}>
          <Button
            variant="contained"
            LinkComponent={({ href, ...props }) => <Link {...props} to={href} />}
            href={`/${currentChainConfig.symbol}/rug/${tokenAddress}`}
            endIcon={<ArrowForward />}
          >
            Rug Check
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
