import {
  Box,
  Grid,
  SvgIcon,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FullLogoImage } from 'assets/full-logo.svg';
import { ReactComponent as Discord } from 'assets/discord.svg';
import { ReactComponent as Tiktok } from 'assets/tiktok.svg';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { Instagram, OpenInNew, Telegram, Twitter, YouTube } from '@mui/icons-material';
import { ChainSymbol } from 'types/chain';

export const Footer: FC = () => {
  const currentChainConfig = useCurrentChainConfig();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        typography: {
          ...theme.typography,
          body1: {
            ...theme.typography.body1,
            fontSize: isTablet ? 13 : 14,
          },
          h2: {
            ...theme.typography.body2,
            fontWeight: 500,
            fontSize: isTablet ? 14 : 15,
          },
        },
        palette: {
          ...theme.palette,
          text: {
            ...theme.palette.text,
            primary: '#c5c5c5',
          },
        },
      })}
    >
      <Box px={{ md: 8, xs: 2 }} pt={7.5} pb={5} bgcolor="#1d1921" id="footer">
        <Grid container spacing={{ md: 3, xs: 1 }}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { md: 'start', xs: 'center' },
            }}
          >
            <Box
              component={Link}
              to={`/${currentChainConfig.symbol}`}
              display="flex"
              alignItems="center"
            >
              <Box mr={1}>
                <FullLogoImage height={22} width="auto" />
              </Box>
              <OpenInNew sx={{ fontSize: 20, color: 'hsla(0,0%,77.3%,.5)' }} />
            </Box>
            <Typography
              mt={1}
              letterSpacing={2}
              sx={{ fontSize: 12 }}
              textAlign={{ md: 'left', xs: 'center' }}
            >
              INNOVATIVE VISUALS FOR BLOCKCHAIN DATA
            </Typography>
            <Box
              display="flex"
              columnGap={0.5}
              rowGap={1}
              flexWrap="wrap"
              mt={1}
              mb={{ md: 0, xs: 3 }}
            >
              {socials.map((social, index) => (
                <Box
                  key={index}
                  component="a"
                  href={social.link}
                  target="_blank"
                  sx={{
                    width: 35,
                    height: 35,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 24,
                    color: 'white',
                    borderRadius: 0.5,
                    bgcolor: 'rgba(222,33,154,.3)',
                    transition: 'background-color .15s linear',
                    '&:hover': {
                      bgcolor: '#de219a',
                    },
                  }}
                >
                  <social.Icon />
                </Box>
              ))}
            </Box>
            <Terms sx={{ mt: 2, display: { md: 'block', xs: 'none' } }} />
          </Grid>
          {footerMenus.map((footerMenu, index) => (
            <Grid key={index} item md={2} sm={3} xs={6}>
              <Typography
                variant="h2"
                sx={{ color: 'white', mb: 1.25, textAlign: { md: 'left', xs: 'center' } }}
              >
                {footerMenu.title}
              </Typography>
              {footerMenu.items.map((item, index) => (
                <Typography
                  key={index}
                  component={Link}
                  sx={{
                    mb: { md: 1, xs: 0.5 },
                    display: 'block',
                    textAlign: { md: 'left', xs: 'center' },
                  }}
                  to={item.link}
                  target={item.link.startsWith('https://') ? '_blank' : '_self'}
                >
                  {item.title}
                </Typography>
              ))}
            </Grid>
          ))}
          <Grid xs={12} item sx={{ display: { md: 'none', xs: 'block' } }}>
            <Terms sx={{ textAlign: 'center', pt: 2 }} />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const Terms: FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  return (
    <Box sx={sx}>
      <Typography
        component="a"
        href="https://storage.googleapis.com/public-bubblemaps/legal/Terms%20%26%20Conditions%20-%20Bubblemaps.io.pdf"
        target="_blank"
        sx={{ fontSize: 11 }}
      >
        Terms & Conditions
      </Typography>
      <Typography sx={{ fontSize: 11 }} component="span">
        {' - '}
      </Typography>
      <Typography
        component="a"
        href="https://storage.googleapis.com/public-bubblemaps/legal/Cookies%20-%20Bubblemaps.io.pdf"
        target="_blank"
        sx={{ fontSize: 11 }}
      >
        Cookies
      </Typography>
      <Typography component="span" sx={{ fontSize: 11 }}>
        {' - '}
      </Typography>
      <Typography
        component="a"
        href="https://storage.googleapis.com/public-bubblemaps/legal/Privacy%20Policy%20-%20%20Bubblemaps.io.pdf"
        target="_blank"
        sx={{ fontSize: 11 }}
      >
        Privacy
      </Typography>
    </Box>
  );
};

const footerMenus = [
  {
    title: 'ABOUT',
    items: [
      {
        title: 'Main Website',
        link: '/',
      },
      {
        title: 'Case Studies',
        link: 'https://bubblemaps.io/case-studies',
      },
      {
        title: 'Get Premium',
        link: 'https://bubblemaps.io/get-premium',
      },
      {
        title: 'Get Listed',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSdj9s9DhslOWlgv1xaJRlOAAv-3lYOzR7W9IWpnv0v0b2EFog/viewform',
      },
    ],
  },
  {
    title: 'SUPPORTED CHAINS',
    items: [
      { title: 'Ethereum', link: `/${ChainSymbol.eth}` },
      { title: 'BNB Chain', link: `/${ChainSymbol.bsc}` },
      { title: 'Fantom', link: `/${ChainSymbol.ftm}` },
      { title: 'Avalanche', link: `/${ChainSymbol.avax}` },
      { title: 'Cronos', link: `/${ChainSymbol.cro}` },
      { title: 'Arbitrum', link: `/${ChainSymbol.arbi}` },
      { title: 'Polygon', link: `/${ChainSymbol.poly}` },
      { title: 'Base', link: `/${ChainSymbol.base}` },
      { title: 'Solana', link: `/${ChainSymbol.sol}` },
    ],
  },
  {
    title: 'INTEGRATIONS',
    items: [
      { title: 'Gem.xyz', link: 'https://www.gem.xyz/' },
      { title: 'DEX Screener', link: 'https://dexscreener.com/' },
      { title: 'Trait Sniper ', link: 'https://app.traitsniper.com/' },
      { title: 'NFTKey', link: 'https://nftkey.app/' },
      { title: 'MoonScan', link: 'https://moonscan.com/' },
      { title: 'GeckoTerminal', link: 'https://www.geckoterminal.com/' },
    ],
  },
  {
    title: 'CONTACT',
    items: [
      {
        title: 'Work with Us',
        link: 'https://bubblemaps.io/work-with-us',
      },
      {
        title: 'Telegram',
        link: 'https://t.me/Bubblemaps_BD',
      },
      {
        title: 'Email',
        link: 'mailto:contact@bubblemaps.io',
      },
      {
        title: 'Support',
        link: 'mailto:help@bubblemaps.io',
      },
    ],
  },
];

const socials = [
  {
    Icon: Twitter,
    link: 'https://twitter.com/bubblemaps',
  },
  {
    Icon: Telegram,
    link: 'https://t.me/bubblemaps',
  },
  {
    Icon: () => (
      <SvgIcon>
        <Discord />
      </SvgIcon>
    ),
    link: 'https://discord.gg/6DexjezHzV',
  },
  {
    Icon: Instagram,
    link: 'https://www.instagram.com/bubblemaps.io/',
  },
  {
    Icon: YouTube,
    link: 'https://www.youtube.com/c/Bubblemaps/',
  },
  {
    Icon: () => (
      <SvgIcon>
        <Tiktok />
      </SvgIcon>
    ),
    link: 'https://www.tiktok.com/@bubblemaps.io',
  },
];
