import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

export type CardProps = {
  title: string;
  rightTitle?: string;
  children: ReactNode;
  autoHeight?: boolean;
};
export const Card: FC<CardProps> = ({ title, rightTitle, children, autoHeight = true }) => {
  return (
    <Box border="1px solid #fff" px={2} py={1} height={autoHeight ? '100%' : 'auto'}>
      <Box display="flex" mb={1} alignItems="center">
        <Typography fontWeight="bold" fontSize={26} flex={1}>
          {title}
        </Typography>
        <Typography fontWeight="bold" fontSize={26}>
          {rightTitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
