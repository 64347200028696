import { Box, GlobalStyles, styled } from '@mui/material';
import { FC, useEffect } from 'react';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { openTableWidth, useTokenDetailStore } from 'stores/tokenDetail';

export const Chart: FC = () => {
  const { d3Helper, tokenDetail, initChart, openTableMode } = useTokenDetailStore();

  useEffect(() => initChart(), [initChart]);

  if (!tokenDetail) return <></>;

  return (
    <Box height="100%" width="100%">
      <GlobalStyles
        styles={{
          '@keyframes dash': {
            '100%': {
              strokeDashoffset: 200,
            },
          },
          '#svg line': {
            stroke: '#fff',
            strokeOpacity: 0.6,
            pointerEvents: 'none',
            '&.--backward,&.--forward': {
              strokeDasharray: 4,
              animation: 'dash 15s linear infinite',
            },
          },
          '#svg circle': {
            fillOpacity: 0.2,
            strokeWidth: '1.5px',
            cursor: 'pointer',
            '&:hover': {
              fillOpacity: 0.8,
              stroke: '#fff',
            },
            '&.nodes--selected': {
              strokeWidth: 4,
              fillOpacity: 0.8,
              stroke: '#fff',
            },
          },
        }}
      />
      <Box component="svg" id="svg" pointer-events="all" height="100%" width="100%">
        <defs>
          <marker
            id="startarrow"
            markerWidth="8"
            markerHeight="6"
            refX="1"
            refY="3"
            orient="auto"
            markerUnits="userSpaceOnUse"
          >
            <polygon points="8 6, 0 3, 8 0" fill="white" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="8"
            markerHeight="6"
            refX="7"
            refY="3"
            orient="auto"
            markerUnits="userSpaceOnUse"
          >
            <polygon points="0 0, 8 3, 0 6" fill="white" />
          </marker>
        </defs>
      </Box>
      <Box
        position="absolute"
        zIndex={1}
        right={8 + (openTableMode ? openTableWidth : 0)}
        bottom={8}
        display="flex"
      >
        <ZoomButton onClick={d3Helper?.zoomIn}>
          <AddCircleOutline />
        </ZoomButton>
        <ZoomButton onClick={d3Helper?.zoomOut}>
          <RemoveCircleOutline />
        </ZoomButton>
      </Box>
    </Box>
  );
};

const ZoomButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: rgba(29, 25, 33, 0.8);
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    background-color: rgba(77, 59, 79, 0.8);
  }
`;
