import { Box, IconButton, SvgIcon, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as WebsiteIcon } from 'assets/website.svg';
import { ReactComponent as EthscanIcon } from 'assets/ethscan.svg';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';

export type TokenWebsiteProps = {
  website: string;
};
export const TokenWebsite: FC<TokenWebsiteProps> = ({ website }) => {
  return (
    <IconButton sx={{ color: '#fff' }} size="small" component="a" href={website} target="_blank">
      <SvgIcon fontSize="small">
        <WebsiteIcon />
      </SvgIcon>
    </IconButton>
  );
};

export type TokenScanProps = {
  tokenAddress: string;
};
export const TokenScan: FC<TokenScanProps> = ({ tokenAddress }) => {
  const currentChainConfig = useCurrentChainConfig();
  return (
    <IconButton
      sx={{ color: '#fff' }}
      size="small"
      component="a"
      href={currentChainConfig.tokenScanUrl.replace('$token', tokenAddress)}
      target="_blank"
    >
      <SvgIcon fontSize="small">
        <EthscanIcon />
      </SvgIcon>
    </IconButton>
  );
};

export type TokenSocialProps = {
  item: {
    tokenAddress: string;
    website: string;
  };
  sx?: SxProps<Theme>;
};
export const TokenSocial: FC<TokenSocialProps> = ({ item, sx }) => {
  return (
    <Box sx={{ display: 'flex', ...sx }}>
      {!!item.website && <TokenWebsite website={item.website} />}
      <TokenScan tokenAddress={item.tokenAddress} />
    </Box>
  );
};
