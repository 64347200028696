import { RugDetail } from 'types/rug';

export const tokenRugDetail: RugDetail = {
  token: {
    id: '8cd10c9b-4d70-4d4a-a2e0-0ff9e1e365fd',
    tokenAddress: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    fullName: 'Shiba Inu',
    symbol: '$SHI',
    imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/shiba.jpeg',
    website: 'https://shibatoken.com/',
  },
  tokenMeta: {
    pricing: 0.13123123123,
    supply: 1000000000,
    creator: {
      fullName: undefined,
      address: '23D73hG5cxBtB5sdnZRNMA3wKYp7eqX6tfkcSJzyU6wL',
    },
    marketCap: 100000000,
    minAuthority: {
      fullName: undefined,
      address: 'GY4JfF6Bf677AiKszzzkTaAS2s2X3mmiDN6ARTvNo2S1',
    },
    lpLocked: 99,
  },
  risks: [
    {
      name: 'Mutable metadata',
      description: 'Token metadata can be changed by the owner',
      score: 100,
      level: 'danger',
    },
    {
      name: 'Mutable metadata',
      description: 'Token metadata can be changed by the owner',
      score: 200,
      level: 'warn',
    },
  ],
  score: 100,
  riskOverall: 'good', // good | bad
  communitySentiment: {
    sellPercent: 60,
    buyPercent: 40,
  },
  markets: [
    {
      marketType: 'raydium', // Provide by BE
      address: {
        fullName: undefined,
        address: 'FMiecMsYhPdBf94zZKa7i6inK1GX7aypLf7QewNz1i6w',
      },
      pairA: {
        symbol: '$SHI',
        address: 'H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw',
      },
      pairB: {
        symbol: 'SOL',
        address: 'So11111111111111111111111111111111111111112',
      },
      lpMint: {
        fullName: 'LP',
        address: '9a6AJiRzUEv2V752zCBPgKuWFwRh5cUcjyQtUqptpU3Y',
      },
      liquidity: 100,
      lpLocked: 50,
    },
    {
      marketType: 'raydium', // Provide by BE
      address: {
        fullName: undefined,
        address: 'FMiecMsYhPdBf94zZKa7i6inK1GX7aypLf7QewNz1i6w',
      },
      pairA: {
        symbol: '$SHI',
        address: 'H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw',
      },
      pairB: {
        symbol: 'SOL',
        address: 'So11111111111111111111111111111111111111112',
      },
      lpMint: {
        fullName: 'LP',
        address: '9a6AJiRzUEv2V752zCBPgKuWFwRh5cUcjyQtUqptpU3Y',
      },
      liquidity: 12312312,
      lpLocked: 50,
    },
  ],
  holders: [
    {
      address: {
        fullName: undefined,
        address: 'FMiecMsYhPdBf94zZKa7i6inK1GX7aypLf7QewNz1i6w',
      },
      amount: 1000000,
      percentage: 10,
    },
    {
      address: {
        fullName: undefined,
        address: 'FMiecMsYhPdBf94zZKa7i6inK1GX7aypLf7QewNz1i6w',
      },
      amount: 1000000,
      percentage: 10,
    },
  ],
};
