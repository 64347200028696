export const updateUrlParams = (params: Record<string, any>) => {
  const url = new URL(window.location.href);

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value === null || value === undefined || value === false) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
  });
  window.history.replaceState({}, '', url);
  return url;
};
