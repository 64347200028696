import * as d3 from 'd3';
import BezierEasing from 'bezier-easing';
import { D3HelperProps, Graph, GraphLink, GraphNode } from './D3Helper.type';
import { getGroups, getGroupsMainNodes } from './D3Helper.group.utils';
import { ChainSymbol } from 'types/chain';
import { Callback, EventEmitter } from 'utils/EventEmitter';

const clusterColors = [
  '#be3caf',
  '#fd4a85',
  '#ff744a',
  '#e6b22e',
  '#b3eb53',
  '#5cf662',
  '#21e49a',
  '#1fb4d2',
];

const isolatedColor = '#6536a3';

export class D3Helper {
  props: D3HelperProps;
  graph: Graph;

  d3ForceSimulation?: d3.Simulation<GraphNode, undefined>;
  d3SvgElements?: {
    circles: d3.Selection<SVGCircleElement, GraphNode, d3.BaseType, unknown>;
    lines: d3.Selection<SVGLineElement, GraphLink, d3.BaseType, unknown>;
  };
  rootSelection?: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  zoomController?: d3.ZoomBehavior<SVGSVGElement, unknown>;

  d3ForceSimulationFirstTick = false;
  nodesPercents: Record<string, number> = {};
  lastPositions: Record<string, { x: number; y: number }> = {};
  get centerCoords() {
    return {
      x: this.initialViewBox / 2 - (this.props.offsetRight || 0),
      y: this.initialViewBox / 2,
    };
  }
  initialViewBox =
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1000 &&
    Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) > 1000
      ? 1000
      : 650;

  selectNodeEventEmitter = new EventEmitter<GraphNode | null>();

  constructor(props: D3HelperProps) {
    this.props = props;
    this.graph = this.buildGraph();
    this.nodesPercents = this.getNodesPercents();
  }

  updateProps = (props: Partial<D3HelperProps>) => {
    const isChangeOffsetRight = props.offsetRight !== this.props.offsetRight;
    this.props = { ...this.props, ...props };
    this.refresh();

    if (isChangeOffsetRight) {
      this.d3ForceSimulation
        ?.force('center', this.getForceCenter())
        .force('radial', this.getForceRadial())
        .restart()
        .alpha(0.3);
    }
  };

  zoomIn = () => {
    if (!this.zoomController || !this.rootSelection) return;
    this.zoomController.scaleBy(this.rootSelection.transition().duration(200), 1.4);
  };

  zoomOut = () => {
    if (!this.zoomController || !this.rootSelection) return;
    this.zoomController.scaleBy(this.rootSelection.transition().duration(200), 0.7);
  };

  onSelectNode(callback: Callback<GraphNode | null>) {
    const id = this.selectNodeEventEmitter.on(callback);
    return () => this.selectNodeEventEmitter.remove(id);
  }

  buildGraph = (): Graph => {
    const { tokenDetail, hiddenAddresses, mode } = this.props;
    const tokenDetailLinks = [];
    if (mode >= 0) {
      tokenDetailLinks.push(...tokenDetail.tokenLinks[mode].links);
    } else {
      tokenDetail.tokenLinks.forEach((tokenLink) => {
        tokenDetailLinks.push(...tokenLink.links);
      });
    }
    const linkAddresses = tokenDetailLinks
      .filter(
        (link) => link.source < tokenDetail.nodes.length && link.target < tokenDetail.nodes.length
      )
      .map((link) => ({
        ...link,
        source: tokenDetail.nodes[link.source].address,
        target: tokenDetail.nodes[link.target].address,
      }));
    const nodes = tokenDetail.nodes
      .map((node) => ({ ...node }))
      .filter((node) => !hiddenAddresses.includes(node.address));
    const links = linkAddresses
      .filter(
        (link) => !(hiddenAddresses.includes(link.source) || hiddenAddresses.includes(link.target))
      )
      .map((link) => ({
        ...link,
        //@ts-ignore
        source: nodes.findIndex((node) => link.source === node.address) as GraphNode,
        //@ts-ignore
        target: nodes.findIndex((node) => link.target === node.address) as GraphNode,
      })) as GraphLink[];

    const groups = getGroups(links);
    const groupsMainNodes = getGroupsMainNodes(groups, nodes, links);

    return {
      nodes: nodes.map((node, index) => ({ ...node, group: index in groups ? groups[index] : -1 })),
      links,
      groups,
      groupsMainNodes,
    };
  };

  refresh() {
    const lastPositions: Record<string, { x?: number; y?: number }> = {};
    this.graph.nodes.forEach((node) => {
      lastPositions[node.address] = { x: node.x, y: node.y };
    });
    const newGraph = this.buildGraph();
    this.graph.links = newGraph.links;
    this.graph.groupsMainNodes = newGraph.groupsMainNodes;
    this.graph.nodes = newGraph.nodes.map((node) => {
      const oldNode = this.graph.nodes.find((el) => el.address === node.address);

      let defaultPosition: { x: number; y: number } | undefined;
      if (!oldNode) {
        // For new nodes, use last position if available, else if no group
        // place randomly according to size, else center.
        if (node.address in this.lastPositions) {
          defaultPosition = this.lastPositions[node.address];
        } else if (node.group === -1) {
          const r = this.getNodeDefaultRCoord(node);
          const theta = Math.random() * Math.PI * 2;
          defaultPosition = {
            x: this.centerCoords.x + r * Math.cos(theta),
            y: this.centerCoords.y + r * Math.sin(theta),
          };
        } else {
          // Randomize slightly the center positions to avoid infinite forces
          // when adding multiple nodes at the same time
          defaultPosition = {
            x: this.centerCoords.x + 500 * (Math.random() * 0.1 - 0.05),
            y: this.centerCoords.y + 500 * (Math.random() * 0.1 - 0.05),
          };
        }
      }

      return {
        ...defaultPosition,
        ...oldNode,
        ...node,
      };
    });

    this.d3ForceSimulation
      ?.nodes(this.graph.nodes)
      .force('link', this.getForceLinks())
      .restart()
      .alpha(0.3);

    if (this.props.selectedNode?.address) {
      const nodeSelected = this.graph.nodes.find(
        (node) => node.address === this.props.selectedNode?.address
      );
      if (!nodeSelected) this.selectNodeEventEmitter.emit(null);
      else if (nodeSelected.group !== this.props.selectedNode.group)
        this.selectNodeEventEmitter.emit(nodeSelected);
    }
    this.updateD3SvgElements();
  }

  initD3() {
    d3.select('#svg').selectAll('g').remove();

    this.rootSelection = d3
      .select<SVGSVGElement, unknown>('#svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', `0 0 ${this.initialViewBox} ${this.initialViewBox}`);

    const g = this.rootSelection.append('g');
    g.append('g').attr('id', 'circles');
    g.append('g').attr('id', 'lines');

    this.zoomController = d3
      .zoom<SVGSVGElement, unknown>()
      .on('zoom', (event) => g.attr('transform', event.transform));

    this.rootSelection.call(this.zoomController);

    // Init Simulation
    const that = this;
    this.d3ForceSimulation = d3
      .forceSimulation<GraphNode>(this.graph.nodes)
      .force('charge', d3.forceManyBody().strength(-50).distanceMax(250))
      // On first tick force_center is with strength=1 for invisible transition
      .force('center', this.getForceCenter().strength(1))
      .force('radial', this.getForceRadial())
      .force('link', this.getForceLinks())
      .on('tick', () => {
        if (!this.d3SvgElements || !this.d3ForceSimulation) return;
        this.d3SvgElements.lines
          .attr('x1', (d) => {
            return d.source.x!;
          })
          .attr('y1', (d) => {
            return d.source.y!;
          })
          .attr('x2', (d) => {
            return d.target.x!;
          })
          .attr('y2', (d) => {
            return d.target.y!;
          })
          .attr('visibility', 'visible')
          .each(function (d) {
            const classModifiers: string[] = [];
            //Todo Update
            let isSelected = that.props.selectedNode?.group === d.source.group;
            if (isSelected) {
              classModifiers.push('--selected');
            }

            if (d.forward > 0 && d.backward > 0) {
              classModifiers.push('--bidirectional');
            } else if (d.forward > 0) {
              classModifiers.push('--forward');
            } else if (d.backward > 0) {
              classModifiers.push('--backward');
            }
            d3.select(this).attr('class', classModifiers.join(' '));

            if (d.forward > 0) {
              d3.select(this).attr('marker-end', 'url(#endarrow)');
            } else {
              d3.select(this).attr('marker-end', null);
            }
            if (d.backward > 0) {
              d3.select(this).attr('marker-start', 'url(#startarrow)');
            } else {
              d3.select(this).attr('marker-start', null);
            }
          });

        this.d3SvgElements.circles
          .attr('cx', (d) => {
            return d.x!;
          })
          .attr('cy', (d) => {
            return d.y!;
          })
          .attr('class', (d) => {
            return this.props.selectedNode?.address === d.address ? 'nodes--selected' : '';
          });

        // Only on first tick - this condition is just for performance
        if (this.d3ForceSimulationFirstTick) {
          // Get back force center to normal strength
          this.d3ForceSimulation.force('center', this.getForceCenter());
          this.d3ForceSimulationFirstTick = false;
        }
      });
  }

  updateD3SvgElements() {
    // Since D3 modifies the DOM directly, it is not linked to Vue reactivity,
    // so we need to call this manually when the `graph` changes.
    // We do not use a watcher that'd trigger continuously and call this
    // directly in apply_data_settings
    if (this.graph) {
      const g = d3.select('#svg').select('g');

      const lines = g
        .select('#lines')
        .selectAll<SVGLineElement, GraphLink>('line')
        .data(this.graph.links, (link) => {
          return link.source.address + link.target.address;
        })
        .join('line');

      const circles = g
        .select('#circles')
        .selectAll<SVGCircleElement, GraphNode>('circle')
        .data(this.graph.nodes, (node) => {
          return node.address;
        })
        .join(
          (enter) =>
            enter.append('circle').attr('r', (node) => {
              return Math.max(Math.sqrt(this.getPercentAddress(node.address)) * 25, 3);
            }),
          (update) =>
            update
              .transition()
              .duration(400)
              .attr('r', (node: any) => {
                return Math.max(Math.sqrt(this.getPercentAddress(node.address)) * 25, 1);
              }),
          (exit) => exit.remove()
        )
        .attr('fill', (d) => {
          return this.getNodeColor(d);
        })
        .attr('stroke', (d) => {
          return this.getNodeColor(d);
        })
        .attr('stroke-dasharray', (d) => {
          return 'none';
        })
        .call(
          d3
            .drag<SVGCircleElement, GraphNode>()
            .on('start', (event, d) => {
              if (!event.active) this.d3ForceSimulation?.alphaTarget(0.3).restart();
              d.fx = d.x;
              d.fy = d.y;
            })
            .on('drag', (event, d) => {
              d.fx = event.x;
              d.fy = event.y;
            })
            .on('end', (event, d) => {
              if (!event.active) this.d3ForceSimulation?.alphaTarget(0);
              d.fx = null;
              d.fy = null;
            })
        )
        .on('click', (event, node) => {
          this.selectNodeEventEmitter.emit(node);
        });
      this.d3SvgElements = { circles: circles, lines: lines };
    }
  }

  getNodesPercents() {
    const ignoredPercents = this.graph.nodes
      .map((el) => (this.isHidden(el) ? el.percentage : 0))
      .reduce((a, b) => a + b, 0);
    let dividerValue = 100 - ignoredPercents;

    dividerValue = dividerValue < 0 ? 0 : dividerValue;
    return this.graph.nodes.reduce((previous, current) => {
      return {
        ...previous,
        [current.address]: (current.percentage * 100) / dividerValue,
      };
    }, {});
  }

  private getForceCenter() {
    return d3.forceCenter(this.centerCoords.x, this.centerCoords.y).strength(0.03);
  }
  private getForceRadial() {
    let that = this;
    return d3
      .forceRadial<GraphNode>(
        function radius(node) {
          return that.getNodeDefaultRCoord(node);
        },
        this.centerCoords.x,
        this.centerCoords.y
      )
      .strength(0.005);
  }
  private getForceLinks() {
    return d3.forceLink(this.graph.links).distance(50).strength(0.5);
  }
  private getNodeDefaultRCoord(node: GraphNode) {
    return (1 - BezierEasing(0, 1, 0, 1)(node.percentage / 100)) * 400;
  }

  getGroupAddresses(group: number) {
    return this.graph.nodes.filter((el) => el.group === group).map((el) => el.address);
  }

  getGroup(address: string) {
    return this.graph.nodes.find((el) => el.address === address)?.group || -1;
  }

  private getNodeColor(node: GraphNode) {
    return this.getGroupColor(node.group);
  }
  private getColorFromAddress(address: string) {
    const hash =
      this.props.tokenDetail.chain === ChainSymbol.sol
        ? address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
        : parseInt(address.substring(0, 8), 16);
    return clusterColors[hash % clusterColors.length];
  }

  private getGroupColor(group: number, opacityHex = null) {
    let color;
    if (group === -1) {
      color = isolatedColor;
    } else {
      color = this.getColorFromAddress(this.graph.groupsMainNodes[group]);
    }
    return opacityHex ? color + opacityHex : color;
  }

  private getPercentAddress(address: string) {
    return this.nodesPercents[address];
  }

  private isHidden(node: GraphNode) {
    return this.props.hiddenAddresses.includes(node.address);
  }
}
