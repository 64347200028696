import { Box, Button, Grid, ThemeProvider, Typography, createTheme, useTheme } from '@mui/material';
import { SearchButton } from 'components/SearchModal/SearchButton';
import { FC } from 'react';
import { useMostVisitedTokens, usePopularTokens, useRecentlyListedTokens } from 'stores/tokens';
import { ListTokens } from 'components/ListTokens';
import { AccessTimeFilled, EmojiEvents } from '@mui/icons-material';
import { TokenSocial } from 'components/TokenSocial';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { Link } from 'react-router-dom';

export const HomePage: FC = () => {
  const theme = useTheme();
  const currentChainConfig = useCurrentChainConfig();
  const popularTokens = usePopularTokens();
  const mostVisitedTokens = useMostVisitedTokens();
  const recentlyListedTokens = useRecentlyListedTokens();

  return (
    <>
      <Box my={6}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item maxWidth="100%">
            <SearchButton
              placeholder="Search by name, address, symbol"
              sx={{ width: 400, maxWidth: '100%' }}
              size="large"
            />
          </Grid>
          <Grid item>
            <ThemeProvider
              theme={createTheme({
                ...theme,
                palette: {
                  ...theme.palette,
                  primary: {
                    main: currentChainConfig.color,
                  },
                },
              })}
            >
              <Button
                variant="outlined"
                size="large"
                LinkComponent={({ href, ...props }) => <Link {...props} to={href} />}
                href="https://bubblemaps.io/"
                target="_blank"
              >
                LEARN MORE
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid container spacing={{ md: 2.5, xs: 2 }} justifyContent="center">
          {popularTokens.map((token) => (
            <Grid key={token.id} item md={3} xs={6}>
              <Box
                sx={{
                  boxShadow: 'rgba(232, 179, 11, 0.2) 0px 0px 24px',
                  borderRadius: 2.5,
                  border: '1px solid rgba(232, 179, 11, 0.2)',
                  height: {
                    xs: 160,
                    md: 190,
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    transition: 'all ease .3s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    },
                  }}
                  component={Link}
                  to={`/${currentChainConfig.symbol}/token/${token.tokenAddress}`}
                >
                  <Box
                    sx={{
                      width: {
                        md: 70,
                        xs: 50,
                      },
                      height: {
                        md: 70,
                        xs: 50,
                      },
                      overflow: 'hidden',
                      borderRadius: '50%',
                    }}
                  >
                    <img alt="" src={token.imageUrl} />
                  </Box>
                  <Typography textAlign="center" variant="h6" mt={1} fontWeight="bold">
                    {token.fullName}
                  </Typography>
                </Box>
                <TokenSocial item={token} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={6}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <ListTokens
              icon={<EmojiEvents />}
              title="Most Visited"
              items={mostVisitedTokens}
              hasIndex
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ListTokens
              icon={<AccessTimeFilled />}
              title="Recently Listed"
              items={recentlyListedTokens}
            />
          </Grid>
        </Grid>
        <Box textAlign="center" mt={3}>
          <ThemeProvider
            theme={createTheme({
              ...theme,
              palette: {
                ...theme.palette,
                primary: {
                  main: '#bf97d1',
                },
              },
            })}
          >
            <Button
              variant="outlined"
              LinkComponent={({ href, ...props }) => <Link {...props} to={href} />}
              href={`/${currentChainConfig.symbol}/listed-tokens`}
            >
              VIEW ALL LISTED TOKENS
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
    </>
  );
};
