import { ChainSymbol } from 'types/chain';
import { AllTokens, TokenDetail, TokenHistory, TokenLite } from 'types/token';
import { ethTokens, solTokens, tokenDetail, tokenHistories } from './token.mock';
import * as humps from 'humps';
import { baseApi } from './base';

export const getAllTokens = async (): Promise<AllTokens> => {
  const response = await baseApi.get('/main-page');
  const data = response.data.data;

  Object.keys(data).forEach((chain) => {
    const chainTokens: Record<string, any> = { ...data[chain as ChainSymbol] };
    const tokens: TokenLite[] = chainTokens.tokens;
    chainTokens.tokens = new Map<string, TokenLite>(tokens.map((token) => [token.id, token]));
    chainTokens.tokenByAddresses = new Map<string, TokenLite>(
      tokens.map((token) => [token.tokenAddress, token])
    );
    data[chain as ChainSymbol] = chainTokens;
  });
  return data;
};

export const _getAllTokens = async (): Promise<AllTokens> => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const data: any = {
    [ChainSymbol.eth]: ethTokens,
    [ChainSymbol.bsc]: solTokens,
    [ChainSymbol.ftm]: ethTokens,
    [ChainSymbol.avax]: solTokens,
    [ChainSymbol.cro]: ethTokens,
    [ChainSymbol.arbi]: solTokens,
    [ChainSymbol.poly]: ethTokens,
    [ChainSymbol.base]: solTokens,
    [ChainSymbol.sol]: solTokens,
  };

  Object.keys(data).forEach((chain) => {
    const chainTokens: Record<string, any> = { ...data[chain as ChainSymbol] };
    const tokens: TokenLite[] = chainTokens.tokens;
    chainTokens.tokens = new Map<string, TokenLite>(tokens.map((token) => [token.id, token]));
    chainTokens.tokenByAddresses = new Map<string, TokenLite>(
      tokens.map((token) => [token.tokenAddress, token])
    );
    data[chain as ChainSymbol] = chainTokens;
  });
  return data;
};

export const getTokenDetail = async (data: {
  tokenAddress: string;
  historyId?: string;
  chain: ChainSymbol;
}): Promise<TokenDetail> => {
  const response = await baseApi.post('/get-token-detail', data);
  return humps.camelizeKeys<TokenDetail>(response.data.data);
};

export const _getTokenDetail = async (data: {
  tokenAddress: string;
  historyId?: string;
  chain: ChainSymbol;
}): Promise<TokenDetail> => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return humps.camelizeKeys(tokenDetail) as TokenDetail;
};

export const getTokenHistories = async (params: {
  tokenAddress: string;
  chain: ChainSymbol;
}): Promise<TokenHistory[]> => {
  const response = await baseApi.get('/fetch-history', { params });
  return humps.camelizeKeys(response.data.data) as any[];
};

export const _getTokenHistories = async (data: {
  tokenAddress: string;
  chain: ChainSymbol;
}): Promise<TokenHistory[]> => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return tokenHistories;
};
