import { RugDetail } from 'types/rug';
import * as humps from 'humps';
import { baseApi } from './base';
import { tokenRugDetail } from './rug.mock';
import { ChainSymbol } from 'types/chain';

export const getTokenRug = async (data: {
  tokenAddress: string;
  chain: ChainSymbol;
}): Promise<RugDetail> => {
  const response = await baseApi.get('/rugCheck', { params: data });
  return humps.camelizeKeys<RugDetail>(response.data.data);
};

export const _getTokenRug = async (data: {
  tokenAddress: string;
  chain: ChainSymbol;
}): Promise<RugDetail> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return tokenRugDetail;
};
