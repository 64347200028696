import { getAllTokens } from 'api/token';
import { tokenImageUrls } from 'configs/tokens';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useMemo } from 'react';
import { AllTokens, TokenLite } from 'types/token';
import { create } from 'zustand';

export type TokensState = {
  allTokens: AllTokens;
  loading: boolean;
  loadAllTokens: () => Promise<void>;
};

export const useTokensStore = create<TokensState>((set, get) => ({
  // @ts-ignore
  allTokens: null,
  loading: true,
  loadAllTokens: async () => {
    const allTokens = await getAllTokens();
    set({ allTokens, loading: false });
  },
}));

export const usePopularTokens = () => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();
  console.log(state)
  return useMemo(
    () =>
      state.allTokens[currentChain].popularTokenIds
        .map((id) => state.allTokens[currentChain].tokens.get(id)!)
        .filter(Boolean),
    [currentChain, state.allTokens]
  );
};

export const useMostVisitedTokens = () => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();
  return useMemo(
    () =>
      state.allTokens[currentChain].mostVisitedTokenIds
        .map((id) => state.allTokens[currentChain].tokens.get(id)!)
        .filter(Boolean),
    [currentChain, state.allTokens]
  );
};

export const useRecentlyListedTokens = () => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();
  return useMemo(
    () =>
      state.allTokens[currentChain].recentlyListedTokenIds
        .map((id) => state.allTokens[currentChain].tokens.get(id)!)
        .filter(Boolean),
    [currentChain, state.allTokens]
  );
};

export const useTrendingTokens = () => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();
  return useMemo(
    () =>
      state.allTokens[currentChain].trendingTokenIds
        .map((id) => state.allTokens[currentChain].tokens.get(id)!)
        .filter(Boolean),
    [currentChain, state.allTokens]
  );
};

type TokenImageQueryItem = {
  tokenAddress?: string;
  symbol?: string;
};

const getTokenImageUrl = (tokens: Map<string, TokenLite>, query: TokenImageQueryItem) => {
  if (query.symbol && tokenImageUrls[query.symbol]) {
    return tokenImageUrls[query.symbol];
  }
  return query.tokenAddress && tokens.get(query.tokenAddress)?.imageUrl;
};

export const useTokenImageUrl = (query: TokenImageQueryItem) => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();

  return useMemo(() => {
    return getTokenImageUrl(state.allTokens[currentChain].tokenByAddresses, query);
  }, [currentChain, query, state.allTokens]);
};

export const useTokenImageUrls = (queries: TokenImageQueryItem[]) => {
  const currentChain = useCurrentChain();
  const state = useTokensStore();

  return useMemo(() => {
    return queries.map((query) =>
      getTokenImageUrl(state.allTokens[currentChain].tokenByAddresses, query)
    );
  }, [currentChain, state.allTokens, queries]);
};
