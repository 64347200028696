import { Box, SxProps, Theme } from '@mui/material';
import { chainConfigsMap } from 'configs/chains';
import { FC } from 'react';
import { ChainSymbol } from 'types/chain';

export type TagProps = {
  color: string;
  outline?: boolean;
  name: string;
  sx?: SxProps<Theme>;
};
export const Tag: FC<TagProps> = ({ color, outline, name, sx }) => {
  return (
    <Box
      sx={{
        bgcolor: outline ? 'transparent' : color,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: color,
        px: 1,
        py: 0.25,
        borderRadius: 20,
        fontWeight: 'bold',
        color: outline ? color : 'white',
        fontSize: 12,
        ...sx,
      }}
    >
      {name}
    </Box>
  );
};

export const ChainTag: FC<{ chain: ChainSymbol }> = ({ chain }) => {
  return (
    <Tag
      color={chainConfigsMap.get(chain)?.color || '#555'}
      outline={false}
      name={chain.toLocaleUpperCase()}
    />
  );
};
