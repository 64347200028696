import {
  Box,
  Button,
  Divider,
  Drawer,
  Menu,
  MenuItem,
  SvgIcon,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  useTheme,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { ReactComponent as LogoImage } from 'assets/logo.svg';
import { chainConfigs } from 'configs/chains';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import { Search, Verified, Menu as MenuIcon, Login } from '@mui/icons-material';
import { SearchButton } from 'components/SearchModal/SearchButton';
import { useSearchStore } from 'stores/search';

export const Header: FC = () => {
  const theme = useTheme();
  const currentChainConfig = useCurrentChainConfig();
  const { openSearch } = useSearchStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Box id="header">
      <Box
        px={{ md: 8, xs: 2 }}
        height={{ md: 64, xs: 56 }}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#1d1921"
        position="fixed"
        zIndex={100}
        top={0}
      >
        <Box display="flex" alignItems="center">
          <Box
            component={Link}
            to={`/${currentChainConfig.symbol}`}
            height={{ md: 30, xs: 25 }}
            mr={1.5}
          >
            <LogoImage />
          </Box>
          <ChainDropdown />
        </Box>
        <Box display={{ md: 'flex', xs: 'none' }} alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            component={Link}
            to={`/${currentChainConfig.symbol}/listed-tokens`}
          >
            <Verified sx={{ color: '#fff', fontSize: '1.25rem' }} />
            <Typography ml={0.75} whiteSpace="nowrap">
              Listed Tokens
            </Typography>
          </Box>
          <SearchButton placeholder="Search" sx={{ flex: 1, width: 180, ml: 2 }} />
          <Button variant="contained" sx={{ ml: 2 }}>
            LOG IN
          </Button>
        </Box>
        <Box display={{ md: 'none', xs: 'flex' }} columnGap={1}>
          <ThemeProvider
            theme={createTheme({
              ...theme,
              palette: {
                ...theme.palette,
                primary: {
                  main: 'rgba(255,255,255,0.15)',
                },
              },
            })}
          >
            <Button
              variant="contained"
              sx={{ p: 1, minWidth: 0, color: 'white' }}
              onClick={openSearch}
            >
              <Search />
            </Button>
            <Button
              variant="contained"
              sx={{ p: 1, minWidth: 0, color: 'white' }}
              onClick={() => setIsMenuOpen(true)}
            >
              <MenuIcon />
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
      <Box width="100%" height={{ md: 64, xs: 56 }}></Box>
      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: '#090015',
          },
        }}
      >
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setIsMenuOpen(false)}>
          <Box px={1} pt={2} pb={1}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<Login />}
              sx={{ justifyContent: 'start' }}
            >
              LOG IN
            </Button>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer', p: 2 }}
            component={Link}
            to={`/${currentChainConfig.symbol}/listed-tokens`}
          >
            <Verified sx={{ color: '#fff', fontSize: '1.25rem' }} />
            <Typography ml={0.75} whiteSpace="nowrap">
              Listed Tokens
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

const ChainDropdownMenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: 8,
    background: '#262626',
    border: '1px solid #4e4e4e',
    width: 110,
    borderRadius: 16,
  },
}));

const ChainDropdown: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentChainConfig = useCurrentChainConfig();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              main: currentChainConfig.color,
            },
          },
        })}
      >
        <Button
          sx={{ color: 'white', minWidth: 110, fontWeight: 'bold' }}
          variant="contained"
          size="medium"
          startIcon={
            <SvgIcon fontSize="small">
              <currentChainConfig.IconComponent />
            </SvgIcon>
          }
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          {currentChainConfig.symbol.toLocaleUpperCase()}
        </Button>
      </ThemeProvider>
      <ChainDropdownMenuStyled
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {chainConfigs.map((chainConfig) => (
          <MenuItem
            key={chainConfig.symbol}
            onClick={() => {
              navigate(`/${chainConfig.symbol}`);
              handleClose();
            }}
            disableRipple
            sx={{ fontSize: 14, color: 'white' }}
          >
            <SvgIcon sx={{ fontSize: 16, mr: 1 }}>
              <chainConfig.IconComponent />
            </SvgIcon>
            {chainConfig.symbol.toUpperCase()}
          </MenuItem>
        ))}
      </ChainDropdownMenuStyled>
    </>
  );
};
