export const ethTokens = {
  tokens: [
    {
      id: '8cd10c9b-4d70-4d4a-a2e0-0ff9e1e365fd',
      tokenAddress: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      fullName: 'Shiba Inu',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/shiba.jpeg',
      website: 'https://shibatoken.com/',
    },
    {
      id: 'b2bc2ac0-76a9-4385-8fd2-29ae95253fbb',
      tokenAddress: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      fullName: 'Lido Staked ETH',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1dbf317e-2e39-4933-9042-483d78731220',
      website: 'https://lido.fi/',
    },
    {
      id: '7053ed20-0e26-45a8-83c9-ba6f347dab59',
      tokenAddress: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
      fullName: 'CRO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/crypto-com.jpeg',
      website: 'https://www.crypto.com/',
    },
    {
      id: '53912633-88bd-44df-b016-76ddfea332eb',
      tokenAddress: '0x514910771af9ca656af840dff83e8264ecf986ca',
      fullName: 'Chainlink',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/chainlink.jpeg',
      website: 'https://chain.link/',
    },
    {
      id: '0b1d5701-57cb-48fa-b473-06abc94875f5',
      tokenAddress: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
      fullName: 'Binance USD',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/5c6c76a8-881b-496d-b031-65ffefa69bdb',
      website: 'https://www.paxos.com/busd/',
    },
    {
      id: 'd5e681df-00d1-4cb4-a712-38573d1875a4',
      tokenAddress: '0x75231f58b43240c9718dd58b4967c5114342a86c',
      fullName: 'OKB',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/okb.png',
      website: 'https://www.okex.com/okb',
    },
    {
      id: 'c5020f5a-491f-4b47-8671-ce10b4045b0a',
      tokenAddress: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      fullName: 'Uniswap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/uniswap.jpeg',
      website: 'https://uniswap.org/',
    },
    {
      id: '1464a5ab-08c8-4e2d-9698-acfdccb7cbf8',
      tokenAddress: '0x5c147e74d63b1d31aa3fd78eb229b65161983b2b',
      fullName: 'Flow',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/203843e6-8f89-4373-b94d-feda9314853d',
      website: 'https://flow.com/',
    },
    {
      id: 'ea5ee50d-7929-4b34-9d40-4dc40e9636fe',
      tokenAddress: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
      fullName: 'Decentraland',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/decentraland.jpeg',
      website: 'https://decentraland.org/',
    },
    {
      id: 'aece5848-a16b-41bf-8953-42cce0bbf8df',
      tokenAddress: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
      fullName: 'Axie Infinity',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/axie-infinity.jpeg',
      website: 'https://axieinfinity.com/',
    },
    {
      id: 'b11413b2-80b7-4858-84dc-1c135ccf2d01',
      tokenAddress: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
      fullName: 'The Sandbox',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/the-sandbox.jpeg',
      website: 'https://www.sandbox.game/en/',
    },
    {
      id: '065f1446-3aa1-4edf-ab93-1a9064d67277',
      tokenAddress: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
      fullName: 'Pepe',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/de6c7a61-956b-480e-96fa-2b52844b7c38',
      website: 'https://www.pepe.vip/',
    },
    {
      id: 'f3653e53-7e2d-46ad-8080-9bb609fac0b7',
      tokenAddress: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
      fullName: 'dYdX',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ad895627-0afd-4070-8b33-6aba4749ed68',
      website: 'https://dydx.exchange/',
    },
    {
      id: '8177fb95-a063-48a1-9720-09650188e7e3',
      tokenAddress: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      fullName: 'AAVE',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/aave.jpeg',
      website: 'https://app.aave.com/',
    },
    {
      id: '6d1ceaf1-9432-45cb-b060-360a5e4c777f',
      tokenAddress: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
      fullName: 'Lido DAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2758e750-61b7-4eb1-9c0b-8b66e806e58a',
      website: 'https://lido.fi/',
    },
    {
      id: '277975fc-a040-49c1-9479-4cd436b08b56',
      tokenAddress: '0x19de6b897ed14a376dda0fe53a5420d2ac828a28',
      fullName: 'Bitget ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/7934593c-540a-467f-aba3-e6e0dc4e1219',
      website: 'https://www.bitget.com/',
    },
    {
      id: '5f741430-4951-498a-ab25-c40cfe31154d',
      tokenAddress: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
      fullName: 'CryptoPunks',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4811dcf9-e51f-4f83-99dd-77c3ee1213e9',
      website: 'https://cryptopunks.app/',
    },
    {
      id: '38f8c9ed-50ef-45b3-b62b-12ed36eaddde',
      tokenAddress: '0x4d224452801aced8b2f0aebe155379bb5d594381',
      fullName: 'ApeCoin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/27e87c89-0742-402f-93e4-f45bfb960420',
      website: 'https://apecoin.com/',
    },
    {
      id: '129c3f54-a84c-4f78-adcd-84f72034aedf',
      tokenAddress: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
      fullName: 'The Graph',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e1e77f0a-22a0-4c29-b258-372ceae1fcf9',
      website: 'https://thegraph.com/',
    },
    {
      id: 'f475e8c2-81ee-4c31-9f4a-0aa82b99dcd3',
      tokenAddress: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
      fullName: 'Maker',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/maker-dao.jpeg',
      website: 'https://makerdao.com/',
    },
    {
      id: 'cc59c401-2cef-4717-b6eb-4a0f776e5cc5',
      tokenAddress: '0x8d983cb9388eac77af0474fa441c4815500cb7bb',
      fullName: 'Cosmos ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cdfb916e-6ec4-4fb2-86cc-3afd5fbc0eb2',
      website: 'https://cosmos.network/',
    },
    {
      id: '6c6de615-f7a7-47a9-b7fc-4f1640bb28a2',
      tokenAddress: '0xa87135285ae208e22068acdbff64b11ec73eaa5a',
      fullName: 'Lunr Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/afbbe5fd-6462-433d-938b-a12123e0ccf6',
      website: 'https://lunarcrush.com',
    },
    {
      id: '65fca5ac-973f-4604-8367-78a980f51691',
      tokenAddress: '0xf819d9cb1c2a819fd991781a822de3ca8607c3c9',
      fullName: 'Unibot',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/47d789d5-7041-48ad-b1fa-ebc8455b5170',
      website: 'https://unibot.app/',
    },
    {
      id: '0c30e133-3a36-4478-93ac-ed9f5aad0a5f',
      tokenAddress: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
      fullName: 'Amp',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/69a81697-66fd-4831-af3a-431c9dc83ce5',
      website: 'https://amptoken.org/',
    },
    {
      id: 'c6f6cb9e-0dff-4240-9f38-f0cbf9ead274',
      tokenAddress: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
      fullName: 'Enjin Coin',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/enjin.jpeg',
      website: 'https://enjin.io/',
    },
    {
      id: 'b921758f-7bc5-4471-9686-be09baf6c668',
      tokenAddress: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
      fullName: 'SXP',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/be380edc-5e7f-412f-a186-e53c5fe894b9',
      website: 'https://swipe.io/',
    },
    {
      id: '78022469-0ed7-49c3-a22a-59e9bb8f4b81',
      tokenAddress: '0xd1d2eb1b1e90b638588728b4130137d262c87cae',
      fullName: 'GALA - V2',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/413e8a1f-6a2f-4e74-829b-cd7a0992cda1',
      website: 'https://gala.games/',
    },
    {
      id: '3e38e12f-1853-4d0b-b17b-afa53eda29d6',
      tokenAddress: '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da',
      fullName: 'GALA - V1',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/541ab574-1dc9-43f4-9820-75aa2fac9c2b',
      website: 'https://gala.games/',
    },
    {
      id: '964adfb6-4e73-4bf0-a542-a4efa3c87a26',
      tokenAddress: '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
      fullName: 'BitDAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1cad91bc-30ae-42a1-8f9c-7f263aa42e53',
      website: 'https://www.bitdao.io/',
    },
    {
      id: 'a0032e16-a750-4f4c-9826-4c03b8c2e7a5',
      tokenAddress: '0x42bbfa2e77757c645eeaad1655e0911a7553efbc',
      fullName: 'BOBA Network ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/9b07956c-9917-40aa-94e6-bd47612a53b1',
      website: 'https://boba.network',
    },
    {
      id: '02530826-1250-4f71-9595-ea097cbf69a4',
      tokenAddress: '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
      fullName: 'Olympus',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/167a4283-1684-466a-b3c6-eecab6f712f5',
      website: 'https://olympusdao.finance/',
    },
    {
      id: 'b2bbbeb3-b5cc-4b2f-9bd1-fc17f38d192f',
      tokenAddress: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
      fullName: 'FTT',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/48595485-d72f-4889-9a7e-5ac0504120b9',
      website: 'https://en.wikipedia.org/wiki/FTX_(company)',
    },
    {
      id: 'da6e5c67-e266-46b1-b51f-e8f1fccc2cc2',
      tokenAddress: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
      fullName: 'Chiliz',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/chiliz.jpeg',
      website: 'https://www.chiliz.com/',
    },
    {
      id: '810bf855-46cb-4f93-9715-246dc5f52557',
      tokenAddress: '0xc00e94cb662c3520282e6f5717214004a7f26888',
      fullName: 'Compound',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0ebfd175-96fe-4fa2-896b-6286318fb0e3',
      website: 'https://compound.finance/governance/comp',
    },
    {
      id: '6683f2d2-5ad6-4123-af7f-4c5a75ffd450',
      tokenAddress: '0xd533a949740bb3306d119cc777fa900ba034cd52',
      fullName: 'Curve DAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/069b673f-3888-4e63-9144-8d0a708fede5',
      website: 'https://www.curve.fi/',
    },
    {
      id: '9a3f5952-547e-4827-a83c-f7713fd2955f',
      tokenAddress: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
      fullName: 'Looks Rare',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/looksrare.jpeg',
      website: 'https://looksrare.org/',
    },
    {
      id: '82600d10-113b-405a-9fcb-5166664e9af6',
      tokenAddress: '0x04abeda201850ac0124161f037efd70c74ddc74c',
      fullName: 'Nest Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/be55623e-3c41-4e00-9c54-8935bb8a0796',
      website: 'https://nestprotocol.org/',
    },
    {
      id: 'a555650a-0412-40a6-8390-65c6943eb804',
      tokenAddress: '0x74e9fee3fcb56bccac22e726cce7a78ca90185e1',
      fullName: 'RIZO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4009fcad-dc55-43c8-96ce-6a6396f3fcdd',
      website: 'https://rizo.lol/',
    },
    {
      id: '72ceb5f4-2eca-4e4b-bfad-74ccf27f5030',
      tokenAddress: '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
      fullName: 'IoTeX',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8bfae304-bf4c-4c26-9ba7-622ee01489f2',
      website: 'https://www.iotex.io/',
    },
    {
      id: 'd839ec04-f192-4742-bc0a-20f907fb68ce',
      tokenAddress: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414',
      fullName: 'e-Radix',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/radix.png',
      website: 'https://www.radixdlt.com/',
    },
    {
      id: '2010d5ba-7635-460d-abe6-268a2d2065a3',
      tokenAddress: '0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d',
      fullName: 'Voyager Token',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/voyager.png',
      website: 'https://www.investvoyager.com/',
    },
    {
      id: '0edab5c5-3188-4706-b7e2-80320429ace4',
      tokenAddress: '0x67859a9314b9dca2642023ad8231beaa6cbf1933',
      fullName: 'WOLF',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c1448699-170e-432d-96a0-d550491956f1',
      website: 'https://www.0x678landwolf1933.com',
    },
    {
      id: '82c1b3d1-334c-44d2-b15e-bad6b88b3503',
      tokenAddress: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
      fullName: 'UMA',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ee90d22b-bc5c-411f-9330-c25fbe99d317',
      website: 'https://umaproject.org/',
    },
    {
      id: '3417cd2f-ae90-4190-8ce1-99915dda23c2',
      tokenAddress: '0x111111111117dc0aa78b770fa6a738034120c302',
      fullName: '1inch',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/696174e3-87ca-4a7a-9ab9-6e5a43d25024',
      website: 'https://1inch.io/',
    },
    {
      id: 'b3cd78f7-f9f8-4525-8715-09685ef2aa7f',
      tokenAddress: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
      fullName: 'Ankr',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ankr.jpeg',
      website: 'https://stakefi.ankr.com/',
    },
    {
      id: 'b46cbaf3-bcd5-43bf-b9a0-1c2e2e83a0a8',
      tokenAddress: '0x58b6a8a3302369daec383334672404ee733ab239',
      fullName: 'Livepeer ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/840fa002-5ac8-4297-89c1-9cf4bcc713d9',
      website: 'https://livepeer.org/',
    },
    {
      id: 'da4d6f27-8e34-4ae1-a1ea-295767d8fc66',
      tokenAddress: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
      fullName: 'NuCypher ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1f4c8c4a-0eca-40a1-9383-5a0e6503658e',
      website: 'https://www.nucypher.com/',
    },
    {
      id: '0d8d5708-08d9-4474-92f9-6fb43e6756c8',
      tokenAddress: '0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
      fullName: 'Dogelon Mars',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/dogelon-mars.jpeg',
      website: 'https://dogelonmars.com/',
    },
    {
      id: 'c16c4e88-2a1c-41aa-bf7a-f510d55efae3',
      tokenAddress: '0xe6502cee5b040d813d0a9d8cba60c096a22e3ec9',
      fullName: 'Conan Token ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/59e5f062-fd7d-4684-80b7-4aa03c399184',
      website: 'https://www.conantoken.com/en',
    },
    {
      id: 'bc8fb3a4-eb53-444c-a5b5-084d6d0f5a1c',
      tokenAddress: '0x7d0c49057c09501595a8ce23b773bb36a40b521f',
      fullName: 'Preme',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c3b18807-1ef9-4bab-8934-c32e5cd24555',
      website: 'https://premetoken.com/',
    },
    {
      id: '71bc70b5-3817-4899-9f85-0b23a74d5ce0',
      tokenAddress: '0x955d5c14c8d4944da1ea7836bd44d54a8ec35ba1',
      fullName: 'Refund',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a9d6999f-9414-4602-bbe3-45147d77c615',
      website: 'https://refundcoin.org/',
    },
    {
      id: 'c8f3509b-07fd-4686-a056-ad8f26d2cc4c',
      tokenAddress: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
      fullName: 'Magic Internet Money',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/129c7f3d-e49c-441d-96af-1091b36ab8cc',
      website: 'https://abracadabra.money/',
    },
    {
      id: '20f851e8-8909-4ed0-8059-95f57a112a47',
      tokenAddress: '0xc17c30e98541188614df99239cabd40280810ca3',
      fullName: 'EverRise',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/33378213-0bc0-4767-b5dc-edfac0a23d6f',
      website: 'https://www.everrisecoin.com/',
    },
    {
      id: '076636ab-708d-41d7-af50-10d1e16175f6',
      tokenAddress: '0xe41d2489571d322189246dafa5ebde1f4699f498',
      fullName: '0x',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0x.jpeg',
      website: 'https://0x.org/',
    },
    {
      id: '26335cef-3bee-4ef9-9341-a225d1ff90ad',
      tokenAddress: '0x15e6e0d4ebeac120f9a97e71faa6a0235b85ed12',
      fullName: 'SatoshiVM',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/188f7c20-5229-48c8-b96e-f90c7bd7c38e',
      website: 'https://www.satoshivm.io/',
    },
    {
      id: '06ee8eda-f790-4420-80ba-707762b79b75',
      tokenAddress: '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e',
      fullName: 'MXC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/3ad1b5ab-0d21-4218-b596-299f82e4d1e8',
      website: 'https://www.mxc.org/',
    },
    {
      id: '350a62cd-ec20-4816-9239-1af371d82588',
      tokenAddress: '0x9bf1d7d63dd7a4ce167cf4866388226eeefa702e',
      fullName: 'Ben',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/687464c7-d35d-419d-ab0d-920eae156f1e',
      website: 'https://bencoineth.com/',
    },
    {
      id: 'bca72217-d69b-49f3-b12a-b789bc64a78e',
      tokenAddress: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
      fullName: 'Rally',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/95dbed1c-79fa-42af-a5bb-4417c8dd997b',
      website: 'https://rly.network/',
    },
    {
      id: 'ae70eba9-df57-474f-9769-e69fa6cf087b',
      tokenAddress: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
      fullName: 'Golem',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/golem.jpeg',
      website: 'https://golem.network/',
    },
    {
      id: '9b4623eb-4683-4392-8617-66c5fd2ae4c1',
      tokenAddress: '0xa186d739ca2b3022b966194004c6b01855d59571',
      fullName: 'ATTICC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/60e86096-f608-4444-9a66-3dc9a70e3000',
      website: 'https://atticc.xyz/',
    },
    {
      id: 'af8a6d1e-53d0-4c68-abc3-9f2ec12778b1',
      tokenAddress: '0x408e41876cccdc0f92210600ef50372656052a38',
      fullName: 'Ren',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/b5f78dbf-16b0-4e4a-8938-747a22392c28',
      website: 'https://renproject.io',
    },
    {
      id: '21ed11fa-592f-4afd-9688-12c4857192d3',
      tokenAddress: '0x5245c0249e5eeb2a0838266800471fd32adb1089',
      fullName: 'Raydium',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/77c6315e-509c-4fc4-8d57-331dbe762043',
      website: 'https://raydium.io/#/',
    },
    {
      id: 'da2081cb-362f-41ce-b851-665c7476f705',
      tokenAddress: '0x5283d291dbcf85356a21ba090e6db59121208b44',
      fullName: 'Blur ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/08f430bb-1dee-4f2d-bd34-d59056993505',
      website: 'https://blur.io/',
    },
    {
      id: 'd55f0cbc-7665-4aa6-ad1a-3b0689a9d64d',
      tokenAddress: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
      fullName: 'Storj',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1228ac44-e5da-4191-8215-1365d3563c51',
      website: 'https://www.storj.io/',
    },
    {
      id: 'da9bc84c-2477-4129-975e-ed82e3079808',
      tokenAddress: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
      fullName: 'Kyber Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/3a6bedef-146c-4f90-a3eb-caf50d292946',
      website: 'https://kyber.network/',
    },
    {
      id: '169f140b-06bb-415e-a77c-b18c3586a28e',
      tokenAddress: '0x8f8221afbb33998d8584a2b05749ba73c37a938a',
      fullName: 'Request',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4f32eefa-4e76-49c4-9285-fa212af2db2c',
      website: 'https://request.network/',
    },
    {
      id: '38a11953-323f-41a4-ba96-f97bfc3143f1',
      tokenAddress: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
      fullName: 'EURS',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/77ff80c9-4ff9-47ef-89d5-e2e68e00dc68',
      website: 'https://stasis.net/',
    },
    {
      id: '951d2cd3-3269-4219-aaac-9ecf6bc4b9f2',
      tokenAddress: '0x05fb86775fd5c16290f1e838f5caaa7342bd9a63',
      fullName: 'Hacken HAI',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c49dee6e-3c2f-4870-a807-57cac794d2a6',
      website: 'https://hacken.io/',
    },
    {
      id: '8a0c593a-eacc-4973-b07c-5e427d5bfed2',
      tokenAddress: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
      fullName: 'Ultra',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ultra.png',
      website: 'https://ultra.io/',
    },
    {
      id: '5c96f9a6-ddc3-49d4-bd94-a30f2419fad5',
      tokenAddress: '0xa35923162c49cf95e6bf26623385eb431ad920d3',
      fullName: 'Turbo ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4680f247-dba3-4519-a5dc-bac7889bc788',
      website: 'https://sites.google.com/view/turbotoad',
    },
    {
      id: '1ffce2bb-ffd2-47b2-8c1e-6205fd8735fa',
      tokenAddress: '0x1581f848609edb8136955ecef14b0262b707acd4',
      fullName: 'MYLE',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d73bb8c1-4ce4-4010-a2d9-f5a493ad44ca',
      website: 'https://Myletoken.com',
    },
    {
      id: 'cf3db771-ce51-4e04-9720-acf87c7b1162',
      tokenAddress: '0x853d955acef822db058eb8505911ed77f175b99e',
      fullName: 'Frax',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/144ef7b3-7cf2-4e10-8b3a-782a59dba22c',
      website: 'https://frax.finance/#welcome',
    },
    {
      id: '890d2f08-b406-4182-9758-0c95446d3ee1',
      tokenAddress: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
      fullName: 'HEX',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c0ff19b8-29ec-4f21-84c2-8ebf0bb0378c',
      website: 'https://hex.com/',
    },
    {
      id: '2b9cd1c9-beb0-4ecf-9ea2-19d58d3228e5',
      tokenAddress: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
      fullName: 'Bancor',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/07617501-485f-49b2-9808-4735a4cf6a9e',
      website: 'https://bancor.network/',
    },
    {
      id: '4804a6b7-fa5e-4576-96c5-6c8fdd228a6b',
      tokenAddress: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
      fullName: 'Verse',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/71d4c5b7-837a-4bb3-9b81-190ead7c19b9',
      website: 'https://verse.bitcoin.com/',
    },
    {
      id: '8e753186-88c4-46bc-8c54-6227d3aefd95',
      tokenAddress: '0x320623b8e4ff03373931769a31fc52a4e78b5d70',
      fullName: 'Reserve Rights',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/22e4ca3d-f338-40f6-ac3e-05d749adfa25',
      website: 'https://reserve.org/',
    },
    {
      id: '30746649-dd85-41d3-9f34-1cf77792b08e',
      tokenAddress: '0xcdf7028ceab81fa0c6971208e83fa7872994bee5',
      fullName: 'Threshold Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a48983cc-2597-4689-a031-f2ac4192d7ab',
      website: 'https://threshold.network/',
    },
    {
      id: 'fb70b2c5-fde7-49dd-be40-a47e70553ab6',
      tokenAddress: '0xe939f011a3d8fc0aa874c97e8156053a903d7176',
      fullName: 'DOLZ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d21f5101-caf7-455e-9752-3982980c4166',
      website: 'https://dolz.io/',
    },
    {
      id: '3578cae9-527d-4c04-a8c3-c08b9b61261c',
      tokenAddress: '0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05',
      fullName: 'Gelato GEL',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/dae61750-68a7-4800-9f4a-ffc163173f3f',
      website: 'https://www.gelato.network/',
    },
    {
      id: '163ae03b-e3a7-4b18-8824-a4cb66a67b23',
      tokenAddress: '0x5a8291d0f1836726aea75b979a92a47be1ff0cba',
      fullName: 'EdgeAI World',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/fdddab51-bd96-4b0a-a3bf-8a02cdbf1626',
      website: 'https://edgeai.world/',
    },
    {
      id: '0c41aaf0-ddc2-4b42-b918-e3c23abd6157',
      tokenAddress: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
      fullName: 'Swipe',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/swipe.jpeg',
      website: 'https://www.swipe.io/',
    },
    {
      id: '2b1203c3-f585-4318-9ca1-ebc2c891bf91',
      tokenAddress: '0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b',
      fullName: 'UFO Gaming',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ufo-gaming.jpeg',
      website: 'https://ufogaming.io/',
    },
    {
      id: '02a765d1-bc50-4832-82d6-c92992a074b7',
      tokenAddress: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
      fullName: 'Keep Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/56e7574b-d51d-4322-9691-310bb306fdbc',
      website: 'https://keep.network/',
    },
    {
      id: 'ac064321-fb09-405b-8745-9772791c488f',
      tokenAddress: '0x090185f2135308bad17527004364ebcc2d37e5f6',
      fullName: 'Spell ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f688173c-3784-4790-813c-482e8c99acad',
      website: 'https://abracadabra.money/',
    },
    {
      id: '3fad6d09-7fe3-4ebd-bd33-80b6d6c91df1',
      tokenAddress: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
      fullName: 'Orbs',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/orbs.jpeg',
      website: 'https://www.orbs.com/',
    },
    {
      id: '132f199e-d07f-4744-abaf-5ae066ab22e7',
      tokenAddress: '0xfe2e637202056d30016725477c5da089ab0a043a',
      fullName: 'StakeWise',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/53483517-1ea8-418b-a37b-80424b6cfe70',
      website: 'https://stakewise.io/',
    },
    {
      id: '408b2a6f-8f87-486a-b038-07c15f5480fe',
      tokenAddress: '0x3001f57f8308b189eb412a64322aad5ef9951290',
      fullName: 'GEC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/9a330cf0-222a-418d-9eb0-f8ea5362b631',
      website: 'https://gectoken.com/',
    },
    {
      id: 'c38bf2c2-c7f2-4a5c-befb-0d7f5b4eab03',
      tokenAddress: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf',
      fullName: 'Reef Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/reef.png',
      website: 'https://reef.finance/',
    },
    {
      id: '6484fca3-ccf2-4767-a9f8-38577d9f106c',
      tokenAddress: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
      fullName: 'Metis',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0b3b5efe-0598-4df6-88ba-a3f4b805d57f',
      website: 'http://Metis.io',
    },
    {
      id: 'b8821a62-016c-4960-8f84-93cf87cd465b',
      tokenAddress: '0xc5fb36dd2fb59d3b98deff88425a3f425ee469ed',
      fullName: 'Dejitaru Tsuka',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/22836f8b-cb3e-4303-a19e-0136be7332b5',
      website: 'https://www.dejitarutsuka.io/',
    },
    {
      id: '55fb0cb6-9e80-47e0-be32-bfe117affa6d',
      tokenAddress: '0xf433089366899d83a9f26a773d59ec7ecf30355e',
      fullName: 'Metal',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c565fe36-862e-4926-9e1c-93a240124082',
      website: 'https://www.metalpay.com/',
    },
    {
      id: '99b71905-c19b-4f6f-86b6-5e4374d90e3a',
      tokenAddress: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
      fullName: 'Celer Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/b1a557ee-2489-4893-88cd-92b704cd4092',
      website: 'https://www.celer.network/',
    },
    {
      id: 'e029e56c-ba02-4267-ab62-79cb04a6865f',
      tokenAddress: '0x993864e43caa7f7f12953ad6feb1d1ca635b875f',
      fullName: 'SingularityDAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f814c13a-014e-42c3-903c-55b71ff3fdf5',
      website: 'https://www.singularitydao.ai/',
    },
    {
      id: '3dd2bb8d-673b-44bc-bd65-d74597a564cf',
      tokenAddress: '0xcb86c6a22cb56b6cf40cafedb06ba0df188a416e',
      fullName: 'inSure DeFi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/insure-defi.jpeg',
      website: 'https://insuretoken.net/',
    },
    {
      id: '8b23f0ec-0b23-4534-8a34-18272d8b8d2a',
      tokenAddress: '0x579cea1889991f68acc35ff5c3dd0621ff29b0c9',
      fullName: 'IQ ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d95e3e04-cde4-48d7-a406-befe1d4e5c7d',
      website: 'https://everipedia.org/',
    },
    {
      id: '3f3db965-3c96-4250-a693-4280786c7290',
      tokenAddress: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
      fullName: 'TrueFi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/21e7ac0c-ae30-4f1d-b72c-45ebdb430025',
      website: 'http://truefi.io/',
    },
    {
      id: '032899f8-2bde-492c-a31f-e5d3d525deaa',
      tokenAddress: '0x0391d2021f89dc339f60fff84546ea23e337750f',
      fullName: 'BarnBridge ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c2036483-a7fb-4f4a-935e-eff2482cc2bc',
      website: 'https://barnbridge.com/',
    },
    {
      id: '3fb4a031-e97d-4aab-9021-a25e7e3b0e0e',
      tokenAddress: '0x75858677e27c930fb622759feaffee2b754af07f',
      fullName: 'Phantasma',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c9dbd1a4-0b11-428e-91b4-4283ac2ebc1e',
      website: 'https://phantasma.io/',
    },
    {
      id: '2aaada0a-fe2a-4314-8be5-8c8fc2acc27f',
      tokenAddress: '0x910e5566f13bb293c03c900785c75a754f7e42c6',
      fullName: 'Rekt Ralph',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1d849605-27ef-4e18-8372-bf5b6152ebec',
      website: 'https://rekt-ralph.com/',
    },
    {
      id: 'd0240fc8-f68b-445b-a234-b44428907a37',
      tokenAddress: '0xbededdf2ef49e87037c4fb2ca34d1ff3d3992a11',
      fullName: 'FEG Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/34efd783-e956-471b-8e4a-f27f7963428a',
      website: 'https://fegtoken.com/',
    },
    {
      id: '416598cc-2c88-4220-adff-128154d1dbff',
      tokenAddress: '0xac3211a5025414af2866ff09c23fc18bc97e79b1',
      fullName: 'Dovu',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/75eda73c-d3c8-4fdf-be54-ed9f786e266c',
      website: 'https://dovu.earth/',
    },
    {
      id: 'e4fe01e5-d08d-486b-8d55-7e68437eea3e',
      tokenAddress: '0x3b604747ad1720c01ded0455728b62c0d2f100f0',
      fullName: 'WAGMI GAMES',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ac2cc245-fa2e-47b4-9335-81494132ccaa',
      website: 'https://wagmigame.io/',
    },
    {
      id: 'bc407176-6b05-4252-b0ef-d3271ea01d96',
      tokenAddress: '0x8790f2fc7ca2e7db841307fb3f4e72a03baf7b47',
      fullName: 'spillways.finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c3c6adb1-b1e2-423a-a19a-da5dc3da4b70',
      website: 'https://spillways.finance/',
    },
    {
      id: '7911cd25-a973-484c-992c-89d71f0976ef',
      tokenAddress: '0x83249c6794bca5a77eb8c0af9f1a86e055459cea',
      fullName: 'gigaswapfinance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1b3fa50a-1ff5-4ba5-9290-2e6ce8213942',
      website: 'https://t.co/kP3fTLQmUN',
    },
    {
      id: 'e597fb90-cbb9-4a18-bd74-8130c6b3992b',
      tokenAddress: '0xf411903cbc70a74d22900a5de66a2dda66507255',
      fullName: 'Verasity',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/verasity.jpeg',
      website: 'https://verasity.io/',
    },
    {
      id: 'a5a4a6e9-a1c4-4a3f-a548-1dda6c130f63',
      tokenAddress: '0x221657776846890989a759ba2973e427dff5c9bb',
      fullName: 'Augur',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/augur.png',
      website: 'http://www.augur.net/',
    },
    {
      id: 'd4bb644d-4f1f-408b-a769-ab6df4cc282e',
      tokenAddress: '0xb7b1570e26315baad369b8ea0a943b7f140db9eb',
      fullName: 'DEEPSPACE  ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/eefa5bdb-2e24-4bd0-958d-1faedb708780',
      website: 'https://deepspace.game/',
    },
    {
      id: 'd2eef4b7-ca9c-4fe6-8649-0950472ffe6d',
      tokenAddress: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e',
      fullName: 'Phala Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8ad28e0e-c791-41ef-8940-f3b3ea56b33d',
      website: 'https://phala.network/',
    },
    {
      id: '0e4343d8-4343-452c-ac54-77e5508a6cae',
      tokenAddress: '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0',
      fullName: 'AllianceBlock',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/eea706d1-1229-452c-9331-8f6c33c0b4d5',
      website: 'https://allianceblock.io/',
    },
    {
      id: '2e00f333-1cad-4683-a2be-e8b76985730d',
      tokenAddress: '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f',
      fullName: 'Gitcoin ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/595436ef-a9f7-4760-9020-97e9d4499a13',
      website: 'https://gitcoin.co/',
    },
    {
      id: '313dec8f-2cc4-4903-b97c-c021490ba0aa',
      tokenAddress: '0x66d28cb58487a7609877550e1a34691810a6b9fc',
      fullName: 'Koinos',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e8210589-8865-4277-9872-46fb1997e974',
      website: 'https://koinos.io/',
    },
    {
      id: '7b989d5a-a559-4f9e-85a8-80c17bd2f270',
      tokenAddress: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
      fullName: 'Rarible',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/rarible.jpeg',
      website: 'https://app.rarible.com/rari',
    },
    {
      id: '04b04e32-726b-4212-8129-9bb7280555a3',
      tokenAddress: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
      fullName: 'Litentry',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/28060386-8267-4f29-be0d-6bcf1619d892',
      website: 'https://www.litentry.com/',
    },
    {
      id: '18ee69ab-21c6-4da4-9640-92660980a4f9',
      tokenAddress: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
      fullName: 'Floki Inu',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/floki.png',
      website: 'https://www.floki.com/',
    },
    {
      id: '897ad49c-55ef-426c-97f7-3ce2ff7cc0c3',
      tokenAddress: '0xfad45e47083e4607302aa43c65fb3106f1cd7607',
      fullName: 'Hoge Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/hoge.jpeg',
      website: 'https://hoge.finance/',
    },
    {
      id: '06fff533-dfe0-45ff-aa33-d0e5c271d868',
      tokenAddress: '0xa1d65e8fb6e87b60feccbc582f7f97804b725521',
      fullName: 'DXdao',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6ecda18b-6146-4267-8337-d3dcbbe1612b',
      website: 'https://dxdao.eth.link/',
    },
    {
      id: '17c6291b-f00b-485f-a65a-531600dd1f77',
      tokenAddress: '0xfb7b4564402e5500db5bb6d63ae671302777c75a',
      fullName: 'DexTools',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/dextools.jpeg',
      website: 'https://www.dextools.io/',
    },
    {
      id: 'e1850895-221c-4d80-8732-dc4506b1dbc2',
      tokenAddress: '0x90b831fa3bebf58e9744a14d638e25b4ee06f9bc',
      fullName: 'Mimo Governance Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4308d0bb-993e-4b16-a3d1-3e4fa10849fb',
      website: 'https://mimo.capital/',
    },
    {
      id: 'b129bb6d-d4b6-4de5-b33c-a6bbe62661b8',
      tokenAddress: '0x0ab87046fbb341d058f17cbc4c1133f25a20a52f',
      fullName: 'Governance OHM',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/78543059-9025-4182-88de-5d4084aa723a',
      website: 'https://www.olympusdao.finance/',
    },
    {
      id: '08422745-fd1a-4ce0-81c9-d3de406997c4',
      tokenAddress: '0x804ea14b08dec488e5b0bc408f23eef107fe3717',
      fullName: 'Venom',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f4aa2096-8466-49ab-be72-9b87679498ab',
      website: 'https://venomcrypto.io',
    },
    {
      id: 'cbec7f0e-2574-43f9-b0bb-0f45a840c47e',
      tokenAddress: '0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed',
      fullName: 'Rainicorn ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d2ae5fc6-74a0-4bb9-a330-9c032383b74a',
      website: 'https://rainicoin.io/',
    },
    {
      id: '7296c356-5c0b-468e-9311-1d9c311523d8',
      tokenAddress: '0x8a854288a5976036a725879164ca3e91d30c6a1b',
      fullName: 'GET Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f3f27f11-6a3e-41e5-a748-3d8e93de5c50',
      website: 'https://get-protocol.io/',
    },
    {
      id: '7810f10b-b01b-4dda-a94e-7378eab3d94e',
      tokenAddress: '0xb17c88bda07d28b3838e0c1de6a30eafbcf52d85',
      fullName: 'Shyft Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e8e0421e-5827-47c9-af2c-ae46a2358f6b',
      website: 'https://www.shyft.network/',
    },
    {
      id: '33da80fc-2ae6-4d7f-84d4-d463a21fcab1',
      tokenAddress: '0xdacd69347de42babfaecd09dc88958378780fb62',
      fullName: 'Atari ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/44fee197-3f2f-4532-ba64-ed6ebeb32868',
      website: 'https://www.atarichain.com/',
    },
    {
      id: 'd4ffceac-a629-4942-80a7-96c9ef74ef23',
      tokenAddress: '0xf3a3023e6dede84ad88a656a3269f2a36e83c9a9',
      fullName: 'Sharbi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/61b44ed9-0b70-43e5-8f22-ccf68f00a1a3',
      website: 'https://www.sharbi.net/',
    },
    {
      id: '872cc5e2-d899-4ee7-9a66-a3c49698bcb8',
      tokenAddress: '0x32b86b99441480a7e5bd3a26c124ec2373e3f015',
      fullName: 'Bad Idea AI',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a5299240-44d7-4da3-be9f-892d4259ab42',
      website: 'https://badidea.ai',
    },
    {
      id: 'f86263e5-1b95-40e7-a596-e0056ec83836',
      tokenAddress: '0x590619f44e1550cccd6f7778ae73947050aec22b',
      fullName: 'Blu Arctic',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/81bdf0ad-6d6f-476e-83ba-294dc4cfca0a',
      website: 'https://www.bluarctic.xyz/',
    },
    {
      id: '283269e8-3d50-48fd-abcc-7ad48b65e16b',
      tokenAddress: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f',
      fullName: 'RAMP ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/3bc8dc95-70bb-46e7-9243-20268062b607',
      website: 'https://rampdefi.com/',
    },
    {
      id: '6ef12e41-d2f0-4d74-9aa6-aee878f90a3f',
      tokenAddress: '0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b',
      fullName: 'Aurox Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/64d150aa-e521-42b8-ad2f-be3e2c15b345',
      website: 'https://getaurox.com/',
    },
    {
      id: '4855488d-b88a-4844-a4e7-ab95358c330d',
      tokenAddress: '0x72c60bfffef18dca51db32b52b819a951b6ddbed',
      fullName: 'Shido',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/28bb8f83-3c8b-482a-892d-4cc996fc0e63',
      website: 'https://shidoscan.com',
    },
    {
      id: 'f833b796-2482-41f3-af51-a0a7c604fd75',
      tokenAddress: '0x2f109021afe75b949429fe30523ee7c0d5b27207',
      fullName: 'OccamFi ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8d5204f0-283b-4a69-b4a9-bf7e27497174',
      website: 'https://occam.fi/',
    },
    {
      id: 'ae72e6b4-8d82-4446-b720-fac7204d7e6a',
      tokenAddress: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
      fullName: 'Kleros',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/693c1c76-ab7d-45dc-904d-063464ff39c7',
      website: 'https://kleros.io/',
    },
    {
      id: 'ba51a0d4-3e99-4734-ace3-647fb9a7105f',
      tokenAddress: '0x79c7ef95ad32dcd5ecadb231568bb03df7824815',
      fullName: 'Ariva',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ariva.jpeg',
      website: 'https://ariva.digital/',
    },
    {
      id: '45196bd6-ab1b-43fb-91cc-122837eabfa7',
      tokenAddress: '0x6dc6a27822ae2ca3a47da39a2f2bbd525dd693f8',
      fullName: 'Cyberdoge',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8cf378dc-25d1-4649-8f33-ad3c63e07654',
      website: 'https://cyberdoge.io/',
    },
    {
      id: '9a4d111c-b062-4842-8dc6-1df11a16a0e4',
      tokenAddress: '0xcafe001067cdef266afb7eb5a286dcfd277f3de5',
      fullName: 'ParaSwap ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/29c8817b-6f72-4a9d-9d6f-42466aee30cc',
      website: 'https://paraswap.io/',
    },
    {
      id: '6bc7ba53-a834-4dd6-badc-963d6ff65db5',
      tokenAddress: '0xb9edbe853ddccb4baaf49201be6c39ee1816e120',
      fullName: 'Reddit',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4c18a2cf-3b10-4e74-a318-ce195ddae788',
      website: 'https://rddt.io/',
    },
    {
      id: 'ae4b28c3-f190-4438-bfd7-a767a6e7cc8d',
      tokenAddress: '0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2',
      fullName: 'Ternoa ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bfd7d0e0-f3e5-4d6d-9204-e20bb29804b3',
      website: 'https://www.ternoa.com/',
    },
    {
      id: 'e39e5da5-0545-4c9e-b3dd-3ddbd534e21d',
      tokenAddress: '0xaaee1a9723aadb7afa2810263653a34ba2c21c7a',
      fullName: 'MOG Coin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cbf51d4b-d237-4f33-80e7-6b2239d35c40',
      website: 'https://mogcoin.xyz/',
    },
    {
      id: 'babc8ff7-b08b-4404-bf4c-1b8d7a3c73f0',
      tokenAddress: '0x362bc847a3a9637d3af6624eec853618a43ed7d2',
      fullName: 'PARSIQ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/12c8e79e-0fe0-401a-b34e-0c34cb4597ba',
      website: 'https://www.parsiq.net/',
    },
    {
      id: '717d9385-efa5-4a35-a7fd-4d129f5002ed',
      tokenAddress: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
      fullName: 'Stake DAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/257c94c4-c5b2-476e-bcd2-7b3c54ca3e70',
      website: 'https://stakedao.org/',
    },
    {
      id: 'f64e0c67-a145-467e-8fdb-e7a003fbdaab',
      tokenAddress: '0x2ba592f78db6436527729929aaf6c908497cb200',
      fullName: 'Cream',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cream-finance.jpeg',
      website: 'https://cream.finance/',
    },
    {
      id: 'e1f2f424-661e-4142-a0bd-e2ed2064a061',
      tokenAddress: '0xdddddd4301a082e62e84e43f474f044423921918',
      fullName: 'Rhino.fi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/72dda6e7-621e-4b13-9189-b671a24cf832',
      website: 'https://rhino.fi/',
    },
    {
      id: 'f5989799-2000-4de5-b76a-21c570f191c8',
      tokenAddress: '0x6fc13eace26590b80cccab1ba5d51890577d83b2',
      fullName: 'Umbrella Network',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/umbrella.png',
      website: 'https://www.umb.network/',
    },
    {
      id: 'f4bbf20b-ca37-46f0-a54e-cd76843c5815',
      tokenAddress: '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0',
      fullName: 'UniCrypt',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/unicrypt.png',
      website: 'https://www.unicrypt.network/',
    },
    {
      id: '6bf6adcf-e2e7-4bce-859f-4971a878fce6',
      tokenAddress: '0x783c68814126b66b9242c4c6538ae47db5e33169',
      fullName: 'RODO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1d992509-c849-4e5e-bdbd-eb7b5691f380',
      website: 'https://rodotoken.com/',
    },
    {
      id: '9eeb49e2-2e29-46a3-ba26-e26e22c3af50',
      tokenAddress: '0x64df3aab3b21cc275bb76c4a581cf8b726478ee0',
      fullName: 'Cramer',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/453bf6eb-b9d3-4578-ab9e-d8e50e101bed',
      website: 'https://cramercoin.com/',
    },
    {
      id: '64d87c64-a669-4a08-bee3-fbcb69a58375',
      tokenAddress: '0x0ec9f76202a7061eb9b3a7d6b59d36215a7e37da',
      fullName: 'BlackPool Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a7e7d818-f15f-4b5f-8b2a-3b20ae406bea',
      website: 'https://www.blackpool.finance/',
    },
    {
      id: '01a8d725-5ece-4c5f-82b5-d41a36ba6eee',
      tokenAddress: '0xe61f6e39711cec14f8d6c637c2f4568baa9ff7ee',
      fullName: 'Hey',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0a9c1a21-a804-4e0c-9afc-9be1878d3dd7',
      website: 'https://heytoken.pro',
    },
    {
      id: '03b5b30b-d89f-4ba9-9bfc-a2e993bfe4e3',
      tokenAddress: '0x70401dfd142a16dc7031c56e862fc88cb9537ce0',
      fullName: 'Bird Money',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bird.jpeg',
      website: 'https://www.bird.money/',
    },
    {
      id: '3ca756dc-f44a-458b-987d-571985e3974d',
      tokenAddress: '0x2f4eb47a1b1f4488c71fc10e39a4aa56af33dd49',
      fullName: 'UNCL',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/uncl.png',
      website: 'https://www.unicrypt.network/',
    },
    {
      id: 'da5bb7f4-be04-471f-8f90-62645660e4c0',
      tokenAddress: '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2',
      fullName: 'Plasma Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/97d6dcf1-63d9-45a2-b5f1-f2057c5cf53b',
      website: 'https://plasma.finance/',
    },
    {
      id: '7c98badb-5d75-40d4-91ea-556c2f3770d1',
      tokenAddress: '0x36919a60a2b67b6d2329863093d180d23d5a0308',
      fullName: 'Kusunoki Samurai',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0210d916-bd77-452a-a823-4d67e75d0e6a',
      website: 'https://www.kusunokisamurai.com/',
    },
    {
      id: '29a4bc50-ce40-4bfe-a136-67974d325cdd',
      tokenAddress: '0x47c1178f49140ecdbfbdf0ae2935cdb640d579f9',
      fullName: 'Phantasma Energy',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a8e3ab69-22e1-4325-87be-cefa6f10d9d3',
      website: 'https://phantasma.io/',
    },
    {
      id: '5f485a6e-e48c-4eea-b90a-4f5b64bcca75',
      tokenAddress: '0x77132bb08b02a7a87732ec289efab45edf49884f',
      fullName: 'MasRelic ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e39e06ae-9bf5-49d5-ba9b-9fae00e23405',
      website: 'https://www.masrelic.com',
    },
    {
      id: 'c8527d59-e814-40c4-a3f6-8e186d355c70',
      tokenAddress: '0x9319820ad5447180d0cbb76c1c06c870562aef93',
      fullName: 'Oceidon ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/aa5e72cc-db9a-4144-965f-c6606ea4036e',
      website: 'https://oceidon.com/',
    },
    {
      id: '67ed734a-daf0-4d7a-8d43-078d35363722',
      tokenAddress: '0x59d1e836f7b7210a978b25a855085cc46fd090b5',
      fullName: 'AssangeDAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e5a2f1e2-0b5c-4114-8bf8-91c99b4d4b45',
      website: 'https://assangedao.org/',
    },
    {
      id: 'cbd19e6b-aa67-4eca-a962-6f182a4c28e7',
      tokenAddress: '0x375ea781c49eafedde07afe6196f885761f166ae',
      fullName: 'CryptoTrunks',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f9a5694c-a00f-49b2-af3a-d5612eae2c26',
      website: 'https://wallet.garden',
    },
    {
      id: 'c90bee2a-4ab8-4408-a047-72430a0f0709',
      tokenAddress: '0xa973a9ba553bf1f0f228c676047d77c62fd0af72',
      fullName: 'JokerClub',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4837f920-dd41-47de-bd0a-3bb0d1bf3d3c',
      website: 'https://www.jokerclub.io/',
    },
    {
      id: '4965ecf0-84d3-4b69-8000-677cc4ef02b6',
      tokenAddress: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
      fullName: 'BAYC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4917dee3-ac1b-48c0-9dfd-52a44a7b8b2b',
      website: 'https://boredapeyachtclub.com/',
    },
    {
      id: '6a510d15-1ecb-4321-827b-d64047f0eead',
      tokenAddress: '0x183015a9ba6ff60230fdeadc3f43b3d788b13e21',
      fullName: 'Raft',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c0a78815-19a3-4ff0-a083-c6d49e1972d5',
      website: 'https://www.raft.fi/',
    },
    {
      id: '9684455b-1af6-4add-b2d7-8c50d28eb03c',
      tokenAddress: '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7',
      fullName: 'Meebits',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c25163b8-2198-4de6-a1d0-e32e2f802876',
      website: 'https://meebits.app/',
    },
    {
      id: '65c134b9-c304-4daf-ac1f-4cc212772202',
      tokenAddress: '0x892848074ddea461a15f337250da3ce55580ca85',
      fullName: 'CuberBrokers',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/497546a7-913e-4cc3-98e6-9ae58e8ced0e',
      website: 'https://cyberbrokers.io/',
    },
    {
      id: '68576fae-f576-4266-9191-b4b58c666814',
      tokenAddress: '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
      fullName: 'MAYC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a4fd528e-fd77-4ce6-87f1-11a5498107c7',
      website: 'https://boredapeyachtclub.com/#/mayc',
    },
    {
      id: 'ec0304cb-0e0a-4278-93cc-0e7a59a09542',
      tokenAddress: '0xa6a5ec7b1b8a34ff2dcb2926b7c78f52a5ce3b90',
      fullName: 'MGXS',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/5034e28b-3361-4dd4-8cce-5089fda9fd4e',
      website: 'https://mgxs.co/',
    },
    {
      id: '0e47207c-7752-4ee7-94aa-1e811d72c2cf',
      tokenAddress: '0x8d609bd201beaea7dccbfbd9c22851e23da68691',
      fullName: 'Dour Darcles',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4bb26a27-5d21-4b40-9fbe-11b7003bfe01',
      website: 'https://dourdarcels.io/',
    },
    {
      id: 'b3792fff-4e91-4b41-8e3f-59192e8a9f1d',
      tokenAddress: '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b',
      fullName: 'CloneX',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6220b39d-f30c-42af-a76f-bb6e24c5dc3a',
      website: 'http://www.rtfkt.com/',
    },
    {
      id: 'b606dc6b-58b4-4a47-b805-f2b024db107e',
      tokenAddress: '0xee2b297408063e0967096bafdcfd1278d5bf1b8a',
      fullName: 'DOSA',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8bd38ce0-d4e9-4bce-9617-2d010315f84e',
      website: 'https://www.dosa.finance/',
    },
    {
      id: '255c65e4-2bca-4eb4-8f6a-a9008232823a',
      tokenAddress: '0xac2493f4b85fd48835ba9e44058aaec6bb2aea59',
      fullName: 'Pulse DAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6a0aa9e3-3d10-481d-a679-17786b7a9abb',
      website: 'https://pulsedaoeth.com/',
    },
    {
      id: 'f817d9ca-1470-4cec-bf70-1bf96a1ae54f',
      tokenAddress: '0x2ad9addd0d97ec3cdba27f92bf6077893b76ab0b',
      fullName: 'Planet',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2bd31dd6-850e-4772-b77e-d6af1d9cace7',
      website: 'https://joinourplanet.com/',
    },
    {
      id: '7dd478b4-6afc-41aa-9d7d-7788d685f46f',
      tokenAddress: '0xb69753c06bb5c366be51e73bfc0cc2e3dc07e371',
      fullName: 'Pooh',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bc39f89a-7078-41b5-84a3-4596f57ebbc2',
      website: 'https://pooh.money/',
    },
    {
      id: '5cc1eac2-1d58-4c6d-be2d-8782f75fd8f4',
      tokenAddress: '0xed1273928ba97eed7b49e82c2f39d512d7591112',
      fullName: 'Nerd Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4cfe2b37-47da-4aa2-b5d3-a2f1e5f1566d',
      website: 'https://www.thedatanerd.io',
    },
    {
      id: '7049a6b7-257f-40b3-839b-8da756061d66',
      tokenAddress: '0x922e2708462c7a3d014d8344f7c4d92b27ecf332',
      fullName: 'Neuroni AI',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c89d1293-8608-47a2-ad11-2ecf5fe70d79',
      website: 'https://neuroni.ai/',
    },
    {
      id: '786086fa-0b71-4fd3-b8b0-9d8a85093cf6',
      tokenAddress: '0x7e3ef31186d1bec0d3f35ad701d065743b84c790',
      fullName: 'Antonym',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8464db89-1477-40cf-b607-926402ac4065',
      website: 'https://www.antonymnft.com/',
    },
    {
      id: '1caca5e3-b9c1-4a3d-845a-e6c01a6b3f0e',
      tokenAddress: '0x092bbc993042a69811d23feb0e64e3bfa0920c6a',
      fullName: 'Gutter Juice',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/9890911f-32c3-4ef1-989e-3557569280e0',
      website: 'https://guttercatgang.com/',
    },
    {
      id: '14a7fbed-1b8c-49f1-a054-495e95fe546b',
      tokenAddress: '0xa3ba969dd831d4eff2baf930e7f66d2eef5d929c',
      fullName: 'Shibagun',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/9107d87b-748f-4c28-9a15-62a479d80a94',
      website: 'https://shibagun.com/',
    },
    {
      id: '5027bc58-8685-44f4-b95d-d90a3dadb96d',
      tokenAddress: '0xfbbe098ee65238e4d9f771404edddcbf89cd689b',
      fullName: 'Treemeister ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d894c1ca-9db1-4d49-9a1b-70c0483793ea',
      website: 'https://www.treemeistereth.com/',
    },
    {
      id: '9ebb3934-e396-443a-bcfd-e85350a3b7d4',
      tokenAddress: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
      fullName: 'Doodles',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/5979edd1-608d-4d6e-8fea-a8e50ca5ada7',
      website: 'https://doodles.app/',
    },
    {
      id: 'cf5c0e61-e2dd-4b5e-b751-729bd8776536',
      tokenAddress: '0xed5af388653567af2f388e6224dc7c4b3241c544',
      fullName: 'Azuki',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/20b742cb-9666-4174-bae2-a339c7582790',
      website: 'http://www.azuki.com/',
    },
    {
      id: 'b44ba97f-beee-4c13-849d-01de93f2887d',
      tokenAddress: '0x23dc3f6859e122b25b2cd5f3cf2335310b0f2b77',
      fullName: 'DeSME',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4c7318ac-2370-47c8-b962-4faf044c7d78',
      website: 'https://www.desme.io/',
    },
    {
      id: 'b3e2fa86-8b48-4b4e-be11-07cc58c3dbe3',
      tokenAddress: '0x709d30f1f60f03d85a0ef33142ef3259392dc9e1',
      fullName: 'Loser Club',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/557a2086-29fd-483b-9c07-509ac42651c5',
      website: 'https://loserclub.io/',
    },
    {
      id: '0c8a501f-8036-43fb-99fc-de8f962e04b8',
      tokenAddress: '0x3f93d710ff8c449ea300ad69412e7b8f289a2954',
      fullName: 'ILYYW',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/be9478e7-8822-4eca-a822-e9cbce4e5fae',
      website: 'https://www.ilikeyouyoureweird.com/',
    },
    {
      id: '52567b4b-9604-4b77-97ba-68d7db6702c6',
      tokenAddress: '0x901efcf603b7e9874bcbe626b62e7d4aca794df0',
      fullName: 'X-Chain ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/b384a321-0f65-4f88-aeb3-433b6932b9fa',
      website: 'https://xchain.finance/',
    },
    {
      id: 'b467d737-cea8-4794-904d-e6caea675108',
      tokenAddress: '0x7cfd61c04b3de52b0e5aa0abcbca9502b652ff2c',
      fullName: 'Quack Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/36debefe-a2d2-49f3-ba81-a315753487fe',
      website: 'https://quacktoken.com',
    },
    {
      id: '77c51a62-451e-47b5-8029-0b2e2780d50b',
      tokenAddress: '0xbd4455da5929d5639ee098abfaa3241e9ae111af',
      fullName: 'NFT Worlds',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c4d54e60-5d80-4667-ae76-3c95890334fe',
      website: 'https://www.nftworlds.com/',
    },
    {
      id: '4ed0f720-e36e-46c3-8620-cb32e44dad99',
      tokenAddress: '0x3903d4ffaaa700b62578a66e7a67ba4cb67787f9',
      fullName: 'Quirkies',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/71e4d505-1875-4be5-9c40-02e29f540cce',
      website: 'https://quirkies.io/',
    },
    {
      id: '5862afcc-87df-4a60-9636-0bff45a026e7',
      tokenAddress: '0x39ea10e507720783c27edd5f96bf2d6e199579b8',
      fullName: 'DeltaFlare',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e00d8ab3-6c6a-4f12-b3e9-c409b26d6ce1',
      website: 'https://deltaflare.gg/',
    },
    {
      id: '61f92a58-3a89-4d45-990f-cc2b36c2a019',
      tokenAddress: '0xa5c0bd78d1667c13bfb403e2a3336871396713c5',
      fullName: 'CoolmansUniverse',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c41de884-5211-4625-b3a2-c532a01e7807',
      website: 'https://coolmansuniverse.com/',
    },
    {
      id: '37950918-719e-41b7-af6d-04946ff107e0',
      tokenAddress: '0x7adc29cec54b124a7e8e1b1415dff95ddd13b173',
      fullName: 'MetaToolsERC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a24ceebb-f42f-45b4-959e-948857bfb97b',
      website: 'https://www.metatools.tech/',
    },
    {
      id: '4a87efb8-1015-49e8-9b8d-ed035b95be50',
      tokenAddress: '0x59468516a8259058bad1ca5f8f4bff190d30e066',
      fullName: 'Invisible Friends',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a728aa5f-b55e-41ff-9193-ad7da1c1ef6e',
      website: 'https://coolmansuniverse.com/',
    },
    {
      id: 'a70c0776-6102-4e5c-96a4-72a4d454739a',
      tokenAddress: '0xb4d06d46a8285f4ec79fd294f78a881799d8ced9',
      fullName: '3Landers',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/711c56fe-2902-4f8f-bb18-b2f43f144552',
      website: 'https://3landersnft.com/',
    },
    {
      id: '381bbdf8-6b61-4a92-8b7e-6ed7a0ed7a21',
      tokenAddress: '0x93e0f5a3afebdf40637b509ceae3661da0de92f5',
      fullName: 'Wine Bottle Club',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/7b66a7d3-6712-4569-8ccc-843454c42856',
      website: 'https://www.winebottleclub.com/',
    },
    {
      id: '229a6b7b-ccf4-4487-9dd2-bf7680fc693e',
      tokenAddress: '0xdb9d073439e5064312c60de43083071835d64998',
      fullName: 'GORJS ACCESS PASS',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/3330453b-f1f6-4685-b4be-3e426ceec975',
      website: 'https://www.gorjs.io/',
    },
    {
      id: '478f9a42-0f53-47b5-a0ae-4a60c6402bc7',
      tokenAddress: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
      fullName: 'Moonbirds',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/365a3390-bec8-4e4d-88f8-9bf3317100cf',
      website: 'https://moonbirds.xyz/',
    },
    {
      id: 'a17c4fa5-18f0-41f4-bcfa-07e243699222',
      tokenAddress: '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258',
      fullName: 'Otherdeed',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e0e8d76b-66bc-42ab-bd16-c7b37346bfbe',
      website: 'https://otherside.xyz/',
    },
    {
      id: 'e2cc6b55-86b4-4a20-8e9b-76fe283b50f6',
      tokenAddress: '0xba07cd4712a308be5f117292a07beff94a7fe0cf',
      fullName: 'Squiggles',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/660d8e87-a78c-43ce-b56d-87ec942aee90',
      website: 'https://etherscan.io/token/0xba07cd4712a308be5f117292a07beff94a7fe0cf',
    },
    {
      id: '37c724ed-1f56-4f94-92dc-69dcecaceb5c',
      tokenAddress: '0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0',
      fullName: '4thpillar Technologies',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/07ee0b9c-0d65-4bfd-8129-8383e42e8d2a',
      website: 'https://the4thpillar.io/',
    },
    {
      id: 'fcda7424-6213-47d5-8613-4bbc6d297c5a',
      tokenAddress: '0x10f6f2b97f3ab29583d9d38babf2994df7220c21',
      fullName: 'DRAC Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/1f9f54f4-4c53-488d-9a89-3eb197681522',
      website: 'https://teddydoge.finance/',
    },
  ],
  popularTokenIds: [
    '810bf855-46cb-4f93-9715-246dc5f52557',
    '8cd10c9b-4d70-4d4a-a2e0-0ff9e1e365fd',
    'c5020f5a-491f-4b47-8671-ce10b4045b0a',
    'b2bc2ac0-76a9-4385-8fd2-29ae95253fbb',
  ],
  mostVisitedTokenIds: [
    '810bf855-46cb-4f93-9715-246dc5f52557',
    '4965ecf0-84d3-4b69-8000-677cc4ef02b6',
    '478f9a42-0f53-47b5-a0ae-4a60c6402bc7',
    '7053ed20-0e26-45a8-83c9-ba6f347dab59',
    'cf5c0e61-e2dd-4b5e-b751-729bd8776536',
  ],
  recentlyListedTokenIds: [
    '0edab5c5-3188-4706-b7e2-80320429ace4',
    'bc8fb3a4-eb53-444c-a5b5-084d6d0f5a1c',
    '71bc70b5-3817-4899-9f85-0b23a74d5ce0',
    'c16c4e88-2a1c-41aa-bf7a-f510d55efae3',
    '6bc7ba53-a834-4dd6-badc-963d6ff65db5',
  ],
  trendingTokenIds: [
    '810bf855-46cb-4f93-9715-246dc5f52557',
    '8cd10c9b-4d70-4d4a-a2e0-0ff9e1e365fd',
    'c5020f5a-491f-4b47-8671-ce10b4045b0a',
  ],
};

export const solTokens = {
  tokens: [
    {
      id: 'a19c7d31-bd4d-481c-9890-4e6c51fea5a8',
      tokenAddress: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
      fullName: 'Binance-Peg Dogecoin Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4f0e25c6-c9d2-4681-8d82-82571839bcb1',
      website: 'https://dogecoin.com/',
    },
    {
      id: 'fdbf4f73-7283-4578-bfb1-0c9bbcda8701',
      tokenAddress: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
      fullName: 'Binance-Peg Cardano',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/52fbe14e-678f-4187-a3b0-b66f385f0b11',
      website: 'https://cardano.org/',
    },
    {
      id: '9125d9d9-e9de-4f3d-bb54-b139c826f245',
      tokenAddress: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
      fullName: 'Binance-Peg Uniswap',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/dbd05713-2be1-4464-8f4c-3493e126ca11',
      website: 'https://uniswap.org/',
    },
    {
      id: '9887ac1d-21b1-43bb-ae60-53e9aecfd06c',
      tokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      fullName: 'PancakeSwap',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/466485f2-45c0-4316-baf2-2e4a9100aa9f',
      website: 'https://pancakeswap.finance/',
    },
    {
      id: 'a7fb912f-399b-4f90-b0a5-f610185be52c',
      tokenAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
      fullName: 'ApeSwap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/apeswap.png',
      website: 'https://apeswap.finance/',
    },
    {
      id: '6eea4604-9b8e-4bad-a23b-4bf897120ea8',
      tokenAddress: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
      fullName: 'SafeMoon v1',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/safemoon-v1.jpeg',
      website: 'https://safemoon.net/',
    },
    {
      id: '7680aad7-6131-48de-afac-ad2f0eb822e2',
      tokenAddress: '0x42981d0bfbaf196529376ee702f2a9eb9092fcb5',
      fullName: 'SafeMoon v2',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/safemoon-v2.jpeg',
      website: 'https://swap.safemoon.net/#/swap',
    },
    {
      id: '61a21dab-1e0c-4ebf-b551-d55f93c6d279',
      tokenAddress: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
      fullName: 'Shiba Inu ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/57a7021a-7f46-418d-82ab-a7ecaa0d3d8b',
      website: 'https://www.shibatoken.com/',
    },
    {
      id: 'e86aaa3a-912e-4ab3-9d2e-14b0fc68a8b8',
      tokenAddress: '0xe283d0e3b8c102badf5e8166b73e02d96d92f688',
      fullName: 'Elephant Money',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4f714f26-aff7-4513-9e31-43bb3162bb96',
      website: 'https://elephant.money/',
    },
    {
      id: 'f84da913-4c6a-444a-9a7a-f15d4b2033fc',
      tokenAddress: '0x02ff5065692783374947393723dba9599e59f591',
      fullName: 'YooShi',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/yooshi.jpeg',
      website: 'https://yooshi.io/index',
    },
    {
      id: '3d981d88-9f05-4fe2-993c-c9e9a8b3e1d8',
      tokenAddress: '0x8c851d1a123ff703bd1f9dabe631b69902df5f97',
      fullName: 'BinaryX',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/binaryx.jpeg',
      website: 'https://game.binaryx.pro/',
    },
    {
      id: '7bac255f-f4b7-4053-bff9-5ca355ae2c57',
      tokenAddress: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
      fullName: 'Alpaca Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/alpaca.jpeg',
      website: 'https://www.alpacafinance.org/',
    },
    {
      id: '9f2430e0-5b3d-4963-99d0-2bb8642b8659',
      tokenAddress: '0xc1a59a17f87ba6651eb8e8f707db7672647c45bd',
      fullName: 'Lunar DAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/73c0c78d-054c-4510-adde-c4aee8a15cfc',
      website: 'https://lunar.io/',
    },
    {
      id: '0f52406d-db25-4db2-a473-c42436f9cf90',
      tokenAddress: '0x83a86adf1a7c56e77d36d585b808052e0a2aad0e',
      fullName: 'SaveYourAssets',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a7b4b906-cef3-4d32-b0eb-6077fe9781b8',
      website: 'https://radar.sya.today/',
    },
    {
      id: '8f1aa3d4-917c-4be2-9535-566e9e0d4b25',
      tokenAddress: '0x8ddeec6b677c7c552c9f3563b99e4ff90b862ebc',
      fullName: 'Block Ape Scissors',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e927ca33-2573-4911-af34-722b4e0c86c8',
      website: 'https://www.blockapescissors.com/',
    },
    {
      id: '2645c45b-055c-4acc-913e-420dfa3f5bc9',
      tokenAddress: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
      fullName: 'Mobox',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/40230a04-12b4-4db0-9ca6-9c7993e713df',
      website: 'https://www.mobox.io/',
    },
    {
      id: 'fbb76cbb-1b08-49fe-bef4-7e58c01b49a8',
      tokenAddress: '0xc748673057861a797275cd8a068abb95a902e8de',
      fullName: 'Baby Doge Coin',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/babydoge.png',
      website: 'https://babydogecoin.com/',
    },
    {
      id: 'c73f40b6-7fc9-430d-89a7-d4f09dfec5a8',
      tokenAddress: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
      fullName: 'Venus',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/eadd8d8a-e16e-4b85-ac0b-caf3a05d54ab',
      website: 'http://venus.io/',
    },
    {
      id: 'd1721442-987c-4b9e-a182-940a7d46ed21',
      tokenAddress: '0xa7f552078dcc247c2684336020c03648500c6d9f',
      fullName: 'Ellipsis',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ellipsis.png',
      website: 'https://ellipsis.finance/',
    },
    {
      id: '0292462c-07b8-4c26-be6f-2f7bcfedf372',
      tokenAddress: '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
      fullName: 'SafePal',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6c4f614d-ad94-446d-9ba0-3a5433eddf3c',
      website: 'https://www.safepal.io/',
    },
    {
      id: '20c930db-59d0-4308-a036-87d6b231aa39',
      tokenAddress: '0x12bb890508c125661e03b09ec06e404bc9289040',
      fullName: 'Radio Caca',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/radiocaca.png',
      website: 'https://www.radiocaca.com/home#/home',
    },
    {
      id: 'b63c40b2-a1af-4393-96d1-3a8735026685',
      tokenAddress: '0x695adae22c14c3a166afeb46f15ab7262c5d5bc2',
      fullName: 'XQUOK',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/23f04d90-5530-4ffb-b563-ec828ca04ac4',
      website: 'https://xquok.com/',
    },
    {
      id: 'b92a96b0-c056-4717-9a1d-afaac22c33f3',
      tokenAddress: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
      fullName: 'Coin98',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/coin98.png',
      website: 'https://www.coin98.com/',
    },
    {
      id: 'b73554b1-3f6b-4379-b93c-d535ad788c4a',
      tokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',
      fullName: 'KnightSwap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/knight.png',
      website: 'https://knightswap.financial/',
    },
    {
      id: '9a30dd42-2321-4af4-b2a9-6d71883257b4',
      tokenAddress: '0x08ba0619b1e7a582e0bce5bbe9843322c954c340',
      fullName: 'Binamon',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/binamon.jpeg',
      website: 'https://binamon.org/',
    },
    {
      id: '7d721731-b743-41f0-b749-e80a56f5e16e',
      tokenAddress: '0x3ad9594151886ce8538c1ff615efa2385a8c3a88',
      fullName: 'SafeMars',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/safemars.jpeg',
      website: 'https://www.safemarscrypto.com',
    },
    {
      id: 'a237b913-f9e9-4cc3-8199-a428c757292a',
      tokenAddress: '0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f',
      fullName: 'MagicCraft',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/d929ed52-28ff-4145-8ab3-66070b443480',
      website: 'https://magiccraft.io/',
    },
    {
      id: '210ef852-5d71-4a09-b47d-b8030ccabe8c',
      tokenAddress: '0xeb37d7b4c0b2a1f6bc826fa32b284b8d1796354c',
      fullName: 'Kreepy Kritters',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/kreepy-kritters-200.gif',
      website: 'https://twitter.com/KreepyKritters',
    },
    {
      id: '074eafed-3fe4-4460-8d1f-44ce146b53a0',
      tokenAddress: '0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50',
      fullName: 'Pitbull',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bf6d2008-8326-441d-b053-8ae7ca6287ea',
      website: 'https://www.pitbull.community/',
    },
    {
      id: 'a8e69bd1-f663-4c12-9b28-5fe4df7b8026',
      tokenAddress: '0xfb5b838b6cfeedc2873ab27866079ac55363d37e',
      fullName: 'FLOKI ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0301880f-487a-4d07-89dd-b0706bfd4cce',
      website: 'https://floki.com/',
    },
    {
      id: 'cd4c88f7-c771-4827-9bf0-15e02a814fa0',
      tokenAddress: '0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936',
      fullName: 'CatGirl',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/catgirl.png',
      website: 'https://www.catgirl.io/',
    },
    {
      id: 'a6690661-7828-4717-a450-d2f133dd090a',
      tokenAddress: '0xa77346760341460b42c230ca6d21d4c8e743fa9c',
      fullName: 'MicroPets',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/micropets.jpeg',
      website: 'https://pets.micropets.io/',
    },
    {
      id: 'a1cc02a2-4ca9-44cd-9e33-e394add8e081',
      tokenAddress: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
      fullName: 'Reef Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/reef.png',
      website: 'https://reef.finance/',
    },
    {
      id: 'e08d9967-6b7d-491b-9de9-9479dbd43549',
      tokenAddress: '0x9fd87aefe02441b123c3c32466cd9db4c578618f',
      fullName: 'Thetan Gem',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/thethan-gem.png',
      website: 'https://www.thetanarena.com/#theta-gem',
    },
    {
      id: '7f9f4cb9-8c53-4647-8b7e-a0a186040f75',
      tokenAddress: '0x55671114d774ee99d653d6c12460c780a67f1d18',
      fullName: 'Pacoca',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/pacoca.jpeg',
      website: 'https://pacoca.io/',
    },
    {
      id: '3855f16d-5cf9-4449-ae30-6e5f2eb151b0',
      tokenAddress: '0x9029fdfae9a03135846381c7ce16595c3554e10a',
      fullName: 'OpenOcean',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/open-ocean.jpeg',
      website: 'https://openocean.finance/',
    },
    {
      id: '54455849-7d2b-4453-a966-14419e2a5269',
      tokenAddress: '0x8ab7ef0eb25aad36dff0661f81fb81b144af5b87',
      fullName: 'Kumamon',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/67181d2c-d602-46fe-8021-0c6dfea217d3',
      website: 'https://kumamon.finance',
    },
    {
      id: '26b3ec89-420f-4a9b-8140-3447ed2ad8a5',
      tokenAddress: '0x3910db0600ea925f63c36ddb1351ab6e2c6eb102',
      fullName: 'Spartan Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/spartanprotocol.jpeg',
      website: 'https://spartanprotocol.org/',
    },
    {
      id: '2d6c6e25-52a6-4bcf-aca4-d40dbda7d782',
      tokenAddress: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
      fullName: 'STARS',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/056d461b-96ac-48d6-b506-fd4d984fd3c0',
      website: 'https://www.mogulproductions.com/',
    },
    {
      id: '0e07efab-ab23-4ca6-a97f-5f2554b988e8',
      tokenAddress: '0xd2359c576632234d1354b20bf51b0277be20c81e',
      fullName: 'GameGuru',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e1aba932-28c6-47c0-9e70-8656bd3a352e',
      website: 'https://gameguru.gg/',
    },
    {
      id: '47596539-8390-4f18-b7fb-15cd70f686d3',
      tokenAddress: '0x0808bf94d57c905f1236212654268ef82e1e594e',
      fullName: 'Ritestream',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6647a77e-12f6-46a5-9944-8012016b4643',
      website: 'https://www.ritestream.io/',
    },
    {
      id: '49874b63-0d10-4f0a-816d-ffead9b8fd4c',
      tokenAddress: '0xe02df9e3e622debdd69fb838bb799e3f168902c5',
      fullName: 'BakerySwap',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/13e83709-99b1-40a7-a67c-2d22136e1ccd',
      website: 'https://bakeryswap.org/',
    },
    {
      id: '45e1575a-4bb1-4e7a-9e31-1707921ba846',
      tokenAddress: '0x6f13b1fb6b2897bb40adbc09f7f6cfad181c0904',
      fullName: 'GEURO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/71f76ada-fde7-4676-973b-c5dc8c0d3057',
      website: 'https://gameguru.gg',
    },
    {
      id: '14e7abd6-4c17-40d0-ad53-9f88f0ca917b',
      tokenAddress: '0xc17c30e98541188614df99239cabd40280810ca3',
      fullName: 'EverRise',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/everrise.png',
      website: 'https://www.everrisecoin.com/',
    },
    {
      id: '53185092-3694-4056-a8b5-efbb8a665fc2',
      tokenAddress: '0x24ce3d571fbcfd9d81dc0e1a560504636a4d046d',
      fullName: 'Petsverse',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/26d1c659-57a0-49ee-b452-6106831434f6',
      website: 'https://petsverse.games/',
    },
    {
      id: 'd7c8ad3d-a99e-4639-8c99-5436f859d768',
      tokenAddress: '0x2a9718deff471f3bb91fa0eceab14154f150a385',
      fullName: 'ElonGate',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/elongate.png',
      website: 'https://www.elongate.cc/',
    },
    {
      id: '329bd427-04a9-4af8-a522-a9080ced0bc5',
      tokenAddress: '0x78a499a998bdd5a84cf8b5abe49100d82de12f1c',
      fullName: 'JOJO',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/jojo.jpeg',
      website: 'https://jojo.fun/',
    },
    {
      id: '5f6acf92-dcae-4a79-9bf7-77f6fa402c50',
      tokenAddress: '0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506',
      fullName: 'Unicrypt',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/unicrypt.png',
      website: 'https://www.unicrypt.network',
    },
    {
      id: 'a306fc3b-05ed-4567-a71c-0dac24447f8e',
      tokenAddress: '0x4b0f1812e5df2a09796481ff14017e6005508003',
      fullName: 'Trust Wallet',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/242f2154-620a-476b-8ca2-11296520d639',
      website: 'https://trustwallet.com/blog',
    },
    {
      id: '6cdb21ae-f2d5-4ef2-9693-d8e54929ed9a',
      tokenAddress: '0xe56842ed550ff2794f010738554db45e60730371',
      fullName: 'Binemon',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/binemon.jpeg',
      website: 'https://binemon.io/',
    },
    {
      id: '25993c84-02c4-4839-8ccd-2195baa4e8a1',
      tokenAddress: '0x8888888888f004100c0353d657be6300587a6ccd',
      fullName: 'AcryptoS',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/acryptos.png',
      website: 'https://app.acryptos.com/',
    },
    {
      id: 'ae9dfadd-dd25-4292-a4d2-2177c373653c',
      tokenAddress: '0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a',
      fullName: 'Jetfuel Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/jetfuel.jpeg',
      website: 'https://jetfuel.finance',
    },
    {
      id: '00efa350-403d-4830-b485-c15a093742f3',
      tokenAddress: '0xbb46693ebbea1ac2070e59b4d043b47e2e095f86',
      fullName: 'BetFury',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/betfury-2.png',
      website: 'https://betfury.io/',
    },
    {
      id: 'b95fb2d2-766c-4db3-9e76-331b614e33de',
      tokenAddress: '0xb09fe1613fe03e7361319d2a43edc17422f36b09',
      fullName: 'Bogged Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bogged.png',
      website: 'https://www.bogged.finance/',
    },
    {
      id: '1b52431d-c143-43cd-8454-adc1a363719a',
      tokenAddress: '0x0cbd6fadcf8096cc9a43d90b45f65826102e3ece',
      fullName: 'CheckDot Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c3a0de1e-df5a-4a8b-8f87-dae1a2b403dc',
      website: 'https://checkdot.io/',
    },
    {
      id: '2a0f9af0-af38-4ad7-8efe-fe794dab1c8b',
      tokenAddress: '0xaa9e582e5751d703f85912903bacaddfed26484c',
      fullName: 'Hacken HAI',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cb443928-3214-4f1a-b0c2-d9347d09bbd8',
      website: 'https://hacken.io/',
    },
    {
      id: '7763530b-9aaa-471d-8971-95aa35a6efb1',
      tokenAddress: '0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f',
      fullName: 'Anime',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/72e0ecb5-c1b5-46c1-a020-70b11bd0613f',
      website: 'https://animetoken.in/',
    },
    {
      id: '0dcc69e2-473d-478d-b7c1-4554002ba07d',
      tokenAddress: '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab',
      fullName: 'CryptoBlades Skill Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cryptoblades-skill.png',
      website: 'http://cryptoblades.io/',
    },
    {
      id: '68dbf902-6074-48a7-b1e9-046aba7cf5ec',
      tokenAddress: '0x6679eb24f59dfe111864aec72b443d1da666b360',
      fullName: 'Ariva',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/ariva.jpeg',
      website: 'https://ariva.digital/',
    },
    {
      id: 'f70852fb-53e9-47f2-b138-dadb8fa65eb5',
      tokenAddress: '0xc864019047b864b6ab609a968ae2725dfaee808a',
      fullName: 'Biconomy',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/biconomy.png',
      website: 'https://www.biconomy.com/',
    },
    {
      id: '20a85d4f-9fe8-47cd-9333-4f2bfcaed0f3',
      tokenAddress: '0x9fd83625b3a70f95557a117dbbfb67a0d3406d3e',
      fullName: 'Truth Pay',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/15c9bd65-1e75-4039-8815-285a9bebae5d',
      website: 'https://truthpay.app/',
    },
    {
      id: 'a54fe0b3-13b2-461d-96e1-4ef62ff05800',
      tokenAddress: '0x5662ac531a2737c3db8901e982b43327a2fde2ae',
      fullName: 'Cairo Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/b3bff67b-2d94-4810-bae9-3f4eb70ec958',
      website: 'https://cairofinance.app/',
    },
    {
      id: '1681c2f5-0a0b-4c38-90eb-2d8d617074c8',
      tokenAddress: '0x19c018e13cff682e729cc7b5fb68c8a641bf98a4',
      fullName: 'Burnedfi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6aad019f-d590-470c-be0e-ba65f3226bf9',
      website: 'https://burnedfi.app/',
    },
    {
      id: '2ed0cef2-78c9-4f80-be1c-c556432dd6de',
      tokenAddress: '0xc0041ef357b183448b235a8ea73ce4e4ec8c265f',
      fullName: 'Cookie',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/3f24ea15-25e0-4df5-8df0-2773d0fd54f4',
      website: 'https://www.cookie3.com/',
    },
    {
      id: '59447328-c4b7-42ab-bbce-e534dfb5d9ab',
      tokenAddress: '0x5941f87eb62737ec5ebbecab3e373c40fe40566b',
      fullName: 'Moon Nation Game',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/moon-nation.jpeg',
      website: 'https://moonnation.org/',
    },
    {
      id: '23cafb2e-475a-43bc-97e8-bf1a69cba9cb',
      tokenAddress: '0xb84cbbf09b3ed388a45cd875ebba41a20365e6e7',
      fullName: 'BitShiba',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bitshiba.png',
      website: 'https://bitshiba.io/',
    },
    {
      id: 'aa06973f-e90d-41d1-95de-ca4ed5b5dc00',
      tokenAddress: '0x3b78458981eb7260d1f781cb8be2caac7027dbe2',
      fullName: 'LaunchZone',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/launchzone.jpeg',
      website: 'https://lz.finance/',
    },
    {
      id: '8b876773-68e6-4ddb-a5a7-0d6937f1e2cf',
      tokenAddress: '0x0bcefb75933b2bce5f01515f6250b5c0d66cb7fb',
      fullName: 'REV-Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/85202135-7db6-4042-b435-005f65974030',
      website: 'https://revtoken.finance/',
    },
    {
      id: '56ac2198-5ed0-4842-a84f-b1b8720edba7',
      tokenAddress: '0xa184088a740c695e156f91f5cc086a06bb78b827',
      fullName: 'Autofarm',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6ce0f4f5-0a6e-44f4-9b88-3f2c642d3805',
      website: 'https://autofarm.network/',
    },
    {
      id: '98c4ee85-0aad-475c-8379-9eac91b62d91',
      tokenAddress: '0x86fac768241b4133d131edccbd47f143a4fa9d32',
      fullName: 'Arc Coin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/b4c08196-3742-4c27-b27a-368d8dfbd73a',
      website: 'https://archiecoin.io/',
    },
    {
      id: '04934d88-19fa-40c0-93b0-3c9906da115d',
      tokenAddress: '0xca3f508b8e4dd382ee878a314789373d80a5190a',
      fullName: 'Beefy Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/beefyfinance.jpeg',
      website: 'https://beefy.finance/',
    },
    {
      id: 'ada096e9-bab2-4f36-856f-fb1d7d4bbff1',
      tokenAddress: '0x339c72829ab7dd45c3c52f965e7abe358dd8761e',
      fullName: 'Wanaka Farm',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/wanaka.jpeg',
      website: 'https://wanakafarm.com/',
    },
    {
      id: 'f2617f43-80d2-4a0d-91d2-06496fded8bb',
      tokenAddress: '0x24802247bd157d771b7effa205237d8e9269ba8a',
      fullName: 'Thetan Coin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/thethan-coin.png',
      website: 'https://www.thetanarena.com/#theta-coin',
    },
    {
      id: '0a290a5b-2db0-4c1a-bafa-592e07677237',
      tokenAddress: '0xb427e47e8fdd678278d2a91eeac014ffcddaf029',
      fullName: 'AgaTech',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a8984e4b-067b-4b82-b87a-bb82a66dc7b7',
      website: 'https://agatech.io/',
    },
    {
      id: '7e95d165-6060-4f58-b33b-0a7b23c4a49f',
      tokenAddress: '0x9d7c580e0bc4ea441db96eebc7e1440d264bce51',
      fullName: 'MoonLift',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/moonlift.png',
      website: 'https://moonlift.io/',
    },
    {
      id: 'cb0e4cd3-1b0b-4c09-901a-3c62583eb3fc',
      tokenAddress: '0x2222227e22102fe3322098e4cbfe18cfebd57c95',
      fullName: 'Alien Worlds Trilium',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/alien-worlds.jpeg',
      website: 'https://alienworlds.io/',
    },
    {
      id: '2abaf803-30bc-4e59-94b3-6335df8aaa2d',
      tokenAddress: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
      fullName: 'Moonpot',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/moonpot.png',
      website: 'https://moonpot.com/',
    },
    {
      id: '825deac6-ccd3-440f-98f3-c0dda8219793',
      tokenAddress: '0x287db351d5230716246cfb46af8153025eda6a0a',
      fullName: 'BlockMonsters',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/blockmonsters.jpg',
      website: 'https://blockmonsters.co/',
    },
    {
      id: '06417543-4c3f-44c5-a6b2-649ca92f6eb3',
      tokenAddress: '0xda6802bbec06ab447a68294a63de47ed4506acaa',
      fullName: 'Crypter',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/crypter.jpeg',
      website: 'https://crypter.quest/',
    },
    {
      id: 'ac3ae2c5-6161-4f42-ad00-ec03aac7b204',
      tokenAddress: '0x6ec90334d89dbdc89e08a133271be3d104128edb',
      fullName: 'Wiki Cat',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c8bf5436-c768-453b-bbfb-e2f7b201cbef',
      website: 'https://wikicatcoin.com/',
    },
    {
      id: '4acb7aad-ee1f-42f2-966b-50a0f9e19cd9',
      tokenAddress: '0xc0eff7749b125444953ef89682201fb8c6a917cd',
      fullName: 'Horizon Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/horizon-protocol.png',
      website: 'https://horizonprotocol.com/',
    },
    {
      id: 'cad32ca9-086a-4e41-9210-5636888a5d2c',
      tokenAddress: '0x9573c88ae3e37508f87649f87c4dd5373c9f31e0',
      fullName: 'Monsta Infinite',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/monsta-infinite.png',
      website: 'https://monstainfinite.com/',
    },
    {
      id: 'c52b03cc-bae1-4fa6-be64-f0887b1c3097',
      tokenAddress: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
      fullName: 'BSCS Token',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bscs.png',
      website: 'https://bscstation.org/',
    },
    {
      id: '04f4cbed-21ae-491c-be41-4c247cd7321d',
      tokenAddress: '0x422e3af98bc1de5a1838be31a56f75db4ad43730',
      fullName: 'CoinWind',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/coinwind.png',
      website: 'https://www.coinwind.com/',
    },
    {
      id: '1df43649-4daa-4c01-b6b2-d081562cfd1e',
      tokenAddress: '0xe32ae22ec60e21980247b4bdaa16e9aea265f919',
      fullName: 'MoonPets',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8fbba88b-38f9-4c38-873a-995767649538',
      website: 'https://moonpets.com/',
    },
    {
      id: 'dbb7c557-2fae-4063-8187-a61ebe3a1017',
      tokenAddress: '0xc7e9d03b6f13d7215c2e2b1854a2b0bdbebe6fb9',
      fullName: 'Bitmarket',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e4207a5a-806e-49f0-99d6-d0fdadd6113e',
      website: 'https://bitmarket.ro/',
    },
    {
      id: '1cc861d3-f472-482c-b922-8288ad5617d8',
      tokenAddress: '0x2c8368f8f474ed9af49b87eac77061beb986c2f1',
      fullName: 'Leonicorn Swap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/leonicorn.png',
      website: 'https://leonicornswap.com/',
    },
    {
      id: '439ec10a-7d8e-43f5-8d6b-d9deca31f935',
      tokenAddress: '0xfe3af7376e412a377358d5894c790bb3e00d0dc1',
      fullName: 'Shar Pei',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/sharpeitoken.png',
      website: 'https://sharpeitoken.io/',
    },
    {
      id: '4374c5c4-c7ee-41b7-9f1e-ffcbe425cffa',
      tokenAddress: '0xbededdf2ef49e87037c4fb2ca34d1ff3d3992a11',
      fullName: 'FEG Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/34dc7301-7906-4525-91d6-84658f0a9bc8',
      website: 'https://fegtoken.com/',
    },
    {
      id: '4ea04273-5645-4ccd-b8f5-e1a7d0b6d9e6',
      tokenAddress: '0x5a3b6f18dc79d50ab208af2fcd61d10bf7e4896f',
      fullName: 'Aetherius',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/aetherius.jpeg',
      website: 'https://www.aeth.finance/',
    },
    {
      id: '74840288-67a5-47ee-853a-815f211d2941',
      tokenAddress: '0xfb62ae373aca027177d1c18ee0862817f9080d08',
      fullName: 'My Defi Pet',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/my-defi-pet.png',
      website: 'https://mydefipet.com/',
    },
    {
      id: '5c15b799-5403-4315-b124-c5a19aacb074',
      tokenAddress: '0x37807d4fbeb84124347b8899dd99616090d3e304',
      fullName: 'Lunr',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/750a8dac-9176-487f-a919-97984ed25116',
      website: 'https://lunarcrush.com',
    },
    {
      id: '82bf25f1-6398-4d0d-baee-ef6260fb72c1',
      tokenAddress: '0xe60eaf5a997dfae83739e035b005a33afdcc6df5',
      fullName: 'Deri',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/deri-protocol.png',
      website: 'https://deri.finance',
    },
    {
      id: 'b4e2109a-3d63-4c92-bc93-5e0e812a1cdf',
      tokenAddress: '0x2e8799f0a26d8a9f37a0b4747fa534f039c2d234',
      fullName: 'Birb',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/birb.svg',
      website: 'https://birb.com/',
    },
    {
      id: '5fd5cd1a-9d54-4082-87c3-bbc5683c7b4f',
      tokenAddress: '0xfcadd830ff2d6cf3ad1681e1e8fc5ddce9d59e74',
      fullName: 'Zada',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/70cabde3-dbb1-4d43-a301-48f801b4f426',
      website: 'https://zadauniverse.com/',
    },
    {
      id: '5df971d2-2856-4fe5-adde-52372fdc418f',
      tokenAddress: '0xf376807dcdbaa0d7fa86e7c9eacc58d11ad710e4',
      fullName: 'Blitz Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/95412566-d3f4-453c-9f9f-c776365a0cf6',
      website: 'https://blitzlabs.io/',
    },
    {
      id: '47d6ad19-35d0-453b-a9a4-55b9b3f7d679',
      tokenAddress: '0x00e1656e45f18ec6747f5a8496fd39b50b38396d',
      fullName: 'BombCrypto',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bombcrypto.jpeg',
      website: 'https://bombcrypto.io/',
    },
    {
      id: 'fdd980b1-73a0-49f5-97a8-4f655eee855b',
      tokenAddress: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6',
      fullName: 'Copycat Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/copycat-finance.png',
      website: 'https://www.copycat.finance',
    },
    {
      id: '61e29abe-1986-4f8e-a561-3d37ad2fb06b',
      tokenAddress: '0x95a1199eba84ac5f19546519e287d43d2f0e1b41',
      fullName: 'Rabbit Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/rabbitfinance.jpeg',
      website: 'https://www.rabbitfinance.io/',
    },
    {
      id: '76a8cae9-292b-4720-8a57-9d345c37356c',
      tokenAddress: '0x5f136383e230f972739fae2e81e7e774afe64c66',
      fullName: 'GFORCE v2',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/gforce.png',
      website: 'https://jetfuel.finance/vaults',
    },
    {
      id: '96f444d8-5f3d-4d61-9e75-b5c75cf402b4',
      tokenAddress: '0x4b5decb9327b4d511a58137a1ade61434aacdd43',
      fullName: 'Poken ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/630e42db-bbb6-430e-bc86-1efc7a2977e3',
      website: 'https://www.pokmi.com/',
    },
    {
      id: 'f607c690-43de-4213-bbeb-cc1de60fa59c',
      tokenAddress: '0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240',
      fullName: 'SingularityDAO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/10c58613-031e-4390-ae05-797eedb6ef31',
      website: 'https://www.singularitydao.ai/',
    },
    {
      id: 'b51097bc-3d5f-46eb-bcb6-9b54a7745d17',
      tokenAddress: '0x05b339b0a346bf01f851dde47a5d485c34fe220c',
      fullName: 'Astronaut',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/astronaut.png',
      website: 'https://astronaut.to/',
    },
    {
      id: '8072d050-8faf-4893-8627-6dedd89b7524',
      tokenAddress: '0xebd49b26169e1b52c04cfd19fcf289405df55f80',
      fullName: 'Orbs',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/orbs.jpeg',
      website: 'https://www.orbs.com/',
    },
    {
      id: '7e620b7c-7c6b-4c08-ad58-2021b1b5d68f',
      tokenAddress: '0x20d39a5130f799b95b55a930e5b7ebc589ea9ed8',
      fullName: 'Heroes & Empires',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/heroes-empires.jpeg',
      website: 'https://heroesempires.com/',
    },
    {
      id: '3504956a-c87e-488b-89dd-1a2c19949cd0',
      tokenAddress: '0xf859bf77cbe8699013d6dbc7c2b926aaf307f830',
      fullName: 'Berry Data',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/berrydata.png',
      website: 'https://berrydata.co/',
    },
    {
      id: 'f4911479-b12d-4849-9765-57a1fb31f9a4',
      tokenAddress: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
      fullName: 'Mdex ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4a919dab-f41d-457b-99a2-a7fcc3cd40cb',
      website: 'https://mdex.co/#/',
    },
    {
      id: '742f8b4a-81fb-499d-a3aa-21afee028da3',
      tokenAddress: '0xb1ced2e320e3f4c8e3511b1dc59203303493f382',
      fullName: 'Moonlight',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c9ed073c-a18d-474e-8051-e5e920c4c0c3',
      website: 'https://moonlighttoken.com',
    },
    {
      id: '926c6c58-cfb0-42d4-b719-98b44735231a',
      tokenAddress: '0x88da9901b3a02fe24e498e1ed683d2310383e295',
      fullName: 'Baby Grok',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f9a2570b-5bea-4dd8-a401-a90af3ce2d05',
      website: 'https://babygrok.ai',
    },
    {
      id: 'f3974d5b-d3d5-4025-8c94-d9bea71fc298',
      tokenAddress: '0xf729f4d13a9c9556875d20bacf9ebd0bf891464c',
      fullName: 'BUSDX',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/busdx.jpeg',
      website: 'https://www.busdx.com/',
    },
    {
      id: '863d2cde-4d67-4658-bab7-f5a37fb48fbd',
      tokenAddress: '0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c',
      fullName: 'DEEPSPACE',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/deepspace.jpeg',
      website: 'https://deepspace.game/',
    },
    {
      id: 'f44f0b32-b330-4e63-96a2-e7508fdd7110',
      tokenAddress: '0x2f0c6e147974bfbf7da557b88643d74c324053a2',
      fullName: 'Catcoin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f716f7cc-1e29-4297-8972-00a66689fdd0',
      website: 'https://catcoin.io/',
    },
    {
      id: 'd71fb620-25d5-4b23-8710-ed3fa4f07f07',
      tokenAddress: '0x50a9eb8a53f2c2993f46b354bd5f24f1c880bf24',
      fullName: 'TeleTreon',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/42982beb-fc28-4f23-bcd6-1d286755b608',
      website: 'https://teletreon.com',
    },
    {
      id: 'af7dd8ee-8ba9-4fc6-ac29-1aa3463f6431',
      tokenAddress: '0x6ad0f087501eee603aeda0407c52864bc7f83322',
      fullName: 'META FACE',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/30ff4f2a-ec47-4949-903b-e790882cf4a6',
      website: 'http://www.metaversefacemaker.com',
    },
    {
      id: '0adb155c-2ef9-41c0-8be5-c374ebfd5117',
      tokenAddress: '0x4c48cca6153db911002f965d22fdefcd95f33be9',
      fullName: 'The Essential Coin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/the-essential-coin.jpeg',
      website: 'https://theessentialcoin.org/',
    },
    {
      id: '48e11a0a-61bc-4196-a1f7-1fe4a29c1ceb',
      tokenAddress: '0xf315cfc8550f6fca969d397ca8b807c5033fa122',
      fullName: 'Game Ace Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/gat-network.jpeg',
      website: 'https://gat.network/',
    },
    {
      id: '70fcab89-db3b-4fb0-81c2-bca80d6203ca',
      tokenAddress: '0x263a5378e112fc41744b50ec40c0c0adce5884c7',
      fullName: 'RÁBANO COIN',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4cc465c5-ad30-448f-9d0e-77ca50f1bb08',
      website: 'https://rabanocoin.online/',
    },
    {
      id: '7d4c4134-2593-44b1-8417-3b357d4447d4',
      tokenAddress: '0xf48f91df403976060cc05dbbf8a0901b09fdefd4',
      fullName: 'Minu',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a0b9ddce-e720-41f5-a478-7f20eeaa265f',
      website: 'https://www.minucoin.com/',
    },
    {
      id: '4bb6aa71-e602-43fd-b8a9-af8cd283d142',
      tokenAddress: '0x23ec58e45ac5313bcb6681f4f7827b8a8453ac45',
      fullName: 'Zenfuse',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/zenfuse.jpeg',
      website: 'https://zenfuse.io/',
    },
    {
      id: 'bbee3859-8936-42c2-a99f-4bc6d5da9548',
      tokenAddress: '0x1446f3cedf4d86a9399e49f7937766e6de2a3aab',
      fullName: 'KingDeFi',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/kingdefi.jpeg',
      website: 'https://kingdefi.io/king-farms',
    },
    {
      id: 'c1a30411-617c-495b-884c-361c0734c218',
      tokenAddress: '0x98936bde1cf1bff1e7a8012cee5e2583851f2067',
      fullName: 'Annex Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/annex.png',
      website: 'https://www.annex.finance/',
    },
    {
      id: 'cfad8e7a-27fc-42bf-b426-4aeba8abd966',
      tokenAddress: '0x6e61579c22f9a6da63a33e819f29b6697d2a126e',
      fullName: 'RocketFi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/44c841e2-be0a-4d79-a05c-fa78c9b6757c',
      website: 'http://RocketFi.money',
    },
    {
      id: '99c523a9-3569-4683-adf6-a3e1b9a1fa79',
      tokenAddress: '0x4da996c5fe84755c80e108cf96fe705174c5e36a',
      fullName: 'WOWswap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/wowswap.png',
      website: 'https://wowswap.io/swap',
    },
    {
      id: 'bd91fc70-f052-4258-826d-43b3e19d1045',
      tokenAddress: '0x2223bf1d7c19ef7c06dab88938ec7b85952ccd89',
      fullName: 'Kryxivia Coin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/65bde817-2971-4c17-891b-6d3ddcf26c0b',
      website: 'https://kryxivia.io/',
    },
    {
      id: '5504445e-ea10-4a45-89af-232604b24eff',
      tokenAddress: '0xaf96a19c2dd4a0f6b077d9481fcc8c9102faa141',
      fullName: 'Moonarch',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/moonarch.png',
      website: 'https://moonarch.app/',
    },
    {
      id: 'ba145280-ff78-462a-9888-dfa728e1c2ac',
      tokenAddress: '0x7251c7a2155d8fedb42a1dc4333ade589e4d6919',
      fullName: 'Lemonn',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/lemonn.png',
      website: 'https://lemonn.io/',
    },
    {
      id: 'c65c2a9b-9e76-4c7b-bab6-61f3678440c8',
      tokenAddress: '0x0e8d5504bf54d9e44260f8d153ecd5412130cabb',
      fullName: 'UNCL',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/uncl.png',
      website: 'https://unicrypt.network/',
    },
    {
      id: '7d35b2af-1d18-4af2-9ddf-323a86b5e46a',
      tokenAddress: '0x32299c93960bb583a43c2220dc89152391a610c5',
      fullName: 'Kalata Protocol',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/kalata.png',
      website: 'https://www.kalata.io/',
    },
    {
      id: 'fb757d11-d588-4925-bfe0-f381766efb32',
      tokenAddress: '0xbcc608002765339db153d07250d516bc4356531b',
      fullName: 'Leonidasbilic ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/07634875-be51-4cee-b2a5-728b13e3847b',
      website: 'http://www.leonidasbilic.io',
    },
    {
      id: 'c170f3c5-1262-46b8-b1c8-cd14caaaee0c',
      tokenAddress: '0x463e737d8f740395abf44f7aac2d9531d8d539e9',
      fullName: 'Toad Network',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/toadnetwork.jpeg',
      website: 'https://toad.network/',
    },
    {
      id: 'f4461ce1-5713-4913-ae83-6335b505a1dc',
      tokenAddress: '0x999aeb6d778b3883c88ebc502b16a5395d011462',
      fullName: 'EarlyBird',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/earlybird.png',
      website: 'https://ebird.finance',
    },
    {
      id: '110e5e85-6ec2-4c9c-bb07-b081015881eb',
      tokenAddress: '0x855ea8048e1852996429a50abda60f583909d298',
      fullName: 'Phantasma Energy',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8cdd8303-e2c0-43a8-aee5-8c37537731c2',
      website: 'https://phantasma.io/',
    },
    {
      id: 'eab7cd13-81d1-4262-8e81-69b24dc8e077',
      tokenAddress: '0x0cae6c43fe2f43757a767df90cf5054280110f3e',
      fullName: 'Affinity',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/safe-affinity.jpeg',
      website: 'https://safeaffinity.com/',
    },
    {
      id: 'fef89683-f1c1-4c8d-b8a2-9dab11eac3e7',
      tokenAddress: '0x77774a06271d6a305caccdbc06f847def05c7777',
      fullName: 'Gyrowin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/be45994a-3095-4661-818c-698fa8264f0e',
      website: 'https://gyro.win',
    },
    {
      id: '2edda6a4-c9ca-4f31-bc01-3f8a2840c314',
      tokenAddress: '0x766afcf83fd5eaf884b3d529b432ca27a6d84617',
      fullName: 'Bolide',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e03e1dbd-bff4-4aa9-ad66-792cf1c82ee7',
      website: 'https://bolide.fi/',
    },
    {
      id: '2a49dd6b-5fbe-4aee-9499-f59c53344729',
      tokenAddress: '0x1f7e8fe01aeba6fdaea85161746f4d53dc9bda4f',
      fullName: 'GGToken',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2b59c9f9-a266-4c8b-aeea-212210191903',
      website: 'https://ggtkn.com/',
    },
    {
      id: 'db397c36-6577-483e-b0fd-5f3399684481',
      tokenAddress: '0x7601157e00750e2adc28b2cb2a51419bdca53ac9',
      fullName: 'Next Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/19349cda-9da0-4b21-b40b-cd3d4a5e8262',
      website: 'https://nxt-token.com/',
    },
    {
      id: '7b28879a-9999-4348-b49e-739e4e1e49ef',
      tokenAddress: '0x64ef3ae48977075d917e448b61c7b030b9c387fc',
      fullName: 'INME Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2f5f5800-5eed-4e54-900c-a874bec49721',
      website: 'https://www.inme.finance/',
    },
    {
      id: '29ad991e-bd67-4a8c-af80-91a92ff1df37',
      tokenAddress: '0xae9269f27437f0fcbc232d39ec814844a51d6b8f',
      fullName: 'BurgerSwap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/burgerswap.png',
      website: 'https://burgerswap.org',
    },
    {
      id: 'a33541a9-7822-4b00-89e5-58f33de40a52',
      tokenAddress: '0x8a5d7fcd4c90421d21d30fcc4435948ac3618b2f',
      fullName: 'CAKE MONSTER',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/23b2a82f-c79e-408a-b40f-60a228f9dc18',
      website: 'https://cake.monster/',
    },
    {
      id: '49140b96-4aca-44c5-9d20-9b9e59f0217f',
      tokenAddress: '0xa296ad1c47fe6bdc133f39555c1d1177bd51fbc5',
      fullName: 'iBuffer Token',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/buffer.png',
      website: 'https://www.buffer.finance/',
    },
    {
      id: 'aaaf4e1f-f039-4a41-9885-acb693e8cbdb',
      tokenAddress: '0x27ae27110350b98d564b9a3eed31baebc82d878d',
      fullName: 'CumRocket ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2aedc493-f5c5-49cf-8553-f9b8d81bc13e',
      website: 'https://cumrocket.io/',
    },
    {
      id: '91c998da-1d62-4c89-9421-4c8620acb3d1',
      tokenAddress: '0xa1ac3b22b102caa62c9ecaf418585528855b0ddd',
      fullName: 'STRIPTO',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/stripto.png',
      website: 'https://stripto.io/',
    },
    {
      id: '44ace2f0-17a2-4bf9-9526-c486563abed8',
      tokenAddress: '0x14e8bcd053e68a22f239b9e9bead87932465d245',
      fullName: 'Value Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/value-finance-2.jpeg',
      website: 'https://valuefinance.io/',
    },
    {
      id: '8c2f07b8-6e7c-4a9c-b618-aa164198824d',
      tokenAddress: '0x10f6f2b97f3ab29583d9d38babf2994df7220c21',
      fullName: 'DRAC Network ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2e400a8c-f4c3-437a-af2e-b2ff287fc6b1',
      website: 'http://teddydoge.finance',
    },
    {
      id: '61b3235d-b85a-4b26-97bb-c68ec89635a3',
      tokenAddress: '0x95ee03e1e2c5c4877f9a298f1c0d6c98698fab7b',
      fullName: 'Duet Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4a45ee77-32bc-4ad7-bd52-e393b4ac2956',
      website: 'https://www.duet.finance/',
    },
    {
      id: '6f972282-4d63-4532-b812-d73499e19204',
      tokenAddress: '0x5f113f7ef20ff111fd130e83d8e97fd1e0e2518f',
      fullName: 'AiMalls',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/559ba347-03e0-4ecd-bf55-4733bd734b45',
      website: 'https://aimalls.app/',
    },
    {
      id: 'b8bfd638-a4a6-4421-bc1a-653603d8cc03',
      tokenAddress: '0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261',
      fullName: 'Shakita Inu',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/shakita-inu.jpeg',
      website: 'http://www.shakitainu.com',
    },
    {
      id: '990994a9-ada7-4392-a436-2ad54017f855',
      tokenAddress: '0x7859b01bbf675d67da8cd128a50d155cd881b576',
      fullName: 'Mars Ecosystem',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/mars.jpg',
      website: 'https://marsecosystem.com/home',
    },
    {
      id: '95479706-d26e-48a7-b225-9a704b266c55',
      tokenAddress: '0x7f0cd209b05cd6a1183a5fadd1b0fcaa6f50f808',
      fullName: 'Hero Arena',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/hero-arena.jpeg',
      website: 'https://heroarena.app/',
    },
    {
      id: 'bdd7c471-cb27-46de-8179-a1d0893904f3',
      tokenAddress: '0xd283c315ec7f562eadc1c063fe34ceb185aa6211',
      fullName: 'MrShillz',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/mrshillz.jpeg',
      website: 'https://mrshillz.net/',
    },
    {
      id: 'b827fb25-2bc0-4136-885c-7288b38303ae',
      tokenAddress: '0x6d3a0fb0070ea61f901ebc0b675c30450acac737',
      fullName: 'Aether',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/aether.jpeg',
      website: 'https://aetheruniverse.com',
    },
    {
      id: '53f64319-f5f5-4f8e-a8fb-29b0740f420f',
      tokenAddress: '0x758d08864fb6cce3062667225ca10b8f00496cc2',
      fullName: 'NAOS Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/naos-finance.jpeg',
      website: 'https://naos.finance/',
    },
    {
      id: '3c758496-56f1-48cd-b974-84f2e6ce4e14',
      tokenAddress: '0x4f3266a56589357b4f8082918b14b923693e57f0',
      fullName: 'Liquid Collectibles LICO',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/e389cefc-6df1-45a4-aa50-e4721469a822',
      website: 'https://liquidcollectibles.io/',
    },
    {
      id: '130e2094-c047-44ea-9568-c3772f51fe5c',
      tokenAddress: '0x56aa0237244c67b9a854b4efe8479cca0b105289',
      fullName: 'WalletNow',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c7e9b77d-35c8-4b9b-a570-aa1c201f0407',
      website: 'https://walletnow.net/',
    },
    {
      id: '154597c9-d0a8-4082-af00-1256a8661886',
      tokenAddress: '0x9b17baadf0f21f03e35249e0e59723f34994f806',
      fullName: 'inSure DeFi',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/insure-defi.jpeg',
      website: 'https://insuretoken.net/',
    },
    {
      id: 'ed27588d-5192-4cfd-9c93-169585ac3c2e',
      tokenAddress: '0xd73e883e203646e87f6d073baf3d7719bda68bcb',
      fullName: 'Rhythm',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/rhythm.jpeg',
      website: 'https://rhythm.cash/',
    },
    {
      id: '5f9b5358-925d-4a30-ad2c-a838e8cddd2e',
      tokenAddress: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
      fullName: 'HedgePay',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/129e4a93-a6d0-48a6-9c47-8a671d6faa4e',
      website: 'https://hedgepay.org/',
    },
    {
      id: '76a1b48b-f6f3-476c-96d9-bb13401920ce',
      tokenAddress: '0xf10770649b0b8f62bb5e87ad0da7729888a7f5c3',
      fullName: 'NEXTEP',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/nextep.jpeg',
      website: 'https://nextepcrypto.com/fr/',
    },
    {
      id: '2c3ba0bb-8810-4498-9b28-e5873c4f5704',
      tokenAddress: '0x16faf9daa401aa42506af503aa3d80b871c467a3',
      fullName: 'DexCheck',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/fff5c618-9759-4dc0-bca0-b64be1685b6c',
      website: 'https://dexcheck.ai/app',
    },
    {
      id: 'e7eb20e5-4f6d-4f0f-814f-1f77bedda9b1',
      tokenAddress: '0xaef0a177c8c329cbc8508292bb7e06c00786bbfc',
      fullName: 'PULI INU',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/768d2241-8d25-4c92-b32a-e4c5c12be712',
      website: 'https://pulitoken.net',
    },
    {
      id: '123008c0-a698-49b3-a8b7-3bb62a8b2940',
      tokenAddress: '0x950481789959cd6d77f1b88c2e1f61e30608c4e2',
      fullName: 'KALISSA',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0faaf386-317b-4c49-9231-ff897ce23a5d',
      website: 'https://www.kalissa.io',
    },
    {
      id: 'b2cca3cc-0876-4064-8b47-5ad7c69f31e6',
      tokenAddress: '0xc77dd3ade7b717583e0924466e4e474a5673332c',
      fullName: 'Magic Beasties',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/magicbeasties.jpg',
      website: 'https://beasties.online/',
    },
    {
      id: 'df43a5be-09ac-4fd5-a1c5-557c91724b6d',
      tokenAddress: '0xc3adbf524513863102df6784e1ab5652165c7912',
      fullName: 'Phantasma Stake',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a3185bf6-cd12-4274-b71f-249b543d8b31',
      website: 'https://phantasma.io/',
    },
    {
      id: 'eb7ea0a9-b545-44e4-a1d6-58fa0a79487b',
      tokenAddress: '0xfe8e0e9a7a5ae744aaebeac38e3b9b1da7b17af3',
      fullName: 'Watch DO',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/watch-do.jpeg',
      website: 'https://www.watchdo.fr/',
    },
    {
      id: '11403ebf-719d-4804-abfd-a9e63e17632b',
      tokenAddress: '0x9b3a01f8b4abd2e2a74597b21b7c269abf4e9f41',
      fullName: 'Altbase',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/altbase.jpeg',
      website: 'https://altbase.com/',
    },
    {
      id: '12cb5adc-2041-4109-9979-79306ed528e2',
      tokenAddress: '0x2fa0cac2c75efb50382b5091c6494194eacf65b0',
      fullName: 'Waterfall DeFi',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/waterfall.jpeg',
      website: 'https://waterfalldefi.org/',
    },
    {
      id: '6038bec2-575d-45b2-8182-99191b83e88d',
      tokenAddress: '0x71d03f5cbf039febcc6ee8dbe20bc9ba3ea874fe',
      fullName: 'Web3Shot',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/42e49f7b-14eb-49fa-83ce-0ba01a60767a',
      website: 'https://web3shot.io/',
    },
    {
      id: '740c2890-a1c7-4df4-98ba-acc888e2ce70',
      tokenAddress: '0x9733e1038897efb36bb2e526c328c9b6a4b78db2',
      fullName: 'PornVerse',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/7f02fc22-6fe0-47ed-a818-9c0a10a5787a',
      website: 'https://pornverse.land',
    },
    {
      id: 'ab6a2aa8-9d03-445e-999e-c263a4522c43',
      tokenAddress: '0x680a501ea4545c0fb18827ebcbcf9b645469abd5',
      fullName: 'CoinCalendar ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/fa34726f-47b5-4aea-8e4b-3b99a868d56a',
      website: 'https://www.coincalendar.cash/',
    },
    {
      id: 'a704c98a-1d1b-420e-b03f-d46302fbbb9a',
      tokenAddress: '0x0e4b5ea0259eb3d66e6fcb7cc8785817f8490a53',
      fullName: 'SokuSwap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/sokuswap.jpeg',
      website: 'https://www.sokuswap.org',
    },
    {
      id: 'b503aac5-77d0-48c9-b371-77edbc50a241',
      tokenAddress: '0x311e015e2e255dd14eddc4a2107e39649dc81c5d',
      fullName: 'Flash Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/flash-token.png',
      website: 'https://flash-token.com/en/',
    },
    {
      id: 'c5ab7158-3724-437c-8e26-4862a90f474b',
      tokenAddress: '0x0e9766df73973abcfedde700497c57110ee5c301',
      fullName: 'HODL Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f74a3792-653a-40fa-8bcf-3d9ebb821a78',
      website: 'https://hodltoken.net',
    },
    {
      id: '8e539d66-48ba-43db-bfb1-d4196e73f035',
      tokenAddress: '0x33a3d962955a3862c8093d1273344719f03ca17c',
      fullName: 'Spore',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/spore.png',
      website: 'https://spore.earth/',
    },
    {
      id: 'd1905da1-e49e-477b-9b86-8112c9afd9ab',
      tokenAddress: '0x3f8b23753807b83312545b1f6ff265f13d7be970',
      fullName: 'NOWAR',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/6dd79309-235e-463a-adc7-74bd3bdb3274',
      website: 'https://www.nowars.site/',
    },
    {
      id: 'a40672a6-6299-458e-af4e-74eb0833bf43',
      tokenAddress: '0x523821d20a283d955f6205b4c9252779cd0f964b',
      fullName: 'Wrapped Okcash BSC',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/7b33fe79-8129-4954-a74b-93a4a214b13f',
      website: 'https://okcash.co/',
    },
    {
      id: '0ddc0c50-fd67-4d04-8c03-22a2a372c5a2',
      tokenAddress: '0x424aa711301c82252eccaccf01301ad7ad7b5d40',
      fullName: 'Taroverse Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/340070d1-4519-41b1-bb19-4c4c98f3d9a9',
      website: 'https://www.taroverse.com',
    },
    {
      id: '5a215d3b-2e3c-4ee4-b504-46efd4da06f7',
      tokenAddress: '0x741f72bc9e29f662f2eb41c5ab450a2ca33be57d',
      fullName: 'Deez Nuts',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/deeznuts.png',
      website: 'https://www.deeznutscoin.com/',
    },
    {
      id: '0a7176c0-561f-41b2-a238-80d81aa6cc87',
      tokenAddress: '0xf376c874eacdcaaeb2f4012e5e09cf99357d830f',
      fullName: 'Easter Floki',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/easter-floki.jpeg',
      website: 'https://www.easterfloki.com/',
    },
    {
      id: '5b9f8ad6-fe39-4e69-87b3-e191c9b2c478',
      tokenAddress: '0x9b9d617d3445f0147ece2322bace8db2768d2770',
      fullName: 'CUE Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cue-protocol.png',
      website: 'https://cuep.io/hub/#/dashboard',
    },
    {
      id: 'efd79637-a54d-4b77-9b5b-dec30e346cd6',
      tokenAddress: '0x777994409c6b7e2393f6098a33a9cd8b7e9d0d28',
      fullName: 'Cryptotem',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cryptotem.jpeg',
      website: 'https://cryptotem.net/',
    },
    {
      id: '76702c83-02dc-4260-bd62-60b53da4a98a',
      tokenAddress: '0xa532cfaa916c465a094daf29fea07a13e41e5b36',
      fullName: 'Hibiki Finance',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/hibiki.jpeg',
      website: 'https://hibiki.finance/',
    },
    {
      id: '4cb5e563-8587-4e14-b067-9939701cb0d2',
      tokenAddress: '0x87ffc48c9f89fc5dfa05836e083406d684fd6331',
      fullName: 'Hachiko',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/hachiko.png',
      website: 'https://hachiko.cc/',
    },
    {
      id: 'df329769-19d0-4923-a47f-3441839eae5c',
      tokenAddress: '0x00f97c17f4dc4f3bfd2dd9ce5e67f3a339a8a261',
      fullName: 'Shield Protocol',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/shield-protocol.png',
      website: 'https://shieldprotocol.org/',
    },
    {
      id: 'ff334133-f959-4335-ac60-584d6320af8d',
      tokenAddress: '0xf78a2e1824638d09571172368bbe1d8d399893ab',
      fullName: 'BotopiaFinance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/botopia-dark.jpeg',
      website: 'https://botopia.finance/',
    },
    {
      id: 'ef46470f-902f-48ff-a70b-617dbd0e07ec',
      tokenAddress: '0xe6df015f66653ece085a5fbba8d42c356114ce4f',
      fullName: 'Pyrrho',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/de5eed9d-499d-4ad0-8678-7d2c421774e4',
      website: 'https://www.pyrrhollc.com/',
    },
    {
      id: '7865aa14-2744-41d9-a45a-c8b7d39613b6',
      tokenAddress: '0x198abb2d13faa2e52e577d59209b5c23c20cd6b3',
      fullName: 'Bamboo',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bamboo.png',
      website: 'http://www.bamboodefi.com',
    },
    {
      id: '64097865-032c-4cc4-b8c4-30bf5f138a81',
      tokenAddress: '0x8780fea4c6b242677d4a397fe1110ac09ce99ad2',
      fullName: 'Bird',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/bird.jpeg',
      website: 'https://bird.money/',
    },
    {
      id: '988d8f9f-0fb8-4246-a5e2-3d0db5eb89e6',
      tokenAddress: '0x6759565574de509b7725abb4680020704b3f404e',
      fullName: 'VIP TOKEN',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/350eff70-5d78-4ec8-a934-a9bc7f377cdb',
      website: 'https://viptoken.io/',
    },
    {
      id: 'a52316b8-ac0e-447a-9641-5bb54f645526',
      tokenAddress: '0x7c1608c004f20c3520f70b924e2bfef092da0043',
      fullName: 'CZFarm',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/czfarm.png',
      website: 'https://app.czodiac.com/#/czfarm',
    },
    {
      id: '15dde036-41f7-4892-a12d-95657fe6d0a4',
      tokenAddress: '0x0420eb45ac5a4f04763f679c07c3a637741e0289',
      fullName: 'DoKEN',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/doken.jpeg',
      website: 'https://doken.exchange/',
    },
    {
      id: '130380a1-c49a-4b57-a47c-ab91afe284ea',
      tokenAddress: '0xba94c527e780affcdce175eb03fba9df0e392d2d',
      fullName: 'Cuex Capital',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cuex.png',
      website: 'https://cuex.cuep.io/',
    },
    {
      id: '26add917-733c-4bc1-8e31-be612fe6b025',
      tokenAddress: '0xcdaf240c90f989847c56ac9dee754f76f41c5833',
      fullName: 'Pirate Chain',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0ac24bee-76c1-43aa-8e41-bd78a8973dcd',
      website: 'https://pirate.black/',
    },
    {
      id: '7dca093f-bf31-461c-8935-e2cf905df426',
      tokenAddress: '0xde9a73272bc2f28189ce3c243e36fafda2485212',
      fullName: 'Channels',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/channels.png',
      website: 'https://channels.finance/',
    },
    {
      id: '7ab36f39-9683-4bec-9091-a11721fd7636',
      tokenAddress: '0xde2a66c92583332e8e1f0aeeb03deb749a3fd33a',
      fullName: 'Goblin',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4cbf5a7e-4d0c-4cf7-8f30-401fc2c452d4',
      website: 'http://www.goblincoin.io',
    },
    {
      id: '1cd65931-bd3b-42f3-b808-5f1498dc5d83',
      tokenAddress: '0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
      fullName: 'Warden',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/warden.png',
      website: 'https://www.wardenswap.finance/',
    },
    {
      id: 'b27804d9-9e8b-4244-8d05-cba08bedc2d7',
      tokenAddress: '0x2a6f8e3a8fe92849b08ca82f4f323dd43029b33d',
      fullName: 'AAptitude',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/10f42d5c-f876-4781-a4ff-3e74d3cb8984',
      website: 'https://aaptitude.com/',
    },
    {
      id: 'b0398ef4-753c-4c71-92c8-22ae4fbe6bc2',
      tokenAddress: '0xabb912c1caebea89217138761cea4aa3469a9e08',
      fullName: 'FUDx',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/22f686eb-2ae4-4658-b2fe-4d89aaab8806',
      website: 'https://fxccoin.io/',
    },
    {
      id: 'bc46f182-36f6-4b41-861f-1c9de10c9a16',
      tokenAddress: '0x335f07ac2c4363a5951427cbda38b0075455b2b5',
      fullName: 'RetroDoge',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/f55457fc-00e4-40d7-b3e7-dfa3ecc01dba',
      website: 'https://retrodoge.net/',
    },
    {
      id: 'cb8b3ee3-f13b-4e28-b904-9896eca01990',
      tokenAddress: '0x682ca13182eca9e5230d8654215037815288b556',
      fullName: 'Poor Quack',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/poorquack.jpeg',
      website: 'https://poorquack.com/',
    },
    {
      id: '424ff5eb-b278-4459-8209-629853fd072e',
      tokenAddress: '0x9027f130257e8b0d0801b1378dab6e4c3c88b581',
      fullName: 'Arabian.Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/arabian-finance.jpeg',
      website: 'https://www.thearabianfinance.com/',
    },
    {
      id: '2e4bb65c-223c-4752-a177-2bd25cb7e7b1',
      tokenAddress: '0xd6da6491a6663b1d413715f4fd8edd74a4b47694',
      fullName: 'The Landlord',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/a9115202-9e31-4fb4-b51b-f628d6784539',
      website: 'https://www.thelandlord.biz/',
    },
    {
      id: 'ba0e9ca0-5fc4-4653-bb3c-797577f2df4b',
      tokenAddress: '0xf380334b9a181f54bf2f32fdd3e153d259472ebb',
      fullName: 'TIPSY',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/tipsy.png',
      website: 'https://land.tipsyonline.io/',
    },
    {
      id: '160fc605-1a6c-42e8-a6b5-925152bdee08',
      tokenAddress: '0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389',
      fullName: 'ACryptoSI',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/acryptosi.png',
      website: 'https://app.acsi.finance/',
    },
    {
      id: 'eca57782-3bc7-47eb-8877-e6fe258f5a2c',
      tokenAddress: '0x229c32460c6beac113e720ac4a7495b57f53f7cf',
      fullName: 'Donaswap',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/donaswap.jpeg',
      website: 'https://www.donaswap.com/',
    },
    {
      id: 'f40415f8-a8ba-4b70-813d-a467a8258b55',
      tokenAddress: '0xd882739fca9cbae00f3821c4c65189e2d7e26147',
      fullName: '4thpillar technologies',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/745022aa-b5f2-4031-8a59-c4f9d961f2a3',
      website: 'https://the4thpillar.io/',
    },
    {
      id: 'bfa59c6a-7151-4a15-9c42-a499f40e33d5',
      tokenAddress: '0xae9cc5236a2fbb2a9a2e913298d3048c7aa4b722',
      fullName: 'MetaContinental',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/00128824-a355-465e-85a5-d4b9a83a02f3',
      website: 'https://metacontinental.io/',
    },
    {
      id: 'd548e6b3-d976-4bac-9e07-1ffe2338583a',
      tokenAddress: '0x490dba6180b089d8e36ebb1e56a33f1ae9b96b87',
      fullName: 'OpenBiSea',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/open-bi-sea.jpeg',
      website: 'https://openbisea.io/',
    },
    {
      id: '60da4cb2-42e6-46f3-a33d-ff97a4d8c13c',
      tokenAddress: '0x3a701df09fe2db96b40e5da0d8d490a654b73e4c',
      fullName: 'Kudos Token',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/kudos.png',
      website: 'https://www.kudostoken.com/',
    },
    {
      id: 'e2d990df-63c4-470e-a3cb-ccb1b6168b79',
      tokenAddress: '0xf700d4c708c2be1463e355f337603183d20e0808',
      fullName: 'Outer Ring',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4a653e52-390e-4869-b693-1616d3f2eb74',
      website: 'https://outerringmmo.com/',
    },
    {
      id: '9397fe0b-2d57-4f9d-85fb-3e43d0fa8f24',
      tokenAddress: '0x7769d930bc6b087f960c5d21e34a4449576cf22a',
      fullName: 'Ocean Finance',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/0320872a-c73a-4679-bf0c-52fb337e31eb',
      website: 'https://oceans.finance/',
    },
    {
      id: 'b0670d21-8bff-4693-b4da-ba27890e04f0',
      tokenAddress: '0xffad7f9f704a5fdc6265e24b436b4b35ed52def2',
      fullName: 'CloudTx',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2f8e8e8a-9f60-440c-be8b-a40646e8f399',
      website: 'https://cloudtx.finance/',
    },
    {
      id: '8e4691a9-3430-49b8-840b-67e5db228c0a',
      tokenAddress: '0x090006ee9e12b646ce1ece6b18096c023ed38751',
      fullName: 'KSOS Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/67ed1945-1010-4491-9e15-f47e989c8d3f',
      website: 'https://www.ksostoken.com/',
    },
    {
      id: 'd3fc3bd5-81c8-40e5-abfe-962a0a7ff7b3',
      tokenAddress: '0xa410581ec8e6b11f41d116ca4e494ca499891323',
      fullName: 'Infinity Realm',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/cdfd8211-adef-4411-832b-ac8a62a18768',
      website: 'https://infinityrealm.co/',
    },
    {
      id: '6bab8dc5-9178-408b-b844-1ab601849247',
      tokenAddress: '0x988300f65191c3a52ef59dbb5539c01b502787a9',
      fullName: 'Baby Care',
      imageUrl: 'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/babycare.png',
      website: 'https://babycare.to/',
    },
    {
      id: '7939ecd1-9aae-4f28-a319-c084f146c2c5',
      tokenAddress: '0x0566b9a8ffb8908682796751eed00722da967be0',
      fullName: 'FGDTOKEN',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/217b3cc0-1492-4bd0-93a6-a47a556130d2',
      website: 'https://dontbuythiscrap.com',
    },
    {
      id: 'f5de4763-0f11-4f0b-9439-09cb71348252',
      tokenAddress: '0xc85103647048e8eed1b9a3938164dd2c72a4c6bb',
      fullName: 'METASIMS',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/5cbf36d8-00fc-47fd-a54a-21ae2e55c0f1',
      website: 'https://www.rugpulldontinvest.io',
    },
    {
      id: 'a2f03981-acb7-4fdf-801c-025210dbb63c',
      tokenAddress: '0x5e2b35586be94536418456ae99debe9a067da855',
      fullName: 'FIFADOGE',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/43f32a18-aafb-49ec-a7ba-6fcd06309548',
      website: 'https://www.fifadoge.xyz/',
    },
    {
      id: '6ef0c432-1229-440f-b7c1-68af3398a1e1',
      tokenAddress: '0x6f5c228d4ac47ebdcad7ee02b591a562d577eb16',
      fullName: 'Trebol token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/4d17c328-efdb-4824-ba2b-604b3d97436c',
      website: 'https://www.treboltoken.com/https://www.treboltoken.com/',
    },
    {
      id: '3b940f21-d388-41ad-ade9-46b9fe045c2a',
      tokenAddress: '0xbe1936a67f503e0eaf2434b0cf9f4e3d7100008a',
      fullName: 'Prospective LLc',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/40e7e4e2-d370-446b-9484-c772e7eb7c4e',
      website: 'https://www.Prospective.world',
    },
    {
      id: '9674309b-681e-4203-abb8-53975c5775b0',
      tokenAddress: '0x5ca42204cdaa70d5c773946e69de942b85ca6706',
      fullName: 'Position Token',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/8e08a890-a252-43fb-b06a-b23f5d26427e',
      website: 'https://app.position.exchange/',
    },
    {
      id: 'ee20487e-c451-414d-aa41-0a470cf7b866',
      tokenAddress: '0xdc8c8221b8e27dfda87a6d56dc5899a65087b6f4',
      fullName: 'Flokita',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/77483a73-1e63-4559-875a-102d9598674d',
      website: 'https://flokita.cx/',
    },
    {
      id: '3b7ce754-01eb-4fef-b498-2f1294b47baa',
      tokenAddress: '0x0a8901b0e25deb55a87524f0cc164e9644020eba',
      fullName: 'Pancake Squad',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/2a95aa28-06f6-4e71-8777-e943da3ac18f',
      website:
        'https://pancakeswap.finance/nfts/collections/0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    },
    {
      id: '68fe178a-4cb6-4159-86e4-5a06c1231d49',
      tokenAddress: '0x5f41842cff838120271d772c6994f051d418a4ad',
      fullName: 'PixelSweeper ',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/262b8406-8bb8-4bbb-8928-84113e930c1b',
      website: 'https://pixelsweeper.com/dashboard',
    },
    {
      id: 'e108c1b8-31ab-48ce-a710-d446993217a1',
      tokenAddress: '0xa1facbee77125c4fd63ebeaaed43926c3338ea04',
      fullName: 'Oddblox',
      imageUrl:
        'https://storage.googleapis.com/public-bubblemaps/app/tokens-images/c3f3e666-3aae-450f-b6bb-c654cd90583d',
      website: 'https://oddblox.io/',
    },
  ],
  popularTokenIds: [
    'a8e69bd1-f663-4c12-9b28-5fe4df7b8026',
    'a7fb912f-399b-4f90-b0a5-f610185be52c',
    '7bac255f-f4b7-4053-bff9-5ca355ae2c57',
    '0292462c-07b8-4c26-be6f-2f7bcfedf372',
  ],
  mostVisitedTokenIds: [
    '49874b63-0d10-4f0a-816d-ffead9b8fd4c',
    'a7fb912f-399b-4f90-b0a5-f610185be52c',
    '6bab8dc5-9178-408b-b844-1ab601849247',
    '9125d9d9-e9de-4f3d-bb54-b139c826f245',
    'c73f40b6-7fc9-430d-89a7-d4f09dfec5a8',
  ],
  recentlyListedTokenIds: [
    '2ed0cef2-78c9-4f80-be1c-c556432dd6de',
    '47596539-8390-4f18-b7fb-15cd70f686d3',
    '7763530b-9aaa-471d-8971-95aa35a6efb1',
    '1681c2f5-0a0b-4c38-90eb-2d8d617074c8',
    'b63c40b2-a1af-4393-96d1-3a8735026685',
  ],
  trendingTokenIds: [
    'a8e69bd1-f663-4c12-9b28-5fe4df7b8026',
    'a7fb912f-399b-4f90-b0a5-f610185be52c',
    '7bac255f-f4b7-4053-bff9-5ca355ae2c57',
  ],
};

export const tokenDetail = {
  chain: 'eth',
  dt_update: 'Sat, 16 Mar 2024 14:40:05 GMT',
  full_name: 'SHIBA INU',
  id: 'jjySUDiaG5EDCCFVrqdz',
  is_X721: false,
  image_url: "https://storage.googleapis.com/public-bubblemaps/app/tokens-images/shiba.jpeg",
  links: [
    {
      backward: 0,
      forward: 4820.17,
      source: 1,
      target: 23,
    },
    {
      backward: 812455.4600000001,
      forward: 530001.0,
      source: 2,
      target: 27,
    },
    {
      backward: 0,
      forward: 1050000.0,
      source: 2,
      target: 4,
    },
    {
      backward: 1493001.094477,
      forward: 50006.263249999996,
      source: 3,
      target: 20,
    },
    {
      backward: 36235.005,
      forward: 147226.0,
      source: 4,
      target: 31,
    },
    {
      backward: 0,
      forward: 735055.0,
      source: 4,
      target: 27,
    },
    {
      backward: 478001.06519382,
      forward: 41829.538949660004,
      source: 8,
      target: 20,
    },
    {
      backward: 0,
      forward: 584999.0,
      source: 2,
      target: 9,
    },
    {
      backward: 480670.3085369243,
      forward: 443639.2299433115,
      source: 18,
      target: 64,
    },
    {
      backward: 2999.9827999999998,
      forward: 0,
      source: 19,
      target: 102,
    },
    {
      backward: 9899.9114,
      forward: 0,
      source: 19,
      target: 63,
    },
    {
      backward: 5199.9504,
      forward: 0,
      source: 19,
      target: 27,
    },
    {
      backward: 140000.094722,
      forward: 15868.635941496632,
      source: 21,
      target: 23,
    },
    {
      backward: 0.082722,
      forward: 0,
      source: 21,
      target: 58,
    },
    {
      backward: 4999.999232,
      forward: 0,
      source: 21,
      target: 63,
    },
    {
      backward: 20000.0,
      forward: 116100.0,
      source: 25,
      target: 64,
    },
    {
      backward: 24000.5,
      forward: 46966.100000000006,
      source: 34,
      target: 60,
    },
    {
      backward: 3701.2999999999997,
      forward: 0,
      source: 34,
      target: 41,
    },
    {
      backward: 1500.1,
      forward: 0,
      source: 41,
      target: 60,
    },
    {
      backward: 3009.989264,
      forward: 0,
      source: 44,
      target: 63,
    },
    {
      backward: 0,
      forward: 389.98959,
      source: 27,
      target: 44,
    },
    {
      backward: 1149.99057,
      forward: 0,
      source: 44,
      target: 102,
    },
    {
      backward: 249.9977,
      forward: 0,
      source: 45,
      target: 63,
    },
    {
      backward: 7.1588,
      forward: 0,
      source: 45,
      target: 102,
    },
    {
      backward: 0,
      forward: 47000.0,
      source: 27,
      target: 45,
    },
    {
      backward: 531.0917328200001,
      forward: 0,
      source: 48,
      target: 63,
    },
    {
      backward: 0,
      forward: 482.394,
      source: 30,
      target: 49,
    },
    {
      backward: 39.16914,
      forward: 0,
      source: 49,
      target: 64,
    },
    {
      backward: 81.792,
      forward: 0,
      source: 49,
      target: 102,
    },
    {
      backward: 39.8122,
      forward: 0,
      source: 49,
      target: 63,
    },
    {
      backward: 0,
      forward: 7.992900000000001,
      source: 27,
      target: 50,
    },
    {
      backward: 1.4964,
      forward: 0,
      source: 50,
      target: 63,
    },
    {
      backward: 333430.0,
      forward: 0,
      source: 23,
      target: 58,
    },
    {
      backward: 284974.9809,
      forward: 0,
      source: 58,
      target: 64,
    },
    {
      backward: 380.29630399999996,
      forward: 0,
      source: 60,
      target: 63,
    },
    {
      backward: 0,
      forward: 4300.302656,
      source: 27,
      target: 60,
    },
    {
      backward: 9599.99712,
      forward: 0,
      source: 60,
      target: 102,
    },
    {
      backward: 0,
      forward: 0.3,
      source: 27,
      target: 61,
    },
    {
      backward: 5927.55543057,
      forward: 0,
      source: 95,
      target: 102,
    },
    {
      backward: 0,
      forward: 6303.866775930001,
      source: 27,
      target: 95,
    },
    {
      backward: 0,
      forward: 0.23664100000000002,
      source: 63,
      target: 95,
    },
  ],
  metadata: {
    max_amount: 410418579680368.6,
    min_amount: 466767011157.0,
  },
  nodes: [
    {
      address: '0xdead000000000000000042069420694206942069',
      amount: 410418579680368.6,
      is_contract: false,
      name: 'Null: 0xdea...069',
      percentage: 41.0426,
      transaction_count: 101,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xf3b0073e3a7f747c7a38b36b805247b222c302a3',
      amount: 39106313471230.21,
      is_contract: false,
      name: 'Crypto.com 6',
      percentage: 3.9107,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xf977814e90da44bfa03b6295a0616a897441acec',
      amount: 36288525893206.08,
      is_contract: false,
      name: 'Binance 8',
      percentage: 3.6289,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x40b38765696e3d5d8d9d834d8aad4bb6e418e489',
      amount: 26053251930362.965,
      is_contract: false,
      name: 'Robinhood',
      percentage: 2.6054,
      transaction_count: 105,
      transfer_X721_count: null,
      transfer_count: 1765,
    },
    {
      address: '0x5a52e96bacdabb82fd05763e25335261b270efcb',
      amount: 24454867467184.453,
      is_contract: false,
      name: 'Binance 28',
      percentage: 2.4455,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x02e2201576fbbefb52812f2ee7f08eb4774b481e',
      amount: 22646584080784.688,
      is_contract: false,
      name: '0x02E22015...4774B481e',
      percentage: 2.2647,
      transaction_count: 75,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x422193843fe209faa94f5cc1780e04965e77cb7f',
      amount: 11432614620659.674,
      is_contract: true,
      name: '0x42219384...65E77Cb7F',
      percentage: 1.1433,
      transaction_count: 56,
      transfer_X721_count: null,
      transfer_count: 841,
    },
    {
      address: '0x6cc5f688a315f3dc28a7781717a9a798a59fda7b',
      amount: 10028385472475.271,
      is_contract: false,
      name: 'OKX',
      percentage: 1.0029,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x2efb50e952580f4ff32d8d2122853432bbf2e204',
      amount: 9792902477832.191,
      is_contract: false,
      name: 'Robinhood 2',
      percentage: 0.9793,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x47ac0fb4f2d84898e4d9e7b4dab3c24507a6d503',
      amount: 8050000000000.0,
      is_contract: false,
      name: 'Binance: Binance-Peg Tokens',
      percentage: 0.805,
      transaction_count: 84,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xb4a81261b16b92af0b9f7c4a83f1e885132d81e4',
      amount: 6698051380178.007,
      is_contract: true,
      name: 'Shiba Inu: xSHIB Token',
      percentage: 0.6698,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x27fd43babfbe83a81d14665b1a6fb8030a60c9b4',
      amount: 5623829378504.312,
      is_contract: true,
      name: '0x27fD43BA...30A60C9b4',
      percentage: 0.5624,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x2d7af085f2256f114c8a9f540969f0e0ab1c2e5e',
      amount: 5000004289798.584,
      is_contract: false,
      name: '0x2d7Af085...0AB1c2e5E',
      percentage: 0.5,
      transaction_count: 47,
      transfer_X721_count: null,
      transfer_count: 1605,
    },
    {
      address: '0xe1474359c74e78fa8387d9cb58f393693e378de3',
      amount: 5000000000062.11,
      is_contract: false,
      name: '0xe1474359...93E378dE3',
      percentage: 0.5,
      transaction_count: 30,
      transfer_X721_count: null,
      transfer_count: 1290,
    },
    {
      address: '0x6feb9b158262aac3e01549692fd3723a7d29c93e',
      amount: 5000000000000.0,
      is_contract: false,
      name: '0x6FEB9b15...A7d29c93E',
      percentage: 0.5,
      transaction_count: 12,
      transfer_X721_count: null,
      transfer_count: 1250,
    },
    {
      address: '0xfd54ea5842fefc9e0df4ede3b4bb9950d4ede13e',
      amount: 5000000000000.0,
      is_contract: false,
      name: '0xfd54Ea58...0d4EDE13E',
      percentage: 0.5,
      transaction_count: 25,
      transfer_X721_count: null,
      transfer_count: 1245,
    },
    {
      address: '0x127f19e9e3dac0f00f2e7f2affe2ccf972062df0',
      amount: 5000000000000.0,
      is_contract: false,
      name: '0x127F19E9...972062dF0',
      percentage: 0.5,
      transaction_count: 13,
      transfer_X721_count: null,
      transfer_count: 1236,
    },
    {
      address: '0xc35fff873c5751b04f64e565058acdf7f080a557',
      amount: 5000000000000.0,
      is_contract: false,
      name: '0xc35fFf87...7f080a557',
      percentage: 0.5,
      transaction_count: 26,
      transfer_X721_count: null,
      transfer_count: 1251,
    },
    {
      address: '0xc882b111a75c0c657fc507c04fbfcd2cc984f071',
      amount: 4547662856501.226,
      is_contract: false,
      name: 'Gate.io 5',
      percentage: 0.4548,
      transaction_count: 1145,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x19184ab45c40c2920b0e0e31413b9434abd243ed',
      amount: 4477730012251.0,
      is_contract: false,
      name: 'Coinspot 2',
      percentage: 0.4478,
      transaction_count: 391,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x73af3bcf944a6559933396c1577b257e2054d935',
      amount: 3830965840907.599,
      is_contract: false,
      name: '0x73AF3bcf...e2054D935',
      percentage: 0.3831,
      transaction_count: 287,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x72a53cdbbcc1b9efa39c834a540550e23463aacb',
      amount: 3711776560565.25,
      is_contract: false,
      name: 'Crypto.com 3',
      percentage: 0.3712,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x6d0cf1f651f5ae585d24dcaa188d44e389e93d26',
      amount: 3694051541341.7144,
      is_contract: false,
      name: 'Kraken 17',
      percentage: 0.3694,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xcffad3200574698b78f32232aa9d63eabd290703',
      amount: 3666591598905.466,
      is_contract: false,
      name: 'Crypto.com 5',
      percentage: 0.3667,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x46f80018211D5cBBc988e853A8683501FCA4ee9b',
      amount: 3303827585022.141,
      is_contract: false,
      name: '0x46f80018...1FCA4ee9b',
      percentage: 0.3304,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x1c4b70a3968436b9a0a9cf5205c787eb81bb558c',
      amount: 3000000000000.0,
      is_contract: false,
      name: 'Gate.io 3',
      percentage: 0.3,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xd6216fc19db775df9774a6e33526131da7d19a2c',
      amount: 2999986763080.9087,
      is_contract: false,
      name: 'KuCoin 6',
      percentage: 0.3,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x28c6c06298d514db089934071355e5743bf21d60',
      amount: 2733651331699.9883,
      is_contract: false,
      name: 'Binance 14',
      percentage: 0.2734,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xa7060aece919e4b7d9928b8418a9d30731471fa9',
      amount: 2623376457366.7783,
      is_contract: false,
      name: '0xa7060AEC...731471FA9',
      percentage: 0.2623,
      transaction_count: 5,
      transfer_X721_count: null,
      transfer_count: 493,
    },
    {
      address: '0xd6bc559a59b24a58a82f274555d152d67f15a7a6',
      amount: 2600000000000.771,
      is_contract: false,
      name: '0xd6Bc559a...67F15a7A6',
      percentage: 0.26,
      transaction_count: 546,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xf89d7b9c864f589bbf53a82105107622b35eaa40',
      amount: 2501361619818.1714,
      is_contract: false,
      name: 'Bybit: Hot Wallet',
      percentage: 0.2501,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x835678a611b28684005a5e2233695fb6cbbb0007',
      amount: 2418390000000.46,
      is_contract: false,
      name: '0x835678a6...6cbbB0007',
      percentage: 0.2418,
      transaction_count: 33,
      transfer_X721_count: null,
      transfer_count: 98,
    },
    {
      address: '0x058a871358c1b01039a265635ea282c3f435a9ed',
      amount: 2316836386676.06,
      is_contract: false,
      name: '0x058A8713...3F435a9Ed',
      percentage: 0.2317,
      transaction_count: 108,
      transfer_X721_count: null,
      transfer_count: 1053,
    },
    {
      address: '0xa23cbcdfafd09de2ce793d0a08f51865885be3f5',
      amount: 2200841213284.0,
      is_contract: false,
      name: '0xa23cbCdF...5885Be3f5',
      percentage: 0.2201,
      transaction_count: 153,
      transfer_X721_count: null,
      transfer_count: 252,
    },
    {
      address: '0x434587332cc35d33db75b93f4f27cc496c67a4db',
      amount: 1926012824342.3735,
      is_contract: false,
      name: '0x43458733...96c67A4Db',
      percentage: 0.1926,
      transaction_count: 101,
      transfer_X721_count: null,
      transfer_count: 1827,
    },
    {
      address: '0x111cff45948819988857bbf1966a0399e0d1141e',
      amount: 1897438689732.0,
      is_contract: true,
      name: 'bitFlyer',
      percentage: 0.1897,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x9b0c45d46d386cedd98873168c36efd0dcba8d46',
      amount: 1850555085866.2578,
      is_contract: false,
      name: '0x9b0c45d4...0DcBa8d46',
      percentage: 0.1851,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x826ce13450dde3baea0f60eadb056b61890fc32f',
      amount: 1847458321105.355,
      is_contract: false,
      name: '0x826cE134...1890fc32F',
      percentage: 0.1847,
      transaction_count: 26,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xf197c6f2ac14d25ee2789a73e4847732c7f16bc9',
      amount: 1813053860344.1821,
      is_contract: false,
      name: '0xF197c6F2...2C7F16bC9',
      percentage: 0.1813,
      transaction_count: 549,
      transfer_X721_count: null,
      transfer_count: 118,
    },
    {
      address: '0xafcd96e580138cfa2332c632e66308eacd45c5da',
      amount: 1450000000000.0,
      is_contract: true,
      name: '0xAFCD96e5...ACD45C5dA',
      percentage: 0.145,
      transaction_count: 214,
      transfer_X721_count: null,
      transfer_count: 695,
    },
    {
      address: '0x3cc936b795a188f0e246cbb2d74c5bd190aecf18',
      amount: 1281638644295.6265,
      is_contract: false,
      name: 'Mexc.com 3',
      percentage: 0.1282,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x8f509a90c2e47779ca408fe00d7a72e359229ada',
      amount: 1271702771150.0,
      is_contract: false,
      name: '0x8F509A90...359229AdA',
      percentage: 0.1272,
      transaction_count: 77,
      transfer_X721_count: null,
      transfer_count: 1933,
    },
    {
      address: '0x18709e89bd403f470088abdacebe86cc60dda12e',
      amount: 1238885030781.2942,
      is_contract: false,
      name: 'Huobi: Recovery',
      percentage: 0.1239,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x0b925b1dc9b9b9709092bbccbfb32f14db81981d',
      amount: 1220000000000.0,
      is_contract: false,
      name: '0x0B925B1d...4DB81981d',
      percentage: 0.122,
      transaction_count: 58,
      transfer_X721_count: null,
      transfer_count: 1793,
    },
    {
      address: '0x2407b9b9662d970ece2224a0403d3b15c7e4d1fe',
      amount: 1193819404206.3933,
      is_contract: false,
      name: 'CoinDCX 2',
      percentage: 0.1194,
      transaction_count: 1791,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x46533f26eb4080e2050e3f8a3014aedf7b5fdb12',
      amount: 1136976371496.09,
      is_contract: false,
      name: '0x46533f26...f7B5FDb12',
      percentage: 0.1137,
      transaction_count: 354,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xafedf06777839d59eed3163cc3e0a5057b514399',
      amount: 1130883816878.4998,
      is_contract: true,
      name: '0xAFedF067...57b514399',
      percentage: 0.1131,
      transaction_count: 396,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0xc6f400060d98e714992917d7ccfeeb71949e000b',
      amount: 1126824116743.4717,
      is_contract: false,
      name: '0xc6f40006...1949e000B',
      percentage: 0.1127,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 93,
    },
    {
      address: '0x91dca37856240e5e1906222ec79278b16420dc92',
      amount: 1078895880018.0,
      is_contract: false,
      name: 'Indodax 3',
      percentage: 0.1079,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x3d8fc1cffaa110f7a7f9f8bc237b73d54c4abf61',
      amount: 1063752667257.794,
      is_contract: false,
      name: 'Uphold 2',
      percentage: 0.1064,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x1e615ad14822b150b309be81b0e2de689655acd7',
      amount: 1059607837697.8983,
      is_contract: false,
      name: '0x1e615aD1...89655aCD7',
      percentage: 0.106,
      transaction_count: 715,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x5f99e419e477dcecf580e6fc375674fa555beac8',
      amount: 1008020106438.3169,
      is_contract: false,
      name: '0x5f99e419...A555beac8',
      percentage: 0.1008,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x12da64f9c7b4e9a73f9e177cd18c40cf307306f4',
      amount: 1002404900000.0,
      is_contract: true,
      name: '0x12dA64F9...f307306f4',
      percentage: 0.1002,
      transaction_count: 8,
      transfer_X721_count: null,
      transfer_count: 33,
    },
    {
      address: '0xcb5f84f388b1d25553064b35617f9e38c4c100b1',
      amount: 1001661520264.8203,
      is_contract: false,
      name: '0xcb5F84f3...8C4c100b1',
      percentage: 0.1002,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 8,
    },
    {
      address: '0x176066996850702a18158b4230f2b224dea2ca39',
      amount: 998990375094.7734,
      is_contract: false,
      name: '0x17606699...4dEa2cA39',
      percentage: 0.0999,
      transaction_count: 100,
      transfer_X721_count: null,
      transfer_count: 1602,
    },
    {
      address: '0x0a5bca3fcc14abae42c3e46e7b4670ff3b9b2684',
      amount: 858499454562.1118,
      is_contract: false,
      name: '0x0a5bcA3F...f3B9b2684',
      percentage: 0.0859,
      transaction_count: 24,
      transfer_X721_count: null,
      transfer_count: 1255,
    },
    {
      address: '0xac31ce983fd52499bac0adb0487c9f10fe1f3140',
      amount: 836942648505.0112,
      is_contract: false,
      name: '0xaC31ce98...0Fe1F3140',
      percentage: 0.0837,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 90,
    },
    {
      address: '0xefca9ad14d276d9a83b3992f6909c2cf23718f14',
      amount: 836942648505.0112,
      is_contract: false,
      name: '0xEFCA9AD1...f23718f14',
      percentage: 0.0837,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 81,
    },
    {
      address: '0x6262998ced04146fa42253a5c0af90ca02dfd2a3',
      amount: 802408694987.1345,
      is_contract: false,
      name: 'Crypto.com',
      percentage: 0.0802,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x10a9309c40a4dcd792b1a3c76f9fd4ccf3ac33fe',
      amount: 794440533343.3185,
      is_contract: false,
      name: '0x10A9309C...cf3Ac33FE',
      percentage: 0.0794,
      transaction_count: 85,
      transfer_X721_count: null,
      transfer_count: 317,
    },
    {
      address: '0x3f08f17973ab4124c73200135e2b675ab2d263d9',
      amount: 774385664016.2386,
      is_contract: false,
      name: '0x3F08f179...aB2D263D9',
      percentage: 0.0774,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x50cb1557365e11f733c030e68f8b4767a675cbcc',
      amount: 705870024103.9097,
      is_contract: false,
      name: '0x50Cb1557...7A675cbcc',
      percentage: 0.0706,
      transaction_count: 94,
      transfer_X721_count: null,
      transfer_count: 570,
    },
    {
      address: '0x5d7f34372fa8708e09689d400a613eee67f75543',
      amount: 688684783288.285,
      is_contract: false,
      name: 'Binance 42',
      percentage: 0.0689,
      transaction_count: 1324,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x21a31ee1afc51d94c2efccaa2092ad1028285549',
      amount: 667689712610.04,
      is_contract: false,
      name: 'Binance 15',
      percentage: 0.0668,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x0d0707963952f2fba59dd06f2b425ace40b492fe',
      amount: 662582415732.682,
      is_contract: false,
      name: 'Gate.io',
      percentage: 0.0663,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x71956a1cd5a4233177f7bf9a2d5778851e201934',
      amount: 659793006637.2014,
      is_contract: false,
      name: '0x71956a1C...51e201934',
      percentage: 0.066,
      transaction_count: 19,
      transfer_X721_count: null,
      transfer_count: 369,
    },
    {
      address: '0xc0f8a02c07e39cd529edd040793676438c9a9b86',
      amount: 639800000000.0,
      is_contract: false,
      name: '0xC0F8A02C...38C9A9B86',
      percentage: 0.064,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0xcd813ba331cfe53246e617812557d61a86f7a9c3',
      amount: 639800000000.0,
      is_contract: false,
      name: '0xCd813Ba3...a86f7a9c3',
      percentage: 0.064,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x590ff6c88e453bbbef2d8afde9f95871df987018',
      amount: 621000000000.0,
      is_contract: false,
      name: '0x590fF6C8...1Df987018',
      percentage: 0.0621,
      transaction_count: 225,
      transfer_X721_count: null,
      transfer_count: 369,
    },
    {
      address: '0x403b07215e2b21d56577be3901b1e9d9c471ccb8',
      amount: 620044488192.0278,
      is_contract: false,
      name: '0x403B0721...9c471CCB8',
      percentage: 0.062,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 89,
    },
    {
      address: '0x536876dba587138c64242364ef749e0f8e3b4f0f',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x536876Db...F8E3b4f0F',
      percentage: 0.06,
      transaction_count: 1,
      transfer_X721_count: null,
      transfer_count: 19,
    },
    {
      address: '0x12fefa76f0f60ed8eac7148d0bc88e46f6068862',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x12FEFA76...6F6068862',
      percentage: 0.06,
      transaction_count: 1,
      transfer_X721_count: null,
      transfer_count: 20,
    },
    {
      address: '0x1bec171c20857ccef342c1fd78072b30562292f0',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x1Bec171c...0562292f0',
      percentage: 0.06,
      transaction_count: 1,
      transfer_X721_count: null,
      transfer_count: 19,
    },
    {
      address: '0x46955c5281e08d5dc0c958b40336ceaa0f4cf4bb',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x46955C52...a0f4cf4bb',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 21,
    },
    {
      address: '0xed55d1b71b6bfa952ddbc4f24375c91652878560',
      amount: 600000000000.0,
      is_contract: false,
      name: '0xed55D1B7...652878560',
      percentage: 0.06,
      transaction_count: 710,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x588451999670b2acd6461cd8d909d84efaf1219e',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x58845199...EfAF1219e',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 22,
    },
    {
      address: '0x34596b5ff0ebb0f63f2097c19f7ce1a78bc48bbd',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x34596B5F...78bC48bBD',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 21,
    },
    {
      address: '0x624c09abeab74d369a02942e08a3c7cb83e5785f',
      amount: 600000000000.0,
      is_contract: false,
      name: '0x624C09Ab...B83e5785F',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 19,
    },
    {
      address: '0xb59b092a39d4c3c5d38755b27af551b36a6c2672',
      amount: 600000000000.0,
      is_contract: false,
      name: '0xB59b092A...36a6c2672',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 21,
    },
    {
      address: '0xb162bc0f78286707df47f640f841efea56839e58',
      amount: 600000000000.0,
      is_contract: false,
      name: '0xb162bC0f...a56839E58',
      percentage: 0.06,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 19,
    },
    {
      address: '0x9c7a04e81460e434efebec08c07238129ddce24a',
      amount: 598833000000.0,
      is_contract: false,
      name: '0x9C7A04e8...29dDCe24a',
      percentage: 0.0599,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x0c501d43206b97fa4300f1b0962742b4f938f70e',
      amount: 597243857100.9938,
      is_contract: false,
      name: '0x0c501D43...4F938f70E',
      percentage: 0.0597,
      transaction_count: 496,
      transfer_X721_count: null,
      transfer_count: 541,
    },
    {
      address: '0xd00486207705f54f4bac843769d0f6d12a4bf9d4',
      amount: 595040306603.6354,
      is_contract: false,
      name: '0xD0048620...12A4bf9d4',
      percentage: 0.0595,
      transaction_count: 683,
      transfer_X721_count: null,
      transfer_count: 1136,
    },
    {
      address: '0xd138803a44fd82f379bbfe6c307b2709391c8ee3',
      amount: 593623685561.5128,
      is_contract: true,
      name: '0xD138803a...9391c8eE3',
      percentage: 0.0594,
      transaction_count: 106,
      transfer_X721_count: null,
      transfer_count: 819,
    },
    {
      address: '0xd27e7392bc61134503985a924fd9f8cc15f2c1b1',
      amount: 580000000000.0,
      is_contract: false,
      name: '0xd27E7392...C15f2c1B1',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x4bbfc1177ca66a66a9e354f2f2c8e07c1de3ddcd',
      amount: 580000000000.0,
      is_contract: false,
      name: '0x4BBfc117...c1DE3Ddcd',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x1cfccd4d0903547dbb792d2d592906319565623e',
      amount: 580000000000.0,
      is_contract: false,
      name: '0x1CFCCD4D...19565623e',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x46dde8af406a0e5b7263a16a7cb4c8216aeac307',
      amount: 580000000000.0,
      is_contract: false,
      name: '0x46DDe8Af...16AeAc307',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0xf2aa4a10d9362c63087105e955fd356f144fb849',
      amount: 580000000000.0,
      is_contract: false,
      name: '0xf2Aa4A10...F144Fb849',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x2f906d44f2f4c25dec3dc17dbd493e0dc0bb01a2',
      amount: 580000000000.0,
      is_contract: false,
      name: '0x2f906D44...dc0bb01A2',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x1b284a9db6a51ee5f6e9fe67d47193aaa9d3c446',
      amount: 579527000000.0,
      is_contract: false,
      name: '0x1b284A9d...AA9d3c446',
      percentage: 0.058,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x5b97a1cd49754878630524298296abcad2b4cc2d',
      amount: 570455657530.0,
      is_contract: true,
      name: '0x5B97a1CD...ad2B4CC2d',
      percentage: 0.057,
      transaction_count: 61,
      transfer_X721_count: null,
      transfer_count: 155,
    },
    {
      address: '0xc7e7406f04dd1120d89111a56e48a84238ef5bb4',
      amount: 569829673793.3999,
      is_contract: false,
      name: '0xc7E7406F...238EF5bB4',
      percentage: 0.057,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 85,
    },
    {
      address: '0xea146260c1374f0bcf6aec408a2a0c9471b49a1c',
      amount: 563091000000.0,
      is_contract: false,
      name: '0xEa146260...471B49a1c',
      percentage: 0.0563,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0xe30dc0efc2740816719c019a542e40ccaffb60d3',
      amount: 559085000000.0,
      is_contract: false,
      name: '0xE30dC0eF...caFFB60D3',
      percentage: 0.0559,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x86523c87c8ec98c7539e2c58cd813ee9d1a08d96',
      amount: 545040716412.97,
      is_contract: false,
      name: '0x86523c87...9D1A08D96',
      percentage: 0.0545,
      transaction_count: 53,
      transfer_X721_count: null,
      transfer_count: 336,
    },
    {
      address: '0x57c281e8904d5abe9709df0d63b4f65c47e076ac',
      amount: 535000000000.6936,
      is_contract: false,
      name: '0x57C281E8...C47e076AC',
      percentage: 0.0535,
      transaction_count: 21,
      transfer_X721_count: null,
      transfer_count: 54,
    },
    {
      address: '0xb98847074e6177248768242b5caacbf499d9334d',
      amount: 534040000000.0,
      is_contract: false,
      name: '0xB9884707...499D9334d',
      percentage: 0.0534,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0xbd89cce1b2f34a68788d83d454ce2d1de51ee06d',
      amount: 520349000000.0,
      is_contract: false,
      name: '0xbd89Cce1...dE51eE06D',
      percentage: 0.052,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 7,
    },
    {
      address: '0x047560f6c2da3575b74d89fc3eae3bcf361664c5',
      amount: 512749000000.0,
      is_contract: false,
      name: '0x047560F6...f361664C5',
      percentage: 0.0513,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 6,
    },
    {
      address: '0x0bd7e5aa6d2adf0d5f33404872bafbd769f434f7',
      amount: 509918000000.0,
      is_contract: false,
      name: '0x0Bd7e5aA...769F434f7',
      percentage: 0.051,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x9e4f051817d13a2b646fd7fef4d2251316058401',
      amount: 508876000000.0,
      is_contract: false,
      name: '0x9E4F0518...316058401',
      percentage: 0.0509,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xdfd5293d8e347dfe59e90efd55b2956a1343963d',
      amount: 504647534472.61,
      is_contract: false,
      name: 'Binance 16',
      percentage: 0.0505,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x4b46d8997fec353cdece8ca40711d37fb378b6a9',
      amount: 500000000107.0,
      is_contract: false,
      name: '0x4b46d899...FB378B6A9',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 16,
    },
    {
      address: '0xc2d65abb5ce3a124a9706bdcf04ae5d840dd4163',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xc2D65ABB...840DD4163',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0x0af7d28ef33a203657efbe6c35b449dbe596dcfb',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x0AF7D28E...BE596DcFb',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0x6fc7d5c377468d1f7bff30840bc821ab387a244d',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x6FC7d5C3...b387a244D',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0x44b67aa993eed18e164f5ec76ec31701a9850b48',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x44b67aa9...1a9850B48',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0x0adc7d79675e86ca1b6b674b20f4d100eba1ecc1',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x0Adc7d79...0EBa1eCC1',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0x7a992e502e5cb6b47175795b4d2a1417f7a22695',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x7a992E50...7f7a22695',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 14,
    },
    {
      address: '0xb89300e39c53d371061f30af7ba6f9aaf92e728a',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xb89300E3...af92E728a',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 15,
    },
    {
      address: '0xec0148f599bb94c7d6b6fe930789222b44ce6a62',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xec0148f5...B44cE6A62',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 15,
    },
    {
      address: '0xbbbeb0fbf1352fd30064bf76d8058a1a731967ae',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xbbbeb0FB...a731967ae',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 16,
    },
    {
      address: '0x29a4be5211296bd14ed9ea62f7a6099453513809',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x29a4bE52...453513809',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 15,
    },
    {
      address: '0x342f124ac27d0aacdfd3076744395efec74254b5',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x342F124A...ec74254B5',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 16,
    },
    {
      address: '0x06a10d37b7ac573f8c923ccb99eb039e9ef344a3',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x06a10D37...E9Ef344A3',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0xdd8612bb657d4876381f2c06722b835023843656',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xdD8612bB...023843656',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0xd707c84fb060aad48a029ac967f68b5151fd2072',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xd707c84F...151fD2072',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 12,
    },
    {
      address: '0xb5530d7527b0833a9dd4b7d71e71ac5bae441792',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xB5530d75...bae441792',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 12,
    },
    {
      address: '0x543a848117274dc0318cea4b711a14783e00af66',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x543A8481...83e00AF66',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0xb6db37870e8504507bd04e85745dc60b3bd7a8ea',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xb6DB3787...B3bd7A8EA',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 12,
    },
    {
      address: '0xa86073f9786090f31f4ae057ece6773b03666745',
      amount: 500000000000.0,
      is_contract: false,
      name: '0xA86073F9...b03666745',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0x682391321d70cc802b419f38273cee39c5a37fdf',
      amount: 500000000000.0,
      is_contract: false,
      name: '0x68239132...9C5a37FDf',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0x5a65a0b4e0c4d52a6b9b4f9fe44f57efb1f803e5',
      amount: 499943385259.0,
      is_contract: false,
      name: '0x5a65a0B4...Fb1f803E5',
      percentage: 0.05,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x6f85b237d4e960d4588c5f55150650af9dbe0eae',
      amount: 498299030615.0,
      is_contract: false,
      name: '0x6F85B237...f9Dbe0Eae',
      percentage: 0.0498,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x85e097c6ed82889fe408db527fa9a441fbd05bfa',
      amount: 497295000000.0,
      is_contract: false,
      name: '0x85E097c6...1FbD05bFA',
      percentage: 0.0497,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xfa6e42ec86cba8434794bf6b8f2139e553d6abe2',
      amount: 495039634670.0,
      is_contract: false,
      name: '0xFa6e42ec...553D6AbE2',
      percentage: 0.0495,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x30f7f9534d3ca7e99dfc87b54537ee5cb6452497',
      amount: 494864000000.0,
      is_contract: false,
      name: '0x30f7f953...CB6452497',
      percentage: 0.0495,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x9eae3a3748f60fa1c6a02bbcb5e4637022a05f4b',
      amount: 492337000000.0,
      is_contract: false,
      name: '0x9eAe3A37...022a05f4b',
      percentage: 0.0492,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x9b3ecddfae7ec8ad17ad9c2932714934bb2d1fad',
      amount: 488778233310.0,
      is_contract: false,
      name: '0x9B3eCdDF...4Bb2D1Fad',
      percentage: 0.0489,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x2b22a511775ea91498845cfac31e63cc294e690c',
      amount: 487742003217.0,
      is_contract: false,
      name: '0x2B22a511...C294E690C',
      percentage: 0.0488,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xa4667485c8852c0748e0103faf2698f204444b78',
      amount: 486360000000.0,
      is_contract: false,
      name: '0xa4667485...204444B78',
      percentage: 0.0486,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x170d1209dca948266f2c5b284bba2c60f92ad2fc',
      amount: 485990000000.0,
      is_contract: false,
      name: '0x170D1209...0f92Ad2Fc',
      percentage: 0.0486,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x55fd30824f4a66237806a8e6c95db42c57479842',
      amount: 485521382879.0,
      is_contract: false,
      name: '0x55fD3082...C57479842',
      percentage: 0.0486,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x167a9333bf582556f35bd4d16a7e80e191aa6476',
      amount: 481667246893.3849,
      is_contract: false,
      name: 'Coinone',
      percentage: 0.0482,
      transaction_count: 2000,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x3a3829688d8dbe23ba233c2f88b449e4088c411b',
      amount: 480000000000.0,
      is_contract: false,
      name: '0x3a382968...4088C411b',
      percentage: 0.048,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x45bdb16a4b6a8066c8aaff94bef69c21af3502e3',
      amount: 480000000000.0,
      is_contract: false,
      name: '0x45bdb16A...1aF3502E3',
      percentage: 0.048,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xdfa11b86c4b93a4b58ec2fbb1e9f38f7e86c7a5e',
      amount: 480000000000.0,
      is_contract: false,
      name: '0xdfa11B86...7E86C7A5E',
      percentage: 0.048,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x822e0a728b7e1e9a35f87f31d4a52ab6ac277d28',
      amount: 478772427495.9005,
      is_contract: false,
      name: '0x822e0a72...6ac277d28',
      percentage: 0.0479,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 77,
    },
    {
      address: '0x45d01fe448208a51038c4b0757cc4fdc50280110',
      amount: 478352406261.0,
      is_contract: false,
      name: '0x45D01fE4...c50280110',
      percentage: 0.0478,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x9c03cb23edb8bfffc750f5717b486e5779fe6275',
      amount: 476945000000.0,
      is_contract: false,
      name: '0x9C03cb23...779Fe6275',
      percentage: 0.0477,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xbad615f7a4c809f6ea5ad687fa0ef4d35bb8da2d',
      amount: 475730433491.0,
      is_contract: false,
      name: '0xBad615F7...35bB8Da2d',
      percentage: 0.0476,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xf804c8a23bf7eccc1536179328383785079d0a78',
      amount: 474951000000.0,
      is_contract: false,
      name: '0xF804C8a2...5079D0a78',
      percentage: 0.0475,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0xf83c5b8c8a51857a558d7521ed9728253ead1bd1',
      amount: 472162478273.0,
      is_contract: false,
      name: '0xf83c5B8C...53eAD1BD1',
      percentage: 0.0472,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x176f3dab24a159341c0509bb36b833e7fdd0a132',
      amount: 472093552224.81,
      is_contract: false,
      name: 'Justin Sun 4',
      percentage: 0.0472,
      transaction_count: 344,
      transfer_X721_count: null,
      transfer_count: 2000,
    },
    {
      address: '0x60b13723ba5b3595f228a9deb5a40d5605e6e9cf',
      amount: 469220148495.0,
      is_contract: false,
      name: '0x60b13723...605E6E9cf',
      percentage: 0.0469,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x587979b5893dc47b1130b6e443f3a5f8ea26fae6',
      amount: 468989000000.0,
      is_contract: false,
      name: '0x587979b5...8eA26Fae6',
      percentage: 0.0469,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 13,
    },
    {
      address: '0x31621721b70719c6ed7917763fd60f31a4ecc024',
      amount: 468972395524.0,
      is_contract: false,
      name: '0x31621721...1A4EcC024',
      percentage: 0.0469,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
    {
      address: '0x48b67cc80b120a045caae34e1fcea793ac60fe23',
      amount: 468134658934.6426,
      is_contract: false,
      name: '0x48b67CC8...3ac60Fe23',
      percentage: 0.0468,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 79,
    },
    {
      address: '0xd6c431822fc7ed82809b00fd1f5d86b2a30ee546',
      amount: 466767011157.0,
      is_contract: false,
      name: '0xD6C43182...2A30ee546',
      percentage: 0.0467,
      transaction_count: 0,
      transfer_X721_count: null,
      transfer_count: 1,
    },
  ],
  source_id: 0,
  symbol: 'SHIB',
  token_address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
  token_links: [
    {
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      decimals: 18,
      links: [],
      name: 'Wrapped Ether',
      symbol: 'WETH',
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6,
      links: [
        {
          backward: 5238413.52,
          forward: 7700000.0,
          source: 1,
          target: 23,
        },
        {
          backward: 459999999.16,
          forward: 1002999999.0,
          source: 2,
          target: 9,
        },
        {
          backward: 0,
          forward: 100000000.0,
          source: 2,
          target: 4,
        },
        {
          backward: 25.0,
          forward: 15.0,
          source: 3,
          target: 20,
        },
        {
          backward: 4437020.0,
          forward: 22851773.0,
          source: 4,
          target: 31,
        },
        {
          backward: 34609324.463536,
          forward: 34609324.463536,
          source: 18,
          target: 64,
        },
        {
          backward: 2000000.0,
          forward: 100000000.0,
          source: 21,
          target: 23,
        },
        {
          backward: 275000000.0,
          forward: 1.3,
          source: 21,
          target: 58,
        },
        {
          backward: 15000000.0,
          forward: 0,
          source: 21,
          target: 63,
        },
        {
          backward: 9999996.8,
          forward: 0,
          source: 21,
          target: 102,
        },
        {
          backward: 10000000.0,
          forward: 0,
          source: 21,
          target: 27,
        },
        {
          backward: 15500000.0,
          forward: 0,
          source: 23,
          target: 63,
        },
        {
          backward: 0,
          forward: 107712.074139,
          source: 25,
          target: 64,
        },
        {
          backward: 357264.31,
          forward: 0,
          source: 45,
          target: 63,
        },
        {
          backward: 80000.0,
          forward: 0,
          source: 48,
          target: 102,
        },
        {
          backward: 0,
          forward: 131589.0,
          source: 30,
          target: 49,
        },
        {
          backward: 2849000.0,
          forward: 0,
          source: 62,
          target: 102,
        },
        {
          backward: 0,
          forward: 1389809.0,
          source: 27,
          target: 62,
        },
        {
          backward: 1642000.0,
          forward: 0,
          source: 62,
          target: 63,
        },
        {
          backward: 0,
          forward: 74833243.553073,
          source: 27,
          target: 102,
        },
        {
          backward: 0,
          forward: 4980088.0,
          source: 27,
          target: 144,
        },
      ],
      name: 'USD Coin',
      symbol: 'USDC',
    },
    {
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      decimals: 6,
      links: [
        {
          backward: 0,
          forward: 5858945.1,
          source: 1,
          target: 23,
        },
        {
          backward: 0,
          forward: 1600000000.0,
          source: 2,
          target: 4,
        },
        {
          backward: 2434134749.428,
          forward: 3404917193.4512534,
          source: 2,
          target: 27,
        },
        {
          backward: 29784689.213,
          forward: 0,
          source: 2,
          target: 102,
        },
        {
          backward: 42683029.883,
          forward: 0,
          source: 2,
          target: 63,
        },
        {
          backward: 0,
          forward: 743157755.246,
          source: 4,
          target: 27,
        },
        {
          backward: 30000000.0,
          forward: 200000000.0,
          source: 2,
          target: 9,
        },
        {
          backward: 573837890.759793,
          forward: 301589874.27285,
          source: 18,
          target: 64,
        },
        {
          backward: 44500000.0,
          forward: 0,
          source: 21,
          target: 63,
        },
        {
          backward: 30500000.0,
          forward: 0,
          source: 21,
          target: 27,
        },
        {
          backward: 5000000.0,
          forward: 0,
          source: 21,
          target: 58,
        },
        {
          backward: 7649955.4,
          forward: 0,
          source: 44,
          target: 102,
        },
        {
          backward: 6102149.417752,
          forward: 0,
          source: 44,
          target: 63,
        },
        {
          backward: 0,
          forward: 399992.0,
          source: 27,
          target: 44,
        },
        {
          backward: 12790.7804,
          forward: 0,
          source: 45,
          target: 64,
        },
        {
          backward: 14499991.36,
          forward: 0,
          source: 48,
          target: 63,
        },
        {
          backward: 0,
          forward: 4000000.0,
          source: 27,
          target: 48,
        },
        {
          backward: 9989986.22,
          forward: 0,
          source: 48,
          target: 102,
        },
        {
          backward: 0,
          forward: 310102.31700000004,
          source: 27,
          target: 49,
        },
        {
          backward: 365556.975,
          forward: 0,
          source: 49,
          target: 102,
        },
        {
          backward: 0,
          forward: 2310646.0,
          source: 30,
          target: 49,
        },
        {
          backward: 329824.219,
          forward: 0,
          source: 49,
          target: 63,
        },
        {
          backward: 224129.663,
          forward: 0,
          source: 49,
          target: 64,
        },
        {
          backward: 14995.8,
          forward: 0,
          source: 50,
          target: 102,
        },
        {
          backward: 7302.59626,
          forward: 0,
          source: 50,
          target: 63,
        },
        {
          backward: 4600957.447001999,
          forward: 0,
          source: 60,
          target: 63,
        },
        {
          backward: 7146656.120625,
          forward: 0,
          source: 60,
          target: 102,
        },
        {
          backward: 0,
          forward: 1500169.0,
          source: 27,
          target: 60,
        },
        {
          backward: 0,
          forward: 84987973.1,
          source: 27,
          target: 62,
        },
        {
          backward: 91499994.4,
          forward: 0,
          source: 62,
          target: 63,
        },
        {
          backward: 88079978.0,
          forward: 0,
          source: 62,
          target: 102,
        },
        {
          backward: 0,
          forward: 122947654.348347,
          source: 27,
          target: 102,
        },
        {
          backward: 0,
          forward: 11030532.0,
          source: 63,
          target: 144,
        },
      ],
      name: 'Tether USD',
      symbol: 'USDT',
    },
    {
      address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      links: [
        {
          backward: 44158163.0,
          forward: 0,
          source: 0,
          target: 22,
        },
        {
          backward: 3954967.5,
          forward: 0,
          source: 0,
          target: 63,
        },
        {
          backward: 1042210.0,
          forward: 0,
          source: 0,
          target: 102,
        },
        {
          backward: 1338872.0,
          forward: 0,
          source: 0,
          target: 27,
        },
        {
          backward: 0,
          forward: 456449221902.0,
          source: 1,
          target: 23,
        },
        {
          backward: 9941346002023.133,
          forward: 4964056928799.833,
          source: 2,
          target: 27,
        },
        {
          backward: 948611046864.589,
          forward: 0,
          source: 2,
          target: 102,
        },
        {
          backward: 692614702543.129,
          forward: 0,
          source: 2,
          target: 63,
        },
        {
          backward: 26053268380981.965,
          forward: 16669606.0,
          source: 3,
          target: 20,
        },
        {
          backward: 102753543489.0,
          forward: 2521140000000.0,
          source: 4,
          target: 31,
        },
        {
          backward: 0,
          forward: 18052800000000.0,
          source: 4,
          target: 27,
        },
        {
          backward: 0,
          forward: 3414568191051.0,
          source: 8,
          target: 20,
        },
        {
          backward: 0,
          forward: 8050000000000.0,
          source: 2,
          target: 9,
        },
        {
          backward: 0,
          forward: 5000000000000.0,
          source: 12,
          target: 15,
        },
        {
          backward: 0,
          forward: 5000000000000.0,
          source: 12,
          target: 14,
        },
        {
          backward: 0,
          forward: 5000000000000.0,
          source: 12,
          target: 16,
        },
        {
          backward: 0,
          forward: 5000000000000.0,
          source: 12,
          target: 17,
        },
        {
          backward: 0,
          forward: 5000000000050.0,
          source: 12,
          target: 13,
        },
        {
          backward: 4206942.0,
          forward: 0,
          source: 12,
          target: 27,
        },
        {
          backward: 30000000000000.0,
          forward: 0,
          source: 12,
          target: 43,
        },
        {
          backward: 6293354785590.963,
          forward: 3565883790806.284,
          source: 18,
          target: 64,
        },
        {
          backward: 24939482510233.023,
          forward: 0,
          source: 1,
          target: 21,
        },
        {
          backward: 0,
          forward: 3015688745090.168,
          source: 25,
          target: 64,
        },
        {
          backward: 0,
          forward: 100000000001.0,
          source: 28,
          target: 51,
        },
        {
          backward: 0,
          forward: 156150805421.0,
          source: 22,
          target: 29,
        },
        {
          backward: 0,
          forward: 3543489.46,
          source: 27,
          target: 31,
        },
        {
          backward: 0,
          forward: 25000.0,
          source: 34,
          target: 60,
        },
        {
          backward: 0,
          forward: 85010000000.0,
          source: 41,
          target: 60,
        },
        {
          backward: 139993379079.0,
          forward: 0,
          source: 44,
          target: 63,
        },
        {
          backward: 0,
          forward: 78995006965.0,
          source: 27,
          target: 44,
        },
        {
          backward: 53997343765.0,
          forward: 0,
          source: 44,
          target: 102,
        },
        {
          backward: 51099008544.0,
          forward: 0,
          source: 45,
          target: 63,
        },
        {
          backward: 6184911433.434999,
          forward: 0,
          source: 49,
          target: 64,
        },
        {
          backward: 0,
          forward: 1153878055.87,
          source: 27,
          target: 49,
        },
        {
          backward: 0,
          forward: 15254065590.0,
          source: 30,
          target: 49,
        },
        {
          backward: 1744204109.94,
          forward: 0,
          source: 49,
          target: 102,
        },
        {
          backward: 1100000669653.2983,
          forward: 1100297028102.05,
          source: 10,
          target: 50,
        },
        {
          backward: 0,
          forward: 25690000.4,
          source: 51,
          target: 102,
        },
        {
          backward: 858403287797.7164,
          forward: 858499454562.1118,
          source: 10,
          target: 55,
        },
        {
          backward: 1000000000000.0,
          forward: 0,
          source: 23,
          target: 58,
        },
        {
          backward: 0,
          forward: 300000.0,
          source: 7,
          target: 61,
        },
        {
          backward: 320000.0,
          forward: 0,
          source: 61,
          target: 102,
        },
        {
          backward: 73520938833.0,
          forward: 0,
          source: 62,
          target: 63,
        },
        {
          backward: 287194536838.0,
          forward: 0,
          source: 62,
          target: 102,
        },
        {
          backward: 0,
          forward: 702932472767.03,
          source: 27,
          target: 62,
        },
        {
          backward: 0,
          forward: 11500000000.0,
          source: 7,
          target: 62,
        },
        {
          backward: 8993752938.609999,
          forward: 0,
          source: 95,
          target: 102,
        },
        {
          backward: 0,
          forward: 302755167193.05005,
          source: 27,
          target: 95,
        },
        {
          backward: 0,
          forward: 233292826281.30997,
          source: 63,
          target: 95,
        },
        {
          backward: 0,
          forward: 577093552224.81,
          source: 27,
          target: 144,
        },
      ],
      name: 'SHIBA INU',
      symbol: 'SHIB',
    },
  ],
  top_500: false,
  version: 5,
};

export const tokenHistories = [
  {
      "id": "KPkrgpi8Ppo7v0K22AMN",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1719937647,
      "fullName": "SHIBA INU"
  },
  {
      "id": "hcTyu3hvM0s8Wgfd7GG0",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1719443204,
      "fullName": "SHIBA INU"
  },
  {
      "id": "NiKl305Yeswj9Y3z2rcR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1718838110,
      "fullName": "SHIBA INU"
  },
  {
      "id": "jUUO6bz1dWRaz8Q2ltJu",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1718295315,
      "fullName": "SHIBA INU"
  },
  {
      "id": "jErkhgzbhRzgw88dlXji",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1717713409,
      "fullName": "SHIBA INU"
  },
  {
      "id": "bpiBRtSwiVITRciVPZ8T",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1717123982,
      "fullName": "SHIBA INU"
  },
  {
      "id": "RJiNB72rxnOkkzFq2qaJ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1716943321,
      "fullName": "SHIBA INU"
  },
  {
      "id": "foBnqfXMiUdX9AJVJqlJ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1716334008,
      "fullName": "SHIBA INU"
  },
  {
      "id": "OKIknyLB6aK2QiqTN5mW",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1715738657,
      "fullName": "SHIBA INU"
  },
  {
      "id": "0vODprIwE6gaOJoJXi4r",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1715364401,
      "fullName": "SHIBA INU"
  },
  {
      "id": "1El1050MUtxdXViZhRKw",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1715315361,
      "fullName": "SHIBA INU"
  },
  {
      "id": "auzCZ2CmUSQa0c1oWqlN",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1714951339,
      "fullName": "SHIBA INU"
  },
  {
      "id": "S7aTjyR3SlnlXI8XYXkG",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1714347792,
      "fullName": "SHIBA INU"
  },
  {
      "id": "EOqV7ZQiFdqUQedg6agp",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1713740830,
      "fullName": "SHIBA INU"
  },
  {
      "id": "OPpjy51mPkBYVt11hoZq",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1713179456,
      "fullName": "SHIBA INU"
  },
  {
      "id": "JvWsbZOnw3Q17XS2bE5K",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1712665816,
      "fullName": "SHIBA INU"
  },
  {
      "id": "JDD8fNPh1mAo8pDSVWaP",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1712268563,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7OW0e9KgvkMnyIXcn3Zp",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1712246710,
      "fullName": "SHIBA INU"
  },
  {
      "id": "1k3y5MaWphRBzfNRb7sl",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1712246627,
      "fullName": "SHIBA INU"
  },
  {
      "id": "FQlpT95jJMJ0iIuOSXl8",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1711920854,
      "fullName": "SHIBA INU"
  },
  {
      "id": "u44wJYjW069L0lsIi8Zb",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1711536274,
      "fullName": "SHIBA INU"
  },
  {
      "id": "CvoY4yevLrttX788fu09",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1711149412,
      "fullName": "SHIBA INU"
  },
  {
      "id": "jjySUDiaG5EDCCFVrqdz",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1710600005,
      "fullName": "SHIBA INU"
  },
  {
      "id": "amwbf7aAhuUhx9lnlkAU",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1710457352,
      "fullName": "SHIBA INU"
  },
  {
      "id": "cmv3lkQ74Wqa52mve0Ri",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1709884550,
      "fullName": "SHIBA INU"
  },
  {
      "id": "QhsDQYIvmq8YcPQAYg6w",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1709571100,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7Ae9vGH6m7ZjE0b7ixBX",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1709571030,
      "fullName": "SHIBA INU"
  },
  {
      "id": "hn54XHAEeaEpruoNxuhn",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1709376609,
      "fullName": "SHIBA INU"
  },
  {
      "id": "xLnclf0TafAZjhOZawpW",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1709169415,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ywvyNQuytcDOhpRm1XiA",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1708564912,
      "fullName": "SHIBA INU"
  },
  {
      "id": "vFQUiZovt7G7IK5040I5",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1708021642,
      "fullName": "SHIBA INU"
  },
  {
      "id": "8TKeXVd3vIdDHQBV4QMz",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1707657169,
      "fullName": "SHIBA INU"
  },
  {
      "id": "JbcYapluqfGr24EGSd6e",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1707491286,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ms4MPNOtmzkLy5z5ZZul",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1707004631,
      "fullName": "SHIBA INU"
  },
  {
      "id": "zG4HYFtwI0phnfbdUbqY",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1706435615,
      "fullName": "SHIBA INU"
  },
  {
      "id": "j1uanVpF5YBRQkTN5TuK",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1706435583,
      "fullName": "SHIBA INU"
  },
  {
      "id": "852NcaiMiu3rCpn25GIP",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1706224010,
      "fullName": "SHIBA INU"
  },
  {
      "id": "qaA31D0rmOaq6lRil067",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1705618919,
      "fullName": "SHIBA INU"
  },
  {
      "id": "APbeRK57daxVUoseBYIb",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1705083801,
      "fullName": "SHIBA INU"
  },
  {
      "id": "L3zEoaJICG8VOnmy5aMi",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1704885382,
      "fullName": "SHIBA INU"
  },
  {
      "id": "B4HpcgtRlvm3qV7mVy2x",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1704726986,
      "fullName": "SHIBA INU"
  },
  {
      "id": "UNayVm8Vw32YPc2HTCpa",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1704238009,
      "fullName": "SHIBA INU"
  },
  {
      "id": "sEVxrj2rwQd6cvnsbwWJ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1703631720,
      "fullName": "SHIBA INU"
  },
  {
      "id": "pulgZ8O5QoZNTPd1cNRs",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1703067625,
      "fullName": "SHIBA INU"
  },
  {
      "id": "cckGCpnSKWiykJsQ9sit",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1702681913,
      "fullName": "SHIBA INU"
  },
  {
      "id": "HEXCrQwdYDY64LCCUhyR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1702127922,
      "fullName": "SHIBA INU"
  },
  {
      "id": "OLKgcalFkIzt4ZkZfFhr",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1702127884,
      "fullName": "SHIBA INU"
  },
  {
      "id": "rVGvps8Fl6riva67t0qH",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1701731509,
      "fullName": "SHIBA INU"
  },
  {
      "id": "MfFCU66HpsTCEuCxCNPF",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1701126117,
      "fullName": "SHIBA INU"
  },
  {
      "id": "LFj7h0Rh3Ly09boObqx5",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1700579853,
      "fullName": "SHIBA INU"
  },
  {
      "id": "QuWBhNFJAleqlVqyjfsn",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1700475907,
      "fullName": "SHIBA INU"
  },
  {
      "id": "W0erdUlJ4MINqe0CLnTB",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1699974668,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7zRz9Vd9zaOOhb9kuZu7",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1699831011,
      "fullName": "SHIBA INU"
  },
  {
      "id": "0tr1GjEynisfmb060mLl",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1699234908,
      "fullName": "SHIBA INU"
  },
  {
      "id": "huyEcdL3JMIRoLNoSPPe",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1698629209,
      "fullName": "SHIBA INU"
  },
  {
      "id": "mYt6abXLkZTTLZpp7UU5",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1698059211,
      "fullName": "SHIBA INU"
  },
  {
      "id": "mlksG5QzFx4ntHW0oGNC",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1697498229,
      "fullName": "SHIBA INU"
  },
  {
      "id": "qRxTYUln6xI2BZnkgOuR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1696891307,
      "fullName": "SHIBA INU"
  },
  {
      "id": "pTxH4yvtqIIRAlZxAOAC",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1696360864,
      "fullName": "SHIBA INU"
  },
  {
      "id": "38fwMTxq6u8vRRfbxuHr",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1696102095,
      "fullName": "SHIBA INU"
  },
  {
      "id": "D7Zh4ZsYHyioatA4rxNB",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1695958605,
      "fullName": "SHIBA INU"
  },
  {
      "id": "bZFXEaB3OpWE63iV4Kt9",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1695919931,
      "fullName": "SHIBA INU"
  },
  {
      "id": "jaoz7nLtIBdetiOiakwM",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1695754395,
      "fullName": "SHIBA INU"
  },
  {
      "id": "Fu6DfyDzJYSgJVRNowF8",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1695569052,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ZWpoa9SWplvkkrJAuCFR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1695160913,
      "fullName": "SHIBA INU"
  },
  {
      "id": "2bq115wnb3zHEIU56dLD",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1694625642,
      "fullName": "SHIBA INU"
  },
  {
      "id": "XWVBDpZTv36fFntiPS62",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1694247498,
      "fullName": "SHIBA INU"
  },
  {
      "id": "FPCq7PVEuw85xswepBb4",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1694192117,
      "fullName": "SHIBA INU"
  },
  {
      "id": "9sQh6vikjlaj5jnXujcx",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1693829702,
      "fullName": "SHIBA INU"
  },
  {
      "id": "crfvpvcswwHs2HVY9w5Y",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1693315254,
      "fullName": "SHIBA INU"
  },
  {
      "id": "i7C6r4IS7evtJCeWuhhk",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1693175506,
      "fullName": "SHIBA INU"
  },
  {
      "id": "Fzu5sVJbYkd9jDs9wIPm",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1692654218,
      "fullName": "SHIBA INU"
  },
  {
      "id": "c5YBiGJAvrba3pYdQ6tg",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1692526535,
      "fullName": "SHIBA INU"
  },
  {
      "id": "0AO8tFhRGhQeQUsGDBfd",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1691964705,
      "fullName": "SHIBA INU"
  },
  {
      "id": "JEjz1NYQmIb2OOdySPK9",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1691426672,
      "fullName": "SHIBA INU"
  },
  {
      "id": "GdypD1RP10VzPb5t2ywk",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1691178397,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7f6isF2HOzw2Wx3P190Z",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1691112602,
      "fullName": "SHIBA INU"
  },
  {
      "id": "qBwV7KoUl9L2wXzdQy7j",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1690909835,
      "fullName": "SHIBA INU"
  },
  {
      "id": "Jzgf4TKeGLfo5SirwDe0",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1690811162,
      "fullName": "SHIBA INU"
  },
  {
      "id": "XcUJffddWLPP8EmJIUJR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1690811112,
      "fullName": "SHIBA INU"
  },
  {
      "id": "DMHLbNk3bBLvxKeWBVhF",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1690241218,
      "fullName": "SHIBA INU"
  },
  {
      "id": "oKkJjeIh5knCEu6CqlUQ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1689637417,
      "fullName": "SHIBA INU"
  },
  {
      "id": "zzV1hnUmtK8HEraNmozj",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1689597948,
      "fullName": "SHIBA INU"
  },
  {
      "id": "63nVAc9fmXDjjNZD7kjm",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1689417395,
      "fullName": "SHIBA INU"
  },
  {
      "id": "9oYpxcZmJzCVYax0H40q",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1689231519,
      "fullName": "SHIBA INU"
  },
  {
      "id": "9to6fAd8K1oqwpTQtoM6",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1689197593,
      "fullName": "SHIBA INU"
  },
  {
      "id": "p9vAV6Fem2ep9INZr5on",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1688942806,
      "fullName": "SHIBA INU"
  },
  {
      "id": "44fJdkQoth5btmqW6Pkg",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1688388403,
      "fullName": "SHIBA INU"
  },
  {
      "id": "pJMUi15XeJmTHSK548lo",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1688172078,
      "fullName": "SHIBA INU"
  },
  {
      "id": "SKKmqf6ktGp3qeJy68tq",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1688132658,
      "fullName": "SHIBA INU"
  },
  {
      "id": "fgSLsI5MJ5JNuOjPyxt8",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1687907806,
      "fullName": "SHIBA INU"
  },
  {
      "id": "UeGwzRQP84kxd3IREQEW",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1687335648,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ThyjZ2AVIW8jYKPCw2qI",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1687227350,
      "fullName": "SHIBA INU"
  },
  {
      "id": "72b9qUzKg4is8Zfe2JxU",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1686867717,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7xIkVoxErATjZoB3bKxI",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1686325708,
      "fullName": "SHIBA INU"
  },
  {
      "id": "jJlecnA5AhK2DiMg2Enl",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1686155295,
      "fullName": "SHIBA INU"
  },
  {
      "id": "6csFGJfJtaHBVkETberZ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1686098506,
      "fullName": "SHIBA INU"
  },
  {
      "id": "TDmqH9y5bomKJcR5xJfJ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1685500906,
      "fullName": "SHIBA INU"
  },
  {
      "id": "qTKbDZqmynJODiEdGPMz",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1684887709,
      "fullName": "SHIBA INU"
  },
  {
      "id": "20K3R3ZhaTKsfzkBo17R",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1684279904,
      "fullName": "SHIBA INU"
  },
  {
      "id": "AoY4NqT7V9kOIOqPee1a",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1683674204,
      "fullName": "SHIBA INU"
  },
  {
      "id": "siW4VUY6aXHt6U6VJSL1",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1683071204,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ZusWS27Zz0FQ4xHOFlbD",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1682527503,
      "fullName": "SHIBA INU"
  },
  {
      "id": "SedUKhu3BjG91XukSKrV",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1682468805,
      "fullName": "SHIBA INU"
  },
  {
      "id": "TzCj9CTzfmMVe5ByOf83",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1681867004,
      "fullName": "SHIBA INU"
  },
  {
      "id": "LIWdrGoaZrwC2sJoupqn",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1681258604,
      "fullName": "SHIBA INU"
  },
  {
      "id": "XJcy9KhkL2COnbIjftNV",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1680662809,
      "fullName": "SHIBA INU"
  },
  {
      "id": "wl3uG5W3W9X6vNr4H12p",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1680059517,
      "fullName": "SHIBA INU"
  },
  {
      "id": "bM6QjbRxTJCUfqxAGiRC",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1679464006,
      "fullName": "SHIBA INU"
  },
  {
      "id": "BFYASQuYa8QTxyIwYqgT",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1678854425,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7RWOKbVV1d9H1Xm7SMoF",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1678230110,
      "fullName": "SHIBA INU"
  },
  {
      "id": "XRqCY38xqMRnhiWb3XNV",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1677625604,
      "fullName": "SHIBA INU"
  },
  {
      "id": "R8fWORGGvfNjBP2ukXLg",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1676422903,
      "fullName": "SHIBA INU"
  },
  {
      "id": "NDXTaNiAnwxKYgBnxU5F",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1675812704,
      "fullName": "SHIBA INU"
  },
  {
      "id": "B8znPfyWdQ6TwrIxwGDp",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1675242695,
      "fullName": "SHIBA INU"
  },
  {
      "id": "YGXbcjRS7I06lTjcox17",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674717137,
      "fullName": "SHIBA INU"
  },
  {
      "id": "GexJMQHZG1N9IZ2QaN5T",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674426846,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7AtS6mzYi1k2abpNf6lm",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674233734,
      "fullName": "SHIBA INU"
  },
  {
      "id": "8ImWcg6Xjc6GzZr0cBZy",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674201481,
      "fullName": "SHIBA INU"
  },
  {
      "id": "72ascrC1ppkYPmg7rqIg",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674155084,
      "fullName": "SHIBA INU"
  },
  {
      "id": "nj8v6TIMkVEgNvfCU3GE",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674043789,
      "fullName": "SHIBA INU"
  },
  {
      "id": "hxoXUiVuzsa43SkkZWJ8",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1674022915,
      "fullName": "SHIBA INU"
  },
  {
      "id": "5PWrE8O6M92WlD9QKjo7",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1673400119,
      "fullName": "SHIBA INU"
  },
  {
      "id": "mx8YSrCdAlyBfudbHNy9",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1672833191,
      "fullName": "SHIBA INU"
  },
  {
      "id": "j7gcxiSVg6mrAEEaMELN",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1672426969,
      "fullName": "SHIBA INU"
  },
  {
      "id": "TUS9TJIOhwJfAZoajpJa",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1672010205,
      "fullName": "SHIBA INU"
  },
  {
      "id": "GUBEUkZObq0gua32Ky7d",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1671449577,
      "fullName": "SHIBA INU"
  },
  {
      "id": "HWomzbW3flxp4BXCDYV7",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1671420120,
      "fullName": "SHIBA INU"
  },
  {
      "id": "SHj5jZoVA7JrASTf3qFF",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1670839607,
      "fullName": "SHIBA INU"
  },
  {
      "id": "OQZrLQGEqzxVR8e1sOYP",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1670381504,
      "fullName": "SHIBA INU"
  },
  {
      "id": "niNCCid77MT6YrzW89IO",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1669781640,
      "fullName": "SHIBA INU"
  },
  {
      "id": "eTCVIIThZbmewMLqkc7X",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1669591190,
      "fullName": "SHIBA INU"
  },
  {
      "id": "5ERApRVY7mreEGEXg3Zr",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1669523537,
      "fullName": "SHIBA INU"
  },
  {
      "id": "gCIdYpRH1YWfk9vwJdAD",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1669392125,
      "fullName": "SHIBA INU"
  },
  {
      "id": "s6LOb1zt1grV0q6xF5Ut",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1669201253,
      "fullName": "SHIBA INU"
  },
  {
      "id": "rf5PwUz8CMUq9AGw8RB0",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1668970538,
      "fullName": "SHIBA INU"
  },
  {
      "id": "YmVDbnZYVLZfrpnEvvyt",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1668741437,
      "fullName": "SHIBA INU"
  },
  {
      "id": "peqgpSXnbluVeNmitFBT",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1668712014,
      "fullName": "SHIBA INU"
  },
  {
      "id": "8k09kWwzNnVd4DFdvXiM",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1668380820,
      "fullName": "SHIBA INU"
  },
  {
      "id": "Kaw8GHlyaKeaEhnsiCOn",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1667775707,
      "fullName": "SHIBA INU"
  },
  {
      "id": "V5jjB5ZmA4ujBrojGB6w",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1667222084,
      "fullName": "SHIBA INU"
  },
  {
      "id": "QIFLqLdG6JQSbhKwu9a3",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1666849005,
      "fullName": "SHIBA INU"
  },
  {
      "id": "bOTbrPVbt9L1nngRew87",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1666237309,
      "fullName": "SHIBA INU"
  },
  {
      "id": "5FhBlQ1BC57yakyPdxr8",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1665613614,
      "fullName": "SHIBA INU"
  },
  {
      "id": "DgwLBL01JQ8N1UzThlfD",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1665049906,
      "fullName": "SHIBA INU"
  },
  {
      "id": "8MZPvzHobIGqnHXzw3zB",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1664422914,
      "fullName": "SHIBA INU"
  },
  {
      "id": "w2RAvVhZP3gOFoBxWtwR",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1663877210,
      "fullName": "SHIBA INU"
  },
  {
      "id": "vWgnA2cyU3LwPIcbAgIk",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1662161146,
      "fullName": "SHIBA INU"
  },
  {
      "id": "TNA0b8lRc1cuipwDa7vh",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1661551605,
      "fullName": "SHIBA INU"
  },
  {
      "id": "7WKephzvvyEj3IGFLVMh",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1660951844,
      "fullName": "SHIBA INU"
  },
  {
      "id": "uPOD3vXL5ycLuY2sTn5f",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1660347352,
      "fullName": "SHIBA INU"
  },
  {
      "id": "4pZmYAznedo5AFi6dV2a",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1659739523,
      "fullName": "SHIBA INU"
  },
  {
      "id": "xyu1CzZYWwzY0qrclqHs",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1659137743,
      "fullName": "SHIBA INU"
  },
  {
      "id": "Va84vWEv2nbM0Dy3iFBp",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1658527226,
      "fullName": "SHIBA INU"
  },
  {
      "id": "d0EDkoUuo7xxhRoAPdLW",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1657922719,
      "fullName": "SHIBA INU"
  },
  {
      "id": "OggWVOJdsYYssyQSlcfr",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1657324825,
      "fullName": "SHIBA INU"
  },
  {
      "id": "QbCsKOLbI2PnU7MdvH2C",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1656712838,
      "fullName": "SHIBA INU"
  },
  {
      "id": "3zOZSfr752ecInJUnNVS",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1656113798,
      "fullName": "SHIBA INU"
  },
  {
      "id": "9VovyjtOAWO4SnUMKANy",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1655533310,
      "fullName": "SHIBA INU"
  },
  {
      "id": "CgaPiRPDjEHBV3clAq9d",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1654925738,
      "fullName": "SHIBA INU"
  },
  {
      "id": "y2iNg0qvAcnsLPyvmr3M",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1654293621,
      "fullName": "SHIBA INU"
  },
  {
      "id": "S9gAp0FMRSSMcUg0Lcqs",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1653762295,
      "fullName": "SHIBA INU"
  },
  {
      "id": "xvTq8mweD9OpYsGVfEGS",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1653446130,
      "fullName": "SHIBA INU"
  },
  {
      "id": "iPHxpBFDWk0i8VHRdoSZ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1652896222,
      "fullName": "SHIBA INU"
  },
  {
      "id": "y7DrPIlep0mm93vxpE2L",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1652349794,
      "fullName": "SHIBA INU"
  },
  {
      "id": "QB8CF0KkTd59xqDGcYH2",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1652106961,
      "fullName": "SHIBA INU"
  },
  {
      "id": "1IAbJPmsKxRyO8g3fvlO",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1651905930,
      "fullName": "SHIBA INU"
  },
  {
      "id": "44MyD5rfJOGE3IzKPAUQ",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1648171116,
      "fullName": "SHIBA INU"
  },
  {
      "id": "UvT6ivblmSXMzDmcD2CU",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1645191355,
      "fullName": "SHIBA INU"
  },
  {
      "id": "ig9CYGC8ycoqmeG3taXT",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1644266882,
      "fullName": "SHIBA INU"
  },
  {
      "id": "alZZTRFGHgmFTYogP4DI",
      "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "symbol": "SHIB",
      "dtUpdate": 1643480327,
      "fullName": "SHIBA INU"
  }
];
