import { TokenLiteWithChain } from 'types/token';
import { create } from 'zustand';

export type RecentlyViewedState = {
  items: TokenLiteWithChain[];
  loadRecentlyViewed: () => void;
  addRecentlyViewed: (item: TokenLiteWithChain) => void;
  cleanAllRecentlyViewed: () => void;
};

const recentlyViewedStorageKey = 'bbmap:recentlyViewed';
const maxRecentlyViewed = 10;

export const useRecentlyViewedStore = create<RecentlyViewedState>((set) => ({
  items: [],
  loadRecentlyViewed: () => {
    try {
      const itemsString = localStorage.getItem(recentlyViewedStorageKey) || '[]';
      const items = JSON.parse(itemsString);
      set({ items });
    } catch (e) {}
  },
  addRecentlyViewed: (item) => {
    const pickedItem: TokenLiteWithChain = {
      id: item.id,
      tokenAddress: item.tokenAddress,
      fullName: item.fullName,
      imageUrl: item.imageUrl,
      website: item.website,
      chain: item.chain,
      isX721: item.isX721,
      symbol: item.symbol,
    };
    set(({ items }) => {
      const newItems = items
        .filter((item) => item.tokenAddress !== pickedItem.tokenAddress)
        .slice(0, maxRecentlyViewed - 1);
      newItems.unshift(pickedItem);
      localStorage.setItem(recentlyViewedStorageKey, JSON.stringify(newItems));
      return { items: newItems };
    });
  },
  cleanAllRecentlyViewed: () => {
    set({ items: [] });
    localStorage.removeItem(recentlyViewedStorageKey);
  },
}));
