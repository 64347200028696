// import { ReactComponent as EthIcon } from 'assets/chains/eth.svg';
// import { ReactComponent as BscIcon } from 'assets/chains/bsc.svg';
// import { ReactComponent as FtmIcon } from 'assets/chains/ftm.svg';
// import { ReactComponent as AvaxIcon } from 'assets/chains/avax.svg';
// import { ReactComponent as CroIcon } from 'assets/chains/cro.svg';
// import { ReactComponent as ArbiIcon } from 'assets/chains/arbi.svg';
// import { ReactComponent as PolyIcon } from 'assets/chains/poly.svg';
// import { ReactComponent as BaseIcon } from 'assets/chains/base.svg';
import { ReactComponent as SolIcon } from 'assets/chains/sol.svg';
import { ChainConfig, ChainSymbol } from 'types/chain';

export const defaultChain = ChainSymbol.sol;

export const chainConfigs: ChainConfig[] = [
  // {
  //   symbol: ChainSymbol.eth,
  //   fullName: 'Ethereum',
  //   color: '#607ae3',
  //   IconComponent: EthIcon,
  //   tokenScanUrl: 'https://etherscan.io/token/$token',
  //   tokenRugUrl: 'https://etherscan.io/token/$token',
  //   tokenScanFromUrl: 'https://etherscan.io/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://etherscan.io/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.bsc,
  //   fullName: 'BNB Chain',
  //   color: '#e8b30b',
  //   IconComponent: BscIcon,
  //   tokenScanUrl: 'https://bscscan.com/token/$token',
  //   tokenRugUrl: 'https://bscscan.com/token/$token',
  //   tokenScanFromUrl: 'https://bscscan.com/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://bscscan.com/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.ftm,
  //   fullName: 'Fantom',
  //   color: '#1a69ff',
  //   IconComponent: FtmIcon,
  //   tokenScanUrl: 'https://ftmscan.com/token/$token',
  //   tokenRugUrl: 'https://ftmscan.com/token/$token',
  //   tokenScanFromUrl: 'https://ftmscan.com/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://ftmscan.com/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.avax,
  //   fullName: 'Avalanche',
  //   color: '#e84142',
  //   IconComponent: AvaxIcon,
  //   tokenScanUrl: 'https://snowscan.xyz/token/$token',
  //   tokenRugUrl: 'https://snowscan.xyz/token/$token',
  //   tokenScanFromUrl: 'https://snowscan.xyz/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://snowscan.xyz/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.cro,
  //   fullName: 'Cronos',
  //   color: '#215784',
  //   IconComponent: CroIcon,
  //   tokenScanUrl: 'https://cronoscan.com/token/$token',
  //   tokenRugUrl: 'https://cronoscan.com/token/$token',
  //   tokenScanFromUrl: 'https://cronoscan.com/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://cronoscan.com/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.arbi,
  //   fullName: 'Arbitrum',
  //   color: '#2a9fef',
  //   IconComponent: ArbiIcon,
  //   tokenScanUrl: 'https://arbiscan.io/token/$token',
  //   tokenRugUrl: 'https://arbiscan.io/token/$token',
  //   tokenScanFromUrl: 'https://arbiscan.io/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://arbiscan.io/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.poly,
  //   fullName: 'Polygon',
  //   color: '#8247e5',
  //   IconComponent: PolyIcon,
  //   tokenScanUrl: 'https://polygonscan.com/token/$token',
  //   tokenRugUrl: 'https://polygonscan.com/token/$token',
  //   tokenScanFromUrl: 'https://polygonscan.com/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://polygonscan.com/address/$token?toaddress=$toaddress',
  // },
  // {
  //   symbol: ChainSymbol.base,
  //   fullName: 'Base',
  //   color: '#245bf0',
  //   IconComponent: BaseIcon,
  //   tokenScanUrl: 'https://basescan.org/token/$token',
  //   tokenRugUrl: 'https://basescan.org/token/$token',
  //   tokenScanFromUrl: 'https://basescan.org/address/$token?fromaddress=$fromaddress',
  //   tokenScanToUrl: 'https://basescan.org/address/$token?toaddress=$toaddress',
  // },
  {
    symbol: ChainSymbol.sol,
    fullName: 'Solana',
    color: '#18d4a0',
    IconComponent: SolIcon,
    tokenScanUrl: 'https://solscan.io/token/$token',
    tokenRugUrl: 'https://solana.fm/address/$token',
    tokenScanFromUrl: 'https://solscan.io/account/$seletedwallet?from_address=$fromaddress&token_address=$token_address#transfers',
    tokenScanToUrl: 'https://solscan.io/account/$seletedwallet?to_address=$toaddress&token_address=$token_address#transfers',
  },
];

export const chainConfigsMap = new Map<ChainSymbol, ChainConfig>(
  chainConfigs.map((chainConfig) => [chainConfig.symbol, chainConfig])
);
