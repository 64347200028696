import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  SvgIcon,
  ThemeProvider,
  createTheme,
  styled,
  useTheme,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { ArrowDropDown, CalendarMonth, Help, List, MoreHoriz } from '@mui/icons-material';
import { ReactComponent as EthscanIcon } from 'assets/ethscan.svg';
import { useCurrentChainConfig } from 'hooks/useCurrentChain';
import { OpenTableMode, openTableWidth, useTokenDetailStore } from 'stores/tokenDetail';
import { useTokenImageUrls } from 'stores/tokens';
import { formatTimeAgo } from 'utils/datetime';
import AllImage from 'assets/all.png';

export const ChartToolbar: FC = () => {
  const theme = useTheme();
  const currentChainConfig = useCurrentChainConfig();
  const { tokenDetail, setOpenTableMode, openTableMode } = useTokenDetailStore();

  if (!tokenDetail) return <></>;
  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        palette: {
          primary: {
            main: 'rgba(29,25,33,.8)',
          },
        },
        components: {
          ...theme,
          MuiButton: {
            styleOverrides: {
              root: {
                padding: 8,
                fontSize: '1rem',
                lineHeight: 1.5,
                minWidth: 0,
                borderRadius: 8,
                textTransform: 'none',
              },
              startIcon: {
                marginLeft: 0,
              },
              endIcon: {
                marginRight: 0,
              },
            },
          },
        },
      })}
    >
      <Box
        position="absolute"
        zIndex={10}
        left={12}
        right={{ md: 12 + (openTableMode ? openTableWidth : 0), xs: 12 }}
        top={12}
        display="flex"
        columnGap={1}
        alignItems="center"
      >
        <Box display="flex" sx={{ flex: 1, minWidth: 0 }} columnGap={1}>
          <Button sx={{ flexShrink: 1 }} variant="contained">
            <Avatar
              src={tokenDetail.imageUrl}
              alt={tokenDetail.symbol}
              sx={{
                width: 24,
                height: 24,
                fontSize: '9px !important',
                fontWeight: 'bold',
                bgcolor: 'rgb(77, 59, 79)',
                mr: 1,
              }}
            >
              {tokenDetail.symbol.slice(0, 2)}
            </Avatar>
            <Box
              component="span"
              flexShrink={1}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              display={{ md: 'inline', xs: 'none' }}
            >
              <strong>{tokenDetail.fullName || tokenDetail.symbol}</strong>
            </Box>
            <MoreHoriz sx={{ ml: { md: 1, xs: 0 } }} />
          </Button>
          <Button
            variant="contained"
            LinkComponent="a"
            href={currentChainConfig.tokenScanUrl.replace('$token', tokenDetail.tokenAddress)}
            target="_blank"
            sx={{ display: { sm: 'flex', xs: 'none' } }}
          >
            <SvgIcon>
              <EthscanIcon />
            </SvgIcon>
          </Button>
          <Button variant="contained">
            <Help />
          </Button>
          <StablecoinDropdown />
        </Box>
        <Box display="flex" columnGap={1}>
          {openTableMode !== OpenTableMode.history && (
            <Button variant="contained" onClick={() => setOpenTableMode(OpenTableMode.history)}>
              <CalendarMonth sx={{ mr: { md: 1, xs: 0 } }} />
              <Box component="span" display={{ md: 'inline', xs: 'none' }}>
                {formatTimeAgo(tokenDetail.dtUpdate)}
              </Box>
            </Button>
          )}
          {openTableMode !== OpenTableMode.walletsList && (
            <Button variant="contained" onClick={() => setOpenTableMode(OpenTableMode.walletsList)}>
              <List sx={{ mr: { md: 1, xs: 0 } }} />
              <Box component="span" display={{ md: 'inline', xs: 'none' }}>
                Wallets List
              </Box>
            </Button>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const ChainDropdownMenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: 8,
    background: '#262626',
    border: '1px solid #4e4e4e',
    minWidth: 140,
    borderRadius: 16,
  },
}));

export const StablecoinDropdown: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mode, setMode, tokenDetail } = useTokenDetailStore();
  const tokenLinks = tokenDetail?.tokenLinks || [];
  const imageUrls = useTokenImageUrls(
    tokenLinks.map((tokenLink) => ({
      tokenAddress: tokenLink.address,
      symbol: tokenLink.symbol,
    }))
  );
  const currentImageUrl = mode < 0 ? AllImage : imageUrls[mode];
  const currentTokenLink = mode < 0 ? { symbol: 'All' } : tokenLinks[mode];

  if (!tokenDetail) return <></>;
  return (
    <>
      <Button variant="contained" sx={{ minWidth: { sm: 140, xs: 0 } }} onClick={handleClick}>
        <Avatar
          src={currentImageUrl}
          alt={currentTokenLink.symbol}
          sx={{
            width: 24,
            height: 24,
            fontSize: '9px !important',
            fontWeight: 'bold',
            bgcolor: 'rgb(77, 59, 79)',
            mr: { sm: 1, xs: 0 },
          }}
        >
          {currentTokenLink.symbol.slice(0, 2)}
        </Avatar>
        <Box component="strong" display={{ sm: 'inline', xs: 'none' }}>
          {currentTokenLink.symbol}
        </Box>
        <ArrowDropDown
          sx={{
            ml: 1,
            display: { sm: 'inline-block', xs: 'none' },
          }}
        />
      </Button>
      <ChainDropdownMenuStyled
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {tokenLinks.map((tokenLink, index) => (
          <MenuItem
            key={tokenLink.symbol}
            onClick={() => {
              setMode(index);
              handleClose();
            }}
            disableRipple
            sx={{ fontSize: 16, color: 'white', justifyContent: 'center' }}
          >
            <Avatar
              src={imageUrls[index]}
              alt={tokenLink.symbol}
              sx={{
                width: 24,
                height: 24,
                fontSize: '9px !important',
                fontWeight: 'bold',
                bgcolor: 'rgb(77, 59, 79)',
                mr: 1,
              }}
            >
              {tokenLink.symbol.slice(0, 2)}
            </Avatar>
            {tokenLink.symbol.toUpperCase()}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setMode(-1);
            handleClose();
          }}
          disableRipple
          sx={{ fontSize: 16, color: 'white', justifyContent: 'center' }}
        >
          <Avatar
            src={AllImage}
            alt={'All'}
            sx={{
              width: 24,
              height: 24,
              fontSize: '9px !important',
              fontWeight: 'bold',
              bgcolor: 'rgb(77, 59, 79)',
              mr: 1,
            }}
          />
          ALL
        </MenuItem>
      </ChainDropdownMenuStyled>
    </>
  );
};
