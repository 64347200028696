import { FC, PropsWithChildren, useEffect } from 'react';
import { useTokensStore } from 'stores/tokens';
import RotateLoader from 'react-spinners/RotateLoader';
import { Box } from '@mui/material';
import { useRecentlyViewedStore } from 'stores/recentlyViewed';

export const InitProvider: FC<PropsWithChildren> = ({ children }) => {
  const { loading, loadAllTokens } = useTokensStore();
  const { loadRecentlyViewed } = useRecentlyViewedStore();
  useEffect(() => {
    loadRecentlyViewed();
    loadAllTokens();
  }, [loadAllTokens, loadRecentlyViewed]);

  if (loading) {
    return (
      <Box height="100dvh" display="flex" alignItems="center" justifyContent="center">
        <RotateLoader color="#fff" size={15} />
      </Box>
    );
  }
  return <>{children}</>;
};
